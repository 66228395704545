import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import headerLogo from "assets/icon/Logo.png";
import noticeIcon from "assets/icon/noticeIcon.svg";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { AppContext, ProfileContext } from "App";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "context/AuthProvider";
import { useQuery, useSubscription } from "@apollo/client";

import {
  CREATE_SCAR_NOTI,
  CREATE_PROJECT_NOTI,
  SCAR_APPROVAL_NOTI,
} from "graphql/App/subscription";
import NoticeModal from "./NoticeModal";
import { SEE_MY_SIGN_PROJECT } from "graphql/Main/Main/query";
import { SEE_SCAR_DETAIL } from "graphql/Main/ProjectDetail/ScarDetail/query";
import {
  SEE_PROJECT_DETAIL,
  SEE_SCAR_LIST,
} from "graphql/Main/ProjectDetail/ProjectDetail/query";

const HeaderContainer = styled.div`
  width: 100%;
  min-width: 1920px;
  height: 81px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ GRAY }) => GRAY && `${colors.grayFontColor}`};
  cursor: ${({ POINTER }) => POINTER && "pointer"};
  margin: ${({ LOGOUT }) => LOGOUT && "0 20px 0 auto"};
  white-space: nowrap;
  width: ${({ width }) => width}px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Logo = styled.img`
  margin-right: ${({ LOGO }) => LOGO && 22}px;
  cursor: pointer;
  width: 90px;
`;

const HeaderMenuBox = styled.div`
  width: 371px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 300px;
`;

const NoticeRelative = styled.div`
  position: relative;
`;

const NoticeIcon = styled.div`
  width: 19px;
  height: 20px;
  position: relative;
  background-image: url(${noticeIcon});
  background-repeat: no-repeat;
  margin-right: 59px;
  cursor: pointer;
  & > span {
    width: 7px;
    height: 7px;
    position: absolute;
    right: -1px;
    top: -1px;
    border-radius: 50%;
    background-color: ${colors.redFontColor};
    display: ${({ notiActive }) => (notiActive ? "block" : "none")};
  }
`;

const RightButtonWrapper = styled.div`
  width: 482px;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const ProfileImage = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: ${({ src }) => (src === "" ? `40%` : `cover`)};
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  cursor: pointer;
  flex-shrink: 0;
`;

const LangWrapper = styled.div`
  width: 81px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 auto;
`;

const Header = () => {
  const { userLogout } = useContext(AuthContext);
  const { subScriptOn, setSubScriptOn } = useContext(AppContext);
  const { myProfile } = useContext(ProfileContext);
  const { language, setLanguage } = useContext(AppContext);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [notiModal, setNotiModal] = useState(false);

  const modal = useRef();
  const notiIconRef = useRef();

  const handleNavi = useCallback(
    (path) => {
      if (pathname === path) window.location.reload();
      else navigate(path);
    },
    [pathname]
  );

  const { refetch: signLineRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { refetch: prjDetailRefetch } = useQuery(SEE_PROJECT_DETAIL, {
    variables: {
      prj_id:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
    },
  });
  const { refetch: scarDetailRefetch } = useQuery(SEE_SCAR_DETAIL, {
    variables: {
      scar_id:
        localStorage.getItem("scarId") === null
          ? 0
          : Number(localStorage.getItem("scarId")),
    },
  });
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });

  const {
    loading: scarSubLoading,
    error: scarSubError,
    data: scarSubData,
  } = useSubscription(CREATE_SCAR_NOTI, {
    variables: {
      userId: myProfile?.user_id,
    },
  });

  const {
    loading: prjSubLoading,
    error: prjSubError,
    data: prjSubData,
  } = useSubscription(CREATE_PROJECT_NOTI, {
    variables: {
      memberId: myProfile?.user_id,
    },
  });

  const {
    loading: scarApprovalLoading,
    error: scarApprovalError,
    data: scarApprovalData,
  } = useSubscription(SCAR_APPROVAL_NOTI, {
    variables: {
      userId: myProfile?.user_id,
    },
  });

  useEffect(() => {
    // console.log("scarSubData>>", scarSubData, "prjSubData>>", prjSubData);
    (async () => {
      if (scarSubData || prjSubData || scarApprovalData) {
        setSubScriptOn(true);
        await signLineRefetch();
        if (localStorage.getItem("prjId") !== null) {
          await prjDetailRefetch();
          await scarListRefetch();
          // console.log("프젝디테일이랑 스카리스트 리패치");
        }
        if (localStorage.getItem("scarId") !== null) {
          await scarDetailRefetch();
        }
      }
    })();
  }, [scarSubData, prjSubData, scarApprovalData]);

  useEffect(() => {
    const click = (e) => {
      if (
        !modal?.current?.contains(e.target) &&
        !notiIconRef?.current?.contains(e.target)
      )
        setNotiModal(false);
      else setNotiModal(true);
    };
    window.addEventListener("click", click);
    return () => window.removeEventListener("click", click);
  }, []);

  return (
    <>
      <HeaderContainer>
        <Logo src={headerLogo} onClick={() => handleNavi("/")} />
        <Text POINTER BOLD onClick={() => handleNavi("/")}>
          {language === "KOR" && "리스크제로 HG"}
          {language === "ENG" && "RiskZero HG"}
        </Text>
        <HeaderMenuBox>
          <Text POINTER onClick={() => handleNavi("/")}>
            {language === "KOR" && "프로젝트"}
            {language === "ENG" && "Project"}
          </Text>
          <Text POINTER onClick={() => handleNavi("/serviceCenter")}>
            {language === "KOR" && "고객센터"}
            {language === "ENG" && "Service Center"}
          </Text>
          <Text POINTER onClick={() => handleNavi("/guide")}>
            {language === "KOR" && "사용법"}
            {language === "ENG" && "Manual"}
          </Text>
        </HeaderMenuBox>
        <RightButtonWrapper>
          <NoticeRelative>
            <NoticeIcon
              ref={notiIconRef}
              notiActive={subScriptOn}
              onClick={() => setNotiModal(!notiModal)}
            >
              <span />
            </NoticeIcon>
            {notiModal && (
              <NoticeModal
                subScriptOn={subScriptOn}
                ref={modal}
                setSubScriptOn={setSubScriptOn}
              />
            )}
          </NoticeRelative>
          <ProfileImage
            src={myProfile?.user_profileImg}
            onClick={() => navigate("/profile")}
          />
          <Text width={90} BOLD POINTER onClick={() => navigate("/profile")}>
            {myProfile?.user_name} {myProfile?.user_rank}
          </Text>
          <Text GRAY LOGOUT POINTER onClick={userLogout}>
            {language === "KOR" && "로그아웃"}
            {language === "ENG" && "Logout"}
          </Text>
          <LangWrapper>
            <Text
              POINTER
              GRAY={language !== "KOR"}
              onClick={() => {
                localStorage.setItem("lang", "KOR");
                setLanguage("KOR");
              }}
            >
              KOR
            </Text>
            <Text POINTER GRAY>
              |
            </Text>
            <Text
              POINTER
              GRAY={language !== "ENG"}
              onClick={() => {
                localStorage.setItem("lang", "ENG");
                setLanguage("ENG");
              }}
            >
              ENG
            </Text>
          </LangWrapper>
        </RightButtonWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
