import React, { useContext, useEffect, useState } from "react";
import ReportPresenter from "./ReportPresenter";
import { useQuery } from "@apollo/client";
import { SHARED_SCAR_DETAIL } from "graphql/Report/query";
import { useLocation } from "react-router-dom";
import { AppContext } from "App";

const ReportContainer = () => {
  const { pathname } = useLocation();
  const { language } = useContext(AppContext);

  const [memberModal, setMemberModal] = useState(false);

  const { data, refetch, loading } = useQuery(SHARED_SCAR_DETAIL, {
    variables: {
      scarId: Number(pathname.split("/").at(-1)),
    },
  });

  return (
    <ReportPresenter
      data={data?.sharedScarDetail}
      setMemberModal={setMemberModal}
      memberModal={memberModal}
      language={language}
    />
  );
};

export default ReportContainer;
