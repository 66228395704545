import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { FaHardHat } from "react-icons/fa";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { colors } from "styles/colors";
import ImageViewer from "react-simple-image-viewer";

const Container = styled.div`
  max-width: 280px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid skyblue; */
`;

const BigImageHidden = styled.div`
  overflow: hidden;
  position: relative;
`;

const BigImageWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${({ index }) => "translateX(-" + index * 280 + "px)"};
`;
const BigImage = styled.img`
  width: 280px;
  height: 280px;
  border-radius: 8px;
  flex-shrink: 0;
  /* background-image: ${({ src }) => `url(${src})`};
  background-position: 50%;
  background-size: cover; */
  object-fit: cover;
  cursor: pointer;
`;

const EmptyBox = styled.div`
  width: 280px;
  height: 280px;
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: ${colors.whiteColor};
  background-color: ${colors.grayFontColor};
`;

const Button = styled.div`
  width: 28px;
  height: 28px;
  display: ${({ view }) => (view ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 126px;
  left: ${({ RIGHT }) => !RIGHT && "10px"};
  right: ${({ RIGHT }) => RIGHT && "10px"};
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  z-index: 100;
`;

const SmallImageHidden = styled.div`
  width: 100%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SmallImageWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${({ index }) => "translateX(-" + index * 58 + "px)"};
`;

const SmallImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  margin: 10px 10px 10px 0;
  /* background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%; */
  object-fit: cover;
  &:last-child {
    margin-right: 0;
  }
  cursor: pointer;
`;

const StyledSwiper = ({ imageList = [] }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = useCallback(() => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  }, []);

  const handleArrow = useCallback(
    (arrow) => {
      switch (arrow) {
        case "left":
          if (currentIndex !== 0) {
            setCurrentIndex(currentIndex - 1);
          }
          return;
        case "right":
        default:
          if (currentIndex < imageList.length - 1) {
            setCurrentIndex(currentIndex + 1);
          }
          return;
      }
    },
    [currentIndex, imageList.length]
  );

  return (
    <Container>
      {imageList.length === 0 ? (
        <EmptyBox>
          <FaHardHat />
        </EmptyBox>
      ) : (
        <>
          <BigImageHidden>
            <Button
              onClick={() => handleArrow("left")}
              view={currentIndex !== 0}
            >
              <FaChevronLeft />
            </Button>
            <BigImageWrapper index={currentIndex}>
              {imageList.map((image, index) => (
                <BigImage
                  key={index}
                  src={image}
                  onClick={() => openImageViewer(index)}
                />
              ))}
            </BigImageWrapper>
            <Button
              RIGHT
              onClick={() => handleArrow("right")}
              view={currentIndex !== imageList.length - 1}
            >
              <FaChevronRight />
            </Button>
          </BigImageHidden>
          <SmallImageHidden>
            <SmallImageWrapper
              index={
                currentIndex < imageList.length - 5
                  ? currentIndex
                  : imageList.length - 5
              }
            >
              {imageList.map((image, index) => (
                <SmallImage
                  key={index}
                  src={image}
                  onClick={() => openImageViewer(index)}
                />
              ))}
            </SmallImageWrapper>
          </SmallImageHidden>
        </>
      )}
      {isViewerOpen && (
        <ImageViewer
          src={imageList}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </Container>
  );
};

export default StyledSwiper;
