import React, { useContext } from "react";
import GuideHeader from "components/layout/GuideHeader";
import styled, { css } from "styled-components";
import SearchScar from "components/feature/Main/ProjectDetail/ProjectDetail/SearchScar";
import ProjectMember from "components/feature/Main/ProjectDetail/ProjectDetail/ProjectMember";
import { ProjectDetailContext } from "routes/Main/ProjectDetail/ProjectDetail/ProjectDetailContainer";
import NaviModal from "components/feature/Main/ProjectDetail/ProjectDetail/NaviModal";
import MemberEditModal from "components/feature/Main/ProjectDetail/ProjectDetail/MemberEditModal";
import ScarInfo from "components/feature/Main/ProjectDetail/ProjectDetail/ScarInfo";
import { colors } from "styles/colors";
import { dateFormat } from "utils/common";
import ProjectEditModal from "components/feature/Main/ProjectDetail/ProjectDetail/ProjectEditModal";
import headerLogo from "assets/icon/Logo.png";

const Container = styled.div`
  width: 1920px;
  /* min-height: calc(1080px - 82px); */
  min-height: calc(100vh - 154px - 81px - 32px - 100px);
  display: grid;
  grid-template-columns: 380px 957px 400px;
  column-gap: 50px;
  padding: 0 40px;
  margin: 0 auto 100px auto;
`;

const ProjectDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  align-content: flex-start;
  margin: 50px 0 100px 0;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 26px;
`;

const ProjectInfoBox = styled.div`
  height: 159px;
  grid-column: 1/ 3;
  display: grid;
  grid-template-columns: 107px 70px 1fr;
  column-gap: 30px;
  row-gap: 20px;
  padding: 26px 30px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const SubTitleText = styled.div`
  grid-column: 1 / 3;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div``;

const FilterItem = styled.div`
  display: inline-block;
  cursor: pointer;

  &:not(:first-of-type)::before {
    content: "|";
    display: inline-block;
    margin: 0 6px;
  }

  font-weight: ${({ active }) => (active ? "bold" : "")};
`;

const ProjectImage = styled.img`
  grid-row: 1 / 4;
  width: 107px;
  height: 107px;
  border-radius: 10px;
  /* background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%; */
  object-fit: cover;
`;

const Text = styled.div`
  white-space: nowrap;
  color: ${({ GRAY }) => GRAY && `${colors.darkGrayFontColor}`};
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;

const MoreButton = styled.button.attrs(() => ({ type: "button" }))`
  margin-left: auto;
  border-radius: 24px;
  width: 68px;
  height: 47px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
`;

const ProjectDetailPresenter = ({ language }) => {
  const {
    naviModal,
    handleNaviModal,
    memberEditModal,
    project,
    prjEditModal,
    scarOrder,
    handleScarOrder,
  } = useContext(ProjectDetailContext);

  return (
    <>
      <GuideHeader
        labelList={
          (language === "KOR" && ["프로젝트 목록", "프로젝트 상세"]) ||
          (language === "ENG" && ["Project List", "Project Detail"])
        }
      />
      <Container>
        <SearchScar language={language} />
        <ProjectDetailContainer>
          <TitleText>
            {language === "KOR" && "프로젝트 상세정보"}
            {language === "ENG" && "Project Detail"}
          </TitleText>
          <MoreButton onClick={handleNaviModal}>
            {language === "KOR" && "메뉴"}
            {language === "ENG" && "Menu"}
          </MoreButton>
          <SubTitleText>
            {language === "KOR" && "프로젝트 발행 정보"}
            {language === "ENG" && "Project Publishing Information"}
          </SubTitleText>
          <ProjectInfoBox>
            <ProjectImage src={project?.prj_img || headerLogo} />
            <Text GRAY>
              {language === "KOR" && "프로젝트명"}
              {language === "ENG" && "Project Name"}
            </Text>
            <Text width={650}>{project?.prj_title} </Text>
            <Text GRAY>
              {language === "KOR" && "현장주소"}
              {language === "ENG" && "Address"}
            </Text>
            <Text width={650}>{project?.prj_address}</Text>
            <Text GRAY>
              {language === "KOR" && "사업기간"}
              {language === "ENG" && "Duration"}
            </Text>
            <Text>
              {dateFormat(
                new Date(Number(project?.prj_startDate)),
                "yyyy.MM.dd",
              )}{" "}
              ~{" "}
              {dateFormat(new Date(Number(project?.prj_endDate)), "yyyy.MM.dd")}
            </Text>
          </ProjectInfoBox>
          <SubTitleText>
            {language === "KOR" && "SCAR 발행 정보"}
            {language === "ENG" && "SCAR Publishing Information"}
            <Filter>
              <FilterItem
                active={scarOrder === "scar_number"}
                onClick={() => handleScarOrder("scar_number")}
              >
                {language === "KOR" && "문서번호순"}
                {language === "ENG" && "by Number"}
              </FilterItem>
              <FilterItem
                active={scarOrder === "scar_createdAt"}
                onClick={() => handleScarOrder("scar_createdAt")}
              >
                {language === "KOR" && "최신순"}
                {language === "ENG" && "by New"}
              </FilterItem>
              <FilterItem
                active={scarOrder === "scar_riskGrade"}
                onClick={() => handleScarOrder("scar_riskGrade")}
              >
                {language === "KOR" && "위험등급순"}
                {language === "ENG" && "by Risk Grade"}
              </FilterItem>
            </Filter>
          </SubTitleText>

          <ScarInfo />
        </ProjectDetailContainer>
        <ProjectMember language={language} />
      </Container>
      {naviModal && <NaviModal language={language} />}
      {prjEditModal && <ProjectEditModal />}
      {memberEditModal && <MemberEditModal />}
    </>
  );
};

export default ProjectDetailPresenter;
