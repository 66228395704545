import { gql } from "@apollo/client";

export const CREATE_SCAR = gql`
  mutation createScar(
    $prjId: Int!
    $scarSpot: String!
    $scarRiskGrade: riskGrade!
    $scarRiskcode: String!
    $scarLabel: [String]!
    $scarImgSelect: Boolean!
    $signLine: inputSignLine!
    $saveSignLine: Boolean!
    $scarTitle: String!
    $scarImgs: [Upload!]
  ) {
    createScar(
      prj_id: $prjId
      scar_spot: $scarSpot
      scar_riskGrade: $scarRiskGrade
      scar_riskcode: $scarRiskcode
      scar_label: $scarLabel
      scar_imgSelect: $scarImgSelect
      signLine: $signLine
      saveSignLine: $saveSignLine
      scar_title: $scarTitle
      scar_imgs: $scarImgs
    ) {
      result
      error
    }
  }
`;

export const DELETE_SAVE_SIGN_LINE = gql`
  mutation deleteSaveSignLine($sslId: Int!) {
    deleteSaveSignLine(ssl_id: $sslId) {
      result
      error
    }
  }
`;
