import { gql } from "@apollo/client";

export const ADD_PROJECT_MEMBER = gql`
  mutation addProjectMember(
    $group: String
    $emailPhone: Boolean
    $countryCode: String
    $term: String
  ) {
    addProjectMember(
      group: $group
      emailPhone: $emailPhone
      countryCode: $countryCode
      term: $term
    ) {
      result
      error
      inviMemberInfo {
        user_id
        user_name
        user_countryCode
        user_cellphone
        user_email
        user_groupCompany
        user_group
        user_profileImg
      }
    }
  }
`;

export const DELETE_PROJECT_MEMBER = gql`
  mutation deleteProjectMember(
    $prj_id: Int
    $unsignedUser_id: Int
    $user_id: Int
  ) {
    deleteProjectMember(
      prj_id: $prj_id
      unsignedUser_id: $unsignedUser_id
      user_id: $user_id
    ) {
      result
      error
    }
  }
`;

export const EDIT_PROJECT = gql`
  mutation editProject(
    $prjTitle: String!
    $prjAddress: String!
    $prjStartDate: String!
    $prjEndDate: String!
    $prjNumber: String!
    $prjOpenAvailable: Boolean
    $prjId: Int
    $prjImgSame: Boolean
    $prjImg: Upload
  ) {
    editProject(
      prj_title: $prjTitle
      prj_address: $prjAddress
      prj_startDate: $prjStartDate
      prj_endDate: $prjEndDate
      prj_number: $prjNumber
      prj_openAvailable: $prjOpenAvailable
      prj_id: $prjId
      prj_img_same: $prjImgSame
      prj_img: $prjImg
    ) {
      result
      error
    }
  }
`;

export const EDIT_PROJECT_MEMBER = gql`
  mutation editProjectMember(
    $prj_id: Int
    $group: String
    $emailPhone: Boolean
    $countryCode: String
    $term: String
  ) {
    editProjectMember(
      prj_id: $prj_id
      group: $group
      emailPhone: $emailPhone
      countryCode: $countryCode
      term: $term
    ) {
      result
      error
      signupCheck
    }
  }
`;

export const REQUEST_CLOSE_PROJECT = gql`
  mutation requestCloseProject($prj_id: Int) {
    requestCloseProject(prj_id: $prj_id) {
      result
      error
    }
  }
`;

export const WITHDRAWAL_PROJECT = gql`
  mutation withdrawalProject($prjId: Int) {
    withdrawalProject(prj_id: $prjId) {
      result
    }
  }
`;
