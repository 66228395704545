import React, { useCallback, useState, useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import StyledButton from "../../share/StyledButton";
import { FiX } from "react-icons/fi";
import StyledInput from "../../share/StyledInput";
import { useMutation } from "@apollo/client";
import { ASK_ONE_ON_ONE } from "graphql/ServiceCenter/mutation";
import { toast } from "react-toastify";
import { AppContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 461px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: bold;
  color: ${({ color }) => color};
`;

const ButtonWrapper = styled.div`
  width: 168px;
  display: flex;
  justify-content: space-between;
  margin: auto 0 0 auto;
`;

const OneOnOneModal = ({ handleModal, oneRefetch }) => {
  const { language } = useContext(AppContext);
  const [inputs, setInputs] = useState("");

  const [askOneOnOne] = useMutation(ASK_ONE_ON_ONE);

  const handleSave = useCallback(async () => {
    if (inputs.trim() === "") {
      toast.error(
        (language === "KOR" && "내용을 입력해주세요") ||
          (language === "ENG" && "Please enter the contents")
      );
      return;
    }
    try {
      const { data } = await askOneOnOne({
        variables: {
          one_question: inputs,
        },
      });
      if (data?.askOneOnOne?.result) {
        toast.success(
          (language === "KOR" && "문의를 등록했습니다.") ||
            (language === "ENG" && "You have registered an inquiry.")
        );
        handleModal();
        await oneRefetch();
      }
      if (data?.askOneOnOne?.error !== "") {
        toast.error(data?.askOneOnOne?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text>
          {" "}
          {language === "KOR" && "일대일 문의 등록하기"}
          {language === "ENG" && "Registering a One-on-one questions"}
        </Text>
        <FiX className="closeIcon" onClick={() => handleModal()} />
        <StyledInput
          label={
            (language === "KOR" && `문의사항 (${inputs.length} / 80)`) ||
            (language === "ENG" && `questions contents (${inputs.length} / 80)`)
          }
          placeholder={
            (language === "KOR" && "문의사항 입력") ||
            (language === "ENG" && "Enter your inquiry")
          }
          value={inputs}
          onChange={(e) => setInputs(e.target.value)}
          margin="30px 0 0 0"
          maxLength={79}
        />
        <ButtonWrapper>
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            BORDER
            borderColor={colors.grayFontColor}
            labelColor={colors.grayFontColor}
            label={
              (language === "KOR" && "취소") || (language === "ENG" && "Cancel")
            }
            onClick={() => handleModal()}
          />
          <StyledButton
            width={language === "ENG" ? 130 : 74}
            height={46}
            fontSize={16}
            label={
              (language === "KOR" && "등록") ||
              (language === "ENG" && "Registration")
            }
            margin="0 0 0 20px"
            onClick={() => handleSave()}
          />
        </ButtonWrapper>
      </ModalContainer>
    </Container>
  );
};

export default OneOnOneModal;
