import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import StyledInput from "components/share/StyledInput";
import { useQuery } from "@apollo/client";
import {SEARCH_RISK_CODE, SEARCH_RISK_CODE_V2} from "graphql/Main/ProjectDetail/CreateScar/query";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 561px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  word-break: keep-all;
  line-height: 1.2;
`;

const RiskCodeWrapper = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.scrollColor};
    border-radius: 5px;
  }
`;

const RiskCodeCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 10px 10px 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.grayFontColor};

  & > ${Text}:first-child {
    margin-bottom: 16px;
    display: flex;
  }
`;

const SelectButton = styled.button`
  color: ${colors.mainColor};
  margin-left: auto;
`;

const RiskcodeModal = ({ handleModal, setInputs, inputs, language }) => {
  const [keyword, setKeyword] = useState("");
  const [codeList, setCodeList] = useState([]);

  const { data: searchData } = useQuery(SEARCH_RISK_CODE_V2, {
    variables: {
      riskCodeKeyword: keyword,
    },
  });

  useEffect(() => {
    if (searchData?.searchRiskCode_v2?.result) {
      setCodeList(searchData.searchRiskCode_v2.checkItemList);
    }
  }, [searchData]);

  const handleSave = (code) => {
    setInputs({ ...inputs, riskCode: code });
    handleModal();
  };

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text BOLD>
          {language === "KOR" && "리스크코드 입력하기"}
          {language === "ENG" && "Search Risk Code"}
        </Text>
        <FiX className="closeIcon" onClick={() => handleModal()} />
        <StyledInput
          label={
            (language === "KOR" && "키워드로 검색하기") ||
            (language === "ENG" && "Search width Keywords")
          }
          margin="20px 0 "
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <RiskCodeWrapper>
          {codeList.map(code => (
            <RiskCodeCard key={code.cli_id}>
              <Text BOLD>
                {code.cli_riskCode} | {language === 'KOR' && code.cli_safetyCategory_kr} {language === 'ENG' && code.cli_safetyCategory_eng}
                <SelectButton onClick={() => handleSave(code.cli_riskCode)}>
                  {language === "KOR" && "선택"}
                  {language === "ENG" && "Select"}
                </SelectButton>
              </Text>
              <Text>
                {language === 'KOR' && code.cli_checkItem_kr}
                {language === 'ENG' && code.cli_checkItem_eng}
              </Text>
            </RiskCodeCard>
          ))}
        </RiskCodeWrapper>
      </ModalContainer>
    </Container>
  );
};

export default RiskcodeModal;
