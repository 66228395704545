import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "routes/Login";
import Main from "routes/Main/Main";
import AuthProvider from "context/AuthProvider";
import Header from "components/layout/Header";
import ServiceCenter from "routes/ServiceCenter";
import CreateProject from "routes/Main/CreateProject";
import Footer from "components/layout/Footer";
import ProjectDetail from "routes/Main/ProjectDetail/ProjectDetail";
import ScarDetail from "routes/Main/ProjectDetail/ScarDetail";
import Profile from "routes/Profile";
import Signup from "routes/Signup";
import Guide from "routes/Guide";

const LoggedOutRoutes = () => (
  <Routes>
    <Route path="*" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
  </Routes>
);

const LoggedInRoutes = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="*" element={<Main />} />
        <Route path="/" element={<Main />} />
        <Route path="/createProject" element={<CreateProject />} />
        <Route path="/serviceCenter" element={<ServiceCenter />} />
        <Route path="/projectDetail/*" element={<ProjectDetail />} />
        <Route path="/scarDetail/:scarId" element={<ScarDetail />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/guide" element={<Guide />} />
      </Routes>
      <Footer />
    </>
  );
};

const AppRouter = ({ isLoggedIn }) => {
  return (
    <AuthProvider>
      {isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}
    </AuthProvider>
  );
};

export default AppRouter;
