import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import searchIconBlack from "assets/icon/searchIconBlack.svg";
import AddressModal from "./AddressModal";

const Container = styled.div`
  width: ${({ width }) => (!width ? "100%" : `${width}px`)};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 14px;
  border-bottom: 1px solid ${colors.styledInputBorder};
  margin: ${({ margin }) => margin};
`;

const Label = styled.div`
  font-size: 14px;
  color: ${colors.grayFontColor};
`;

const Input = styled.input`
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 18px;

  ::placeholder {
    color: ${colors.grayFontColor};
  }
`;

const ValueText = styled.div`
  font-size: 18px;
  position: relative;
  margin-top: 14px;
  padding-right: 20px;
  word-break: break-all;
  line-height: 1.2;
  /* border: 1px solid red; */

  cursor: ${({ POINTER }) => POINTER && "pointer"};
`;

const Icon = styled.img`
  position: absolute;
  right: 0;
`;

const InputForm = ({
  INPUT,
  ADDRESS,
  label,
  placeholder,
  width,
  inputs,
  setInputs,
  value,
  onChange,
  margin = "",
  name,
  maxLength,
}) => {
  const [addressModal, setAddressModal] = useState(false);
  return (
    <>
      <Container width={width} margin={margin}>
        <Label>{label}</Label>
        {ADDRESS && (
          <ValueText POINTER onClick={() => setAddressModal(true)}>
            {inputs.address}
            <Icon src={searchIconBlack} />
          </ValueText>
        )}

        {INPUT && (
          <Input
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
          />
        )}
      </Container>
      {addressModal && (
        <AddressModal
          setAddressModal={setAddressModal}
          setAddress={(value) => setInputs({ ...inputs, address: value })}
        />
      )}
    </>
  );
};

export default InputForm;
