import { gql } from "@apollo/client";

export const SCAR_APPROVAL = gql`
  mutation scarApproval(
    $scar_id: Int
    $approval: Boolean
    $checkApprovalContent: String
    $rejectText: String
  ) {
    scarApproval(
      scar_id: $scar_id
      approval: $approval
      check_approvalContent: $checkApprovalContent
      rejectText: $rejectText
    ) {
      result
      error
    }
  }
`;

export const TAKE_ACTION_SCAR = gql`
  mutation takeActionScar(
    $scar_id: Int
    $sa_text: String
    $sa_imgSelect: Boolean
    $scarActionImg: [Upload!]
  ) {
    takeActionScar(
      scar_id: $scar_id
      sa_text: $sa_text
      sa_imgSelect: $sa_imgSelect
      scarActionImg: $scarActionImg
    ) {
      result
      error
    }
  }
`;

export const SCAR_DELETE = gql`
  mutation scarDelete($scarId: Int) {
    scarDelete(scar_id: $scarId) {
      result
      error
    }
  }
`;

export const SAFEY_ACTION_DOCUMENT_DOWN = gql`
  mutation safeyActionDocumentDown($scarId: Int) {
    safeyActionDocumentDown(scar_id: $scarId) {
      result
      docs
    }
  }
`;
