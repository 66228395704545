import React, { useContext } from "react";
import styled, { css } from "styled-components";
import logo from "assets/icon/Logo.png";
import { colors } from "styles/colors";
import { LoginContext } from "./LoginContainer";
import StyledButton from "components/share/StyledButton";
import StyledSelect from "components/share/StyledSelect";
import { nationList } from "components/feature/Login/nationNumber";
import InputForm from "components/feature/Login/InputForm";
import { onlyNumber } from "utils/common";

const Container = styled.div`
  min-width: 1920px;
  width: 100%;
  height: 100vh;
  background-color: ${colors.whiteColor};
  display: grid;
  grid-template-columns: 640px minmax(1280px, 1fr);
  position: relative;
`;

const Text = styled.div`
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ BOLD }) => BOLD && "bold"};
`;

const LargeText = styled(Text)`
  font-size: 48px;
  font-weight: bold;
  color: ${colors.mainColor};
`;

const MediumText = styled(Text)`
  font-size: 24px;
`;

const SmallText = styled(Text)`
  font-size: 18px;
  cursor: ${({ POINTER }) => POINTER && "pointer"};
`;

const PageLogo = styled.img`
  position: absolute;
  top: 30px;
  left: 40px;
  width: 100px;
  border-radius: 20px;
`;

const ContentsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ BLUE }) =>
    BLUE &&
    css`
      background-color: ${colors.mainColor};
      & > ${Text} {
        text-align: center;
        color: ${colors.whiteColor};
      }
    `};
`;

const SendButton = styled.button`
  width: 87px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 12px;
  right: calc((-87px + -22px));
  font-size: 24px;
  color: ${colors.grayFontColor};
  border: 1px solid ${colors.grayFontColor};
  border-radius: 10px;
  &:hover {
    border: 1.5px solid ${colors.grayFontColor};
  }
  &:active {
    background-color: ${colors.lightGrayHover};
  }
`;

const RowBox = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  margin: ${({ margin }) => margin};
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: pointer;
    `}
`;

const LoginPresenter = ({ language, setLanguage }) => {
  const {
    inputs,
    handleSelect,
    handleInput,
    confirmAuth,
    goToSignup,
    sendAuthCode,
    loginMode,
    handleLoginMode,
  } = useContext(LoginContext);
  return (
    <Container>
      <PageLogo src={logo} />
      <ContentsContainer BLUE>
        <LargeText lineHeight={1.4}>
          {language === "KOR" && " 반가워요,"}
          {language === "ENG" && "Hello,"}
          <br />
          {language === "KOR" && "리스크제로 HG입니다."}
          {language === "ENG" && "It's RiskZero HG."}
        </LargeText>
        <MediumText lineHeight={1.5} margin="50px 0 80px 0">
          {language === "KOR" && "리스크제로 HG는 안전시정조치요구서의 발행"}
          {language === "ENG" && "Risk Zero HG is an application developed"}
          <br />
          {language === "KOR" &&
            "공유 및 문서화를 하나의 플랫폼에서 수행하도록"}
          {language === "ENG" &&
            "by Hanmi Global to issue, share, and document safety"}
          <br />
          {language === "KOR" &&
            "한미글로벌이 주도하여 개발한 어플리케이션입니다."}
          {language === "ENG" && "corrective action requests on one platform."}
        </MediumText>
        {/* <TutorialButton>사용방법 보러가기</TutorialButton> */}
      </ContentsContainer>
      <ContentsContainer>
        <LargeText margin="0 0 90px 0">
          {language === "KOR" && "로그인하기"}
          {language === "ENG" && "Login"}
        </LargeText>
        {loginMode === "PHONE" ? (
          <>
            <RowBox>
              <StyledSelect
                name="nation"
                label={
                  (language === "KOR" && "국가") ||
                  (language === "ENG" && "Country code")
                }
                width={78}
                value={inputs.nation}
                onChange={handleSelect}
                options={nationList}
              />
              <InputForm
                name="phoneNumber"
                label={
                  (language === "KOR" && "휴대폰번호") ||
                  (language === "ENG" && "Phone number")
                }
                margin="0 0 0 30px"
                width={265}
                value={onlyNumber(inputs.phoneNumber)}
                onChange={handleInput}
              />
              <SendButton onClick={() => sendAuthCode(true)}>
                {language === "KOR" && "전송"}
                {language === "ENG" && "Send"}
              </SendButton>
            </RowBox>
          </>
        ) : null}
        {loginMode === "EMAIL" ? (
          <>
            <RowBox>
              <InputForm
                name="email"
                label={
                  (language === "KOR" && "이메일 주소") ||
                  (language === "ENG" && "E-Mail Address")
                }
                width={373}
                value={inputs.email}
                onChange={handleInput}
              />
              <SendButton onClick={() => sendAuthCode(true)}>
                {language === "KOR" && "전송"}
                {language === "ENG" && "Send"}
              </SendButton>
            </RowBox>
          </>
        ) : null}
        <InputForm
          name="authcode"
          label={
            (language === "KOR" && "6자리 인증번호") ||
            (language === "ENG" && "6-digit code")
          }
          margin="40px 0 70px 0"
          width={373}
          value={onlyNumber(inputs.authcode)}
          onChange={handleInput}
          maxLength={6}
          onlyNumber
        />
        <StyledButton
          label={
            (language === "KOR" && "로그인하기") ||
            (language === "ENG" && "Login")
          }
          width={373}
          height={68}
          margin="0 0 191px 0"
          onClick={() => confirmAuth(true)}
        />
        <RowBox cursor={true}>
          <SmallText
            color={colors.grayFontColor}
            margin="0 10px 0 0"
            onClick={() =>
              loginMode === "EMAIL"
                ? handleLoginMode("PHONE")
                : handleLoginMode("EMAIL")
            }
          >
            {loginMode === "EMAIL" ? (
              <>
                {language === "KOR" && "휴대폰으로 로그인 하기"}
                {language === "ENG" && "Login with phone"}
              </>
            ) : (
              <>
                {language === "KOR" && "이메일로 로그인 하기"}
                {language === "ENG" && "Login with e-mail"}
              </>
            )}
          </SmallText>
        </RowBox>
        <RowBox>
          <SmallText color={colors.grayFontColor} margin="20px 10px 0 0">
            {language === "KOR" && "가입하지 않으셨나요?"}
            {language === "ENG" && "Don't have an account?"}
          </SmallText>
          <SmallText POINTER BOLD onClick={() => goToSignup()}>
            {language === "KOR" && "회원가입하기"}
            {language === "ENG" && "Sign Up"}
          </SmallText>
        </RowBox>
        <RowBox margin="20px 0 0 0">
          <SmallText
            POINTER
            BOLD
            color={language !== "KOR" ? colors.grayFontColor : ""}
            onClick={() => {
              localStorage.setItem("lang", "KOR");
              setLanguage("KOR");
            }}
          >
            KOR{" "}
          </SmallText>
          <SmallText margin="0 10px">|</SmallText>
          <SmallText
            POINTER
            BOLD
            color={language !== "ENG" ? colors.grayFontColor : ""}
            onClick={() => {
              localStorage.setItem("lang", "ENG");
              setLanguage("ENG");
            }}
          >
            ENG
          </SmallText>
        </RowBox>
      </ContentsContainer>
    </Container>
  );
};

export default LoginPresenter;
