import React, {useCallback, useContext, useRef, useState} from "react";
import {ProjectDetailContext} from "routes/Main/ProjectDetail/ProjectDetail/ProjectDetailContainer";
import styled from "styled-components";
import {colors} from "styles/colors";
import resetIcon from "assets/icon/resetIcon.svg";
import StyledInput from "components/share/StyledInput";
import StyledButton from "components/share/StyledButton";
import {useNavigate} from "react-router-dom";
import {ProfileContext} from "App";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const WhiteBox = styled.div`
  width: 380px;
  height: 332px;
  padding: 26px 30px;
  margin: 135px 0 20px 0;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const TitleText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({color}) => color};
`;

const ResetButton = styled.div`
  font-size: 14px;
  color: ${colors.grayFontColor};
  cursor: pointer;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({padding}) => padding};
  margin: ${({margin}) => margin};
  border-bottom: ${({BORDER}) =>
          BORDER && `1px solid ${colors.lightgrayBorder}`};
`;

const ResetIcon = styled.img`
  margin: 0 11px 0 auto;
  cursor: pointer;

  &.rotate {
    transition: transform 1s;
    transform: rotate(-360deg);
  }
`;

const StateButton = styled.button`
  width: 150px;
  height: 43px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  color: ${({selected}) => !selected && `${colors.grayFontColor}`};
  border: ${({selected}) =>
          selected
                  ? `1px solid ${colors.blueBorder}`
                  : `1px solid ${colors.lightgrayBorder}`};
  background-color: ${({selected}) =>
          !selected && `${colors.lightgrayBackgroundColor}`};
`;

const StateWrapper = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
`;

const StateText = styled.div`
  font-weight: ${({BOLD}) => BOLD && "bold"};
  text-align: start;

  &:nth-child(2) {
    margin-left: auto;
  }

  &:last-child {
    margin-left: 8px;
  }
`;

const SearchScar = ({language}) => {
    const icon = useRef();
    const {inputs, setInputs, handleReset, scarCount, project, scarCreatePermission} = useContext(ProjectDetailContext);
    const {myProfile} = useContext(ProfileContext);
    const [rotateClass, setRotateClass] = useState(false);
    const navigate = useNavigate();

    const rotateIcon = (e) => {
        handleReset();
        e.preventDefault();
        setRotateClass(true);
        setTimeout(() => setRotateClass(false), 1000);
    };

    const handleState = useCallback((state) => {
        setInputs({...inputs, state: state});
        sessionStorage.setItem("sort", state);
    }, [inputs]);

    return (
        <Container>
            <WhiteBox>
                <RowBox>
                    <TitleText>
                        {language === "KOR" && "SCAR 검색하기"}
                        {language === "ENG" && "Search SCAR"}
                    </TitleText>
                    <ResetIcon
                        src={resetIcon}
                        ref={icon}
                        onClick={rotateIcon}
                        className={rotateClass && "rotate"}
                    />
                    <ResetButton onClick={rotateIcon}>
                        {language === "KOR" && "초기화"}
                        {language === "ENG" && "Reset"}
                    </ResetButton>
                </RowBox>
                <StyledInput
                    SEARCHICON
                    fontSize={18}
                    placeholder={
                        (language === "KOR" && "검색하기") ||
                        (language === "ENG" && "Search")
                    }
                    value={inputs.searchTerm}
                    onChange={(e) => setInputs({...inputs, searchTerm: e.target.value})}
                />
                <StateWrapper>
                    <StateButton
                        selected={inputs.state === "전체"}
                        onClick={() => handleState("전체")}
                    >
                        <StateText>
                            {language === "KOR" && "전체"}
                            {language === "ENG" && "Total"}
                        </StateText>
                        <StateText BOLD> {scarCount?.totalCount}</StateText>
                        <StateText>{language === "KOR" && "건"}</StateText>
                    </StateButton>
                    <StateButton
                        selected={inputs.state === "진행중"}
                        onClick={() => handleState("진행중")}
                    >
                        <StateText>
                            {language === "KOR" && "진행중"}
                            {language === "ENG" && "In Progress"}
                        </StateText>
                        <StateText BOLD>{scarCount?.inProgressCount}</StateText>
                        <StateText>{language === "KOR" && "건"}</StateText>
                    </StateButton>
                    <StateButton
                        selected={inputs.state === "조치필요"}
                        onClick={() => handleState("조치필요")}
                    >
                        <StateText>
                            {language === "KOR" && "조치필요"}
                            {language === "ENG" && "Action Required"}
                        </StateText>
                        <StateText BOLD>{scarCount?.actionRequireCount}</StateText>
                        <StateText>{language === "KOR" && "건"}</StateText>
                    </StateButton>
                    <StateButton
                        selected={inputs.state === "확인필요"}
                        onClick={() => handleState("확인필요")}
                    >
                        <StateText>
                            {language === "KOR" && "확인필요"}
                            {language === "ENG" && "Approval Required"}
                        </StateText>
                        <StateText BOLD>{scarCount?.confirmRequireCount}</StateText>
                        <StateText>{language === "KOR" && "건"}</StateText>
                    </StateButton>
                    <StateButton
                        selected={inputs.state === "불가/반려"}
                        onClick={() => handleState("불가/반려")}
                    >
                        <StateText>
                            {language === "KOR" && "불가/반려"}
                            {language === "ENG" && "Rejected"}
                        </StateText>
                        <StateText BOLD>{scarCount?.rejectCount}</StateText>
                        <StateText>{language === "KOR" && "건"}</StateText>
                    </StateButton>
                    <StateButton
                        selected={inputs.state === "최종완료"}
                        onClick={() => handleState("최종완료")}
                    >
                        <StateText>
                            {language === "KOR" && "최종완료"}
                            {language === "ENG" && "Closed"}
                        </StateText>
                        <StateText BOLD>{scarCount?.finalCount}</StateText>
                        <StateText>{language === "KOR" && "건"}</StateText>
                    </StateButton>
                </StateWrapper>
            </WhiteBox>
            {// (project?.prj_endRequestDate !== "" ? Number(project?.prj_endRequestDate) > new Date().getTime() : Number(project?.prj_endDate) > new Date().getTime()) && (myProfile?.user_group === "head" || myProfile?.user_group === "local")
                scarCreatePermission && (
                    <>
                        <StyledButton
                            label={
                                (language === "KOR" && "SCAR 발행하기") ||
                                (language === "ENG" && "Create SCAR")
                            }
                            width={380}
                            height={48}
                            fontSize={18}
                            onClick={() => navigate("/projectDetail/createScar")}
                        />
                        <StyledButton
                            label={
                                (language === "KOR" && "체크리스트 평가하기") ||
                                (language === "ENG" && "Check list")
                            }
                            bgColor=""
                            labelColor={colors.blackColor}
                            borderColor={colors.grayFontColor}
                            BORDER
                            width={380}
                            height={48}
                            fontSize={18}
                            margin="20px 0 0 0"
                            onClick={() => navigate("/projectDetail/checkList")}
                        />
                    </>
                )}
        </Container>
    );
};

export default SearchScar;
