import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { groupChanger } from "utils/common";
import { ProjectDetailContext } from "routes/Main/ProjectDetail/ProjectDetail/ProjectDetailContainer";

const Container = styled.div`
  width: 400px;
  height: 632px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const MemberWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.scrollColor};
  }
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 56px;
`;

const MemberBox = styled.div`
  width: 109px;
  height: 153px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const ProfileImage = styled.div`
  width: 69px;
  height: 69px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: ${({ src }) => (src === "" ? "40%" : "cover")};
  flex-shrink: 0;
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  &:nth-child(2) {
    color: ${colors.grayFontColor};
  }
  &:last-child {
    font-size: 18px;
    margin-top: 6px;
  }
`;

const ProjectMember = ({ language }) => {
  const { memberList } = useContext(ProjectDetailContext);

  return (
    <Container>
      <TitleText>
        {language === "KOR" && "프로젝트 구성원"}
        {language === "ENG" && "Profile Info"}
      </TitleText>
      <MemberWrapper>
        {memberList.map((user, index) => (
          <MemberBox key={index}>
            <ProfileImage src={user?.user_profileImg} />
            <Text GRAY>{groupChanger(user?.user_group, language)}</Text>
            <Text width={90}>{user?.user_name}</Text>
          </MemberBox>
        ))}
      </MemberWrapper>
    </Container>
  );
};

export default ProjectMember;
