import React, { useContext, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import { AppContext } from "../../../../App";
import StyledInput from "../../../share/StyledInput";
import { toast } from "react-toastify";

const AddressModal = ({ setAddressModal, setAddress }) => {
  const { language } = useContext(AppContext);
  const [directInput, setDirectInput] = useState("");
  const [mode, setMode] = useState("google");

  const handleDirectInput = (e) => {
    const {
      target: { value },
    } = e;
    setDirectInput(value);
  };

  const handleKakaoComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAddress(fullAddress);
    setAddressModal(false);
  };

  const handleDirectComplete = (e) => {
    if (!directInput.trim()) {
      if (language === "KOR") toast.error("주소를 입력해주시기 바랍니다.");
      if (language === "ENG") toast.error("Please enter address");
      return;
    }
    setAddress(directInput);
    setAddressModal(false);
  };

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <FiX className="closeIcon" onClick={() => setAddressModal()} />
        <TabList>
          <Tab selected={mode === "google"} onClick={() => setMode("google")}>
            {language === "KOR" ? "구글" : null}
            {language === "ENG" ? "Google" : null}
          </Tab>
          <Tab selected={mode === "kakao"} onClick={() => setMode("kakao")}>
            {language === "KOR" ? "카카오" : null}
            {language === "ENG" ? "Kakao" : null}
          </Tab>
          <Tab selected={mode === "direct"} onClick={() => setMode("direct")}>
            {language === "KOR" ? "직접입력" : null}
            {language === "ENG" ? "Direct input" : null}
          </Tab>
        </TabList>
        <AddressBox>
          {mode === "google" ? (
            <>
              {language === "KOR" ? "기능 준비 중에 있습니다." : null}
              {language === "ENG" ? "The feature is in preparation." : null}
            </>
          ) : null}
          {mode === "kakao" ? (
            <DaumPostcode onComplete={handleKakaoComplete} />
          ) : null}
          {mode === "direct" ? (
            <>
              <LabelText>
                {language === "KOR" ? "주소" : null}
                {language === "ENG" ? "Address" : null}
              </LabelText>
              <StyledInput value={directInput} onChange={handleDirectInput} />
              <Button onClick={handleDirectComplete}>
                {language === "KOR" ? "완료" : null}
                {language === "ENG" ? "Complete" : null}
              </Button>
            </>
          ) : null}
        </AddressBox>
      </ModalContainer>
    </Container>
  );
};

export default AddressModal;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${colors.whiteColor};
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  & > .closeIcon {
    margin: 0 0 10px auto;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const AddressBox = styled.div`
  overflow: hidden;
  padding: 24px 0 12px;
`;

const TabList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tab = styled.button`
  height: 26px;
  padding: 0 10px;
  flex-grow: 1;
  border-bottom: 2px solid #000000;

  ${({ selected }) =>
    selected &&
    css`
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-top: 2px solid #000000;
      border-left: 2px solid #000000;
      border-right: 2px solid #000000;
      border-bottom: none;
      font-weight: bold;
    `}
`;

const LabelText = styled.div`
  font-size: 14px;
  color: ${colors.grayFontColor};
`;

const Button = styled.button`
  width: 100%;
  background-color: ${colors.mainColor};
  font-weight: bold;
  color: ${colors.whiteColor};
  border-radius: 8px;
  padding: 12px 0;
  margin-top: 12px;
`;
