import React, {memo} from "react";
import styled from "styled-components";
import goBackIcon from "assets/icon/goBackIcon.svg";
import {colors} from "styles/colors";
import GuideHeader from "components/layout/GuideHeader";
import StyledButton from "components/share/StyledButton";
import CheckItem from "../../../../components/feature/CheckList/CheckItem";

const Container = styled.div`
  min-width: 1920px;
  width: 100%;
  min-height: calc(100vh - 154px - 81px - 32px);
  background-color: ${colors.whiteColor};
  position: relative;
  padding-bottom: 100px;
`;
const GoBackIconBtn = styled.img`
  position: absolute;
  top: 50px;
  left: 40px;
  cursor: pointer;
`;
const CenterColumn = styled.div`
  width: 957px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;
const Text = styled.div`
  font-size: ${({fontSize}) => (fontSize ? fontSize : 18)}px;
  font-weight: ${({BOLD}) => BOLD && "bold"};
  margin: ${({margin}) => margin};
  color: ${({color}) => color};
  cursor: ${({POINTER}) => POINTER && "pointer"};
  display: flex;
  align-items: center;
`;
const CriteriaBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 140px;
  left: 40px;

  & > ${Text} {
    margin: 12px 0 0 0;
  }

  & > ${Text}:first-child {
    margin: 0;
  }
`;

const CheckPresenter = ({
                            language,
                            navigate,
                            weeklySafetyCategory = [],
                            data,
                            refetch,
                            handleSaveEvaluation,
                        }) => {
    return (
        <>
            <GuideHeader
                labelList={
                    (language === "KOR" && ["프로젝트 목록", "프로젝트 상세"]) ||
                    (language === "ENG" && ["Project List", "Project Detail"])
                }
            />
            <Container>
                <GoBackIconBtn
                    src={goBackIcon}
                    onClick={() => navigate("/projectDetail/checkDetail")}
                />
                <CriteriaBox>
                    <Text BOLD>점수 설명</Text>
                    <Text>
                        0
                        &nbsp;:&nbsp;
                        {language === 'KOR' && '준수하지 않음'}
                        {language === 'ENG' && 'Unsatisfactory'}
                    </Text>
                    <Text>
                        1
                        &nbsp;:&nbsp;
                        {language === 'KOR' && '일부 준수(0~50%)'}
                        {language === 'ENG' && 'Marginal(0~50%)'}
                    </Text>
                    <Text>
                        2
                        &nbsp;:&nbsp;
                        {language === 'KOR' && '대부분 준수(50~80%)'}
                        {language === 'ENG' && 'Partial(50~80%)'}
                    </Text>
                    <Text>
                        3
                        &nbsp;:&nbsp;
                        {language === 'KOR' && '준수(80~100%)'}
                        {language === 'ENG' && 'Satisfactory(80~100%)'}
                    </Text>
                    <Text>
                        N/A
                        &nbsp;:&nbsp;
                        {language === 'KOR' && '해당없음'}
                        {language === 'ENG' && 'Not applicable'}
                    </Text>
                </CriteriaBox>
                <CenterColumn>
                    <Text BOLD fontSize={24} margin="50px 0 26px 0">
                        {language === "KOR" && "체크리스트 주간평가"}
                        {language === "ENG" && "Review Weekly Checklist"}
                    </Text>
                    <Text fontSize={16} margin="0 0 16px 0">
                        {language === 'KOR' && data?.safetyCategoryKr}
                        {language === 'ENG' && data?.safetyCategoryEng}
                        &nbsp;({data?.scComplyRate}% {(language === 'KOR' && '준수') || (language === 'ENG' && 'Comply')})
                    </Text>

                    {weeklySafetyCategory.map(item => (
                        <CheckItem
                            top
                            key={item.wci_id}
                            title={
                                (language === 'KOR' && item.wci_checkItem_kr) ||
                                (language === 'ENG' && item.wci_checkItem_eng)
                            }
                            item={item}
                            refetch={refetch}
                            status={data?.scStatus}
                        />
                    ))}
                    {data?.scStatus === 'inProgress' && (
                        <StyledButton
                            label={(language === 'KOR' && '평가하기') || (language === 'ENG' && 'Save')}
                            width={468}
                            height={54}
                            margin="40px 0 300px auto"
                            onClick={handleSaveEvaluation}
                        />
                    )}
                </CenterColumn>
            </Container>
        </>
    );
};

export default memo(CheckPresenter);
