export const prjStatusChanger = (value) => {
  switch (value) {
    case "total":
      return "전체";
    case "inProgress":
      return "진행중";
    case "toEnd":
      return "종료예정";
    case "end":
      return "종료";
    case "전체":
      return "total";
    case "진행중":
      return "inProgress";
    case "종료예정":
      return "toEnd";
    case "종료":
      return "end";
  }
};

export const sortChanger = (value) => {
  switch (value) {
    case "내림차순":
      return "desc";
    case "오름차순":
      return "asc";
  }
};
