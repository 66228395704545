import React, {useContext, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {colors} from "styles/colors";
import {useQuery} from "@apollo/client";
import {StatsContext} from "routes/Main/ProjectDetail/Stats/StatsContainer";
import {AppContext} from "App";
import {engLabelChanger} from "utils/common";

const Container = styled.div`
  width: 468px;
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

const Text = styled.div`
  width: ${({width}) => width}px;
  color: ${({color}) => color};
  font-weight: ${({BOLD}) => BOLD && "bold"};
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  
  ${({ RIGHT }) => RIGHT && css`
    text-align: right;
  `};
`;

const RowBox = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: ${({GRAY}) => GRAY && 16}px;
  background-color: ${({GRAY}) =>
          GRAY ? `${colors.lightgrayBorder}` : `${colors.whiteColor}`};
`;

const TextWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 12px;

  & > ${Text} {
    margin-top: 6px;
  }
`;

const Table = ({
                   SCARTOTAL,
                   SCARTOTAL2,
                   SCARLABEL,
                   CHECKTOTAL,
                   CHECKLABEL,
                   checkListStatsData,
               }) => {
    const {language} = useContext(AppContext);
    const {byScarCount, byLabelScarCount, byStatus} = useContext(StatsContext);

    const [createTotal, setCreateTotal] = useState(0);
    const [completeTotal, setCompleteTotal] = useState(0);
    const [imcompleteTotal, setImcompleteTotal] = useState(0);

    useEffect(() => {
        if (byLabelScarCount) {
            setCreateTotal(0);
            setCompleteTotal(0);
            setImcompleteTotal(0);
            byLabelScarCount.forEach((label) => {
                setCreateTotal((createTotal) => createTotal + label.createCount);
                setCompleteTotal(
                    (completeTotal) => completeTotal + label.completeCount
                );
                setImcompleteTotal(
                    (imcompleteTotal) => imcompleteTotal + label.imcompleteCount
                );
            });
        }
    }, [byLabelScarCount]);

    console.info('checkListStatsData', checkListStatsData)

    return (
        <Container>
            {SCARTOTAL && (
                <>
                    <Text>
                        {language === "KOR" && "SCAR 기간 내 총 발행 건수"}
                        {language === "ENG" && "SCAR Status"}
                    </Text>
                    <RowBox GRAY>
                        <Text width={126}>
                            {language === "KOR" && "총 건수"}
                            {language === "ENG" && "Issued"}
                        </Text>
                        <Text width={135}>
                            {language === "KOR" && "처리누계"}
                            {language === "ENG" && "Closed"}
                        </Text>
                        <Text width={103}>
                            {language === "KOR" && "미처리"}
                            {language === "ENG" && "Open"}
                        </Text>
                    </RowBox>
                    <RowBox>
                        <Text width={126}>
                            {byScarCount?.total} {language === "KOR" && "건"}{" "}
                            {language === "ENG" && "count"}
                        </Text>
                        <Text width={135}>
                            {byScarCount?.complete} {language === "KOR" && "건"}{" "}
                            {language === "ENG" && "count"}
                        </Text>
                        <Text width={103}>
                            {byScarCount?.unprocessed} {language === "KOR" && "건"}{" "}
                            {language === "ENG" && "count"}
                        </Text>
                    </RowBox>
                </>
            )}
            {CHECKTOTAL && (
                <>
                    <Text BOLD>
                        {language === "KOR" && "체크리스트 총 평가 통계"}
                        {language === "ENG" && "SCAR Status"}
                    </Text>
                    <RowBox GRAY>
                        <Text width={111}>
                            {language === "KOR" && "총준수율"}
                            {language === "ENG" && "Issued"}
                        </Text>
                        <Text width={118}>
                            {language === "KOR" && "평균 총점"}
                            {language === "ENG" && "Closed"}
                        </Text>
                        <Text width={131}>
                            {language === "KOR" && "총 평균 점수"}
                            {language === "ENG" && "Open"}
                        </Text>
                        <Text width={85}>
                            {language === "KOR" && "반복 평균"}
                            {language === "ENG" && "Open"}
                        </Text>
                    </RowBox>
                    <RowBox>
                        <Text width={111}>{88}%</Text>
                        <Text width={118}>{290}</Text>
                        <Text width={131}>{80}</Text>
                        <Text width={85}>{8}</Text>
                    </RowBox>
                </>
            )}
            {SCARTOTAL2 && (
                <>
                    <Text>
                        {language === "KOR" && "SCAR 기간 내 총 발행 건수"}
                        {language === "ENG" && "SCAR Status"}
                    </Text>
                    <RowBox GRAY>
                        <Text width={165}>
                            {" "}
                            {language === "KOR" && "최종완료"}
                            {language === "ENG" && "Closed"}
                        </Text>
                        <Text width={152}>
                            {" "}
                            {language === "KOR" && "진행중"}
                            {language === "ENG" && "In Progress"}
                        </Text>
                        <Text width={113}>
                            {" "}
                            {language === "KOR" && "지연"}
                            {language === "ENG" && "Delay"}
                        </Text>
                    </RowBox>
                    <RowBox>
                        <Text width={66}>
                            {byStatus?.completeCount} / {byScarCount?.total}{" "}
                        </Text>
                        <Text width={98}>{byStatus?.completeRatio} %</Text>
                        <Text width={63}>
                            {byStatus?.inProgressCount} / {byScarCount?.total}{" "}
                        </Text>
                        <Text width={90}>{byStatus?.inProgressRatio} %</Text>
                        <Text width={61}>
                            {byStatus?.delayCount} / {byScarCount?.total}{" "}
                        </Text>
                        <Text width={52}>{byStatus?.delayRatio} %</Text>
                    </RowBox>
                </>
            )}
            {SCARLABEL && (
                <>
                    <Text>
                        {language === "KOR" && "SCAR 라벨별"}
                        {language === "ENG" && "SCAR by label"}
                    </Text>
                    <RowBox GRAY>
                        <Text width={97}>
                            {" "}
                            {language === "KOR" && "분류"}
                            {language === "ENG" && "Label"}
                        </Text>
                        <Text width={67} RIGHT>
                            {language === "KOR" && "발행"}
                            {language === "ENG" && "Issued"}
                        </Text>
                        <Text width={73} RIGHT>
                            {language === "KOR" && "완료"}
                            {language === "ENG" && "Closed"}
                        </Text>
                        <Text width={82} RIGHT>
                            {language === "KOR" && "미완료"}
                            {language === "ENG" && "Open"}
                        </Text>
                        <Text width={111} RIGHT>
                            %
                        </Text>
                    </RowBox>
                    {byLabelScarCount?.map((label, index) => (
                        <RowBox key={index}>
                            <Text width={97}>
                                {language === "KOR" && label.labelName}{" "}
                                {language === "ENG" && engLabelChanger(label.labelName)}
                            </Text>
                            <Text width={67} RIGHT>
                                {label.createCount}
                            </Text>
                            <Text width={73} RIGHT>
                                {label.completeCount}
                            </Text>
                            <Text width={82} RIGHT>
                                {label.imcompleteCount}
                            </Text>
                            <Text width={111} RIGHT color={colors.blueBorder}>
                                {label.ratio}%
                            </Text>
                        </RowBox>
                    ))}
                    <RowBox>
                        <Text width={97}>
                            {language === "KOR" && "합계"}
                            {language === "ENG" && "Total"}
                        </Text>
                        <Text width={67} RIGHT>
                            {createTotal}
                        </Text>
                        <Text width={73} RIGHT>
                            {completeTotal}
                        </Text>
                        <Text width={82} RIGHT>
                            {imcompleteTotal}
                        </Text>
                    </RowBox>
                </>
            )}
            {CHECKLABEL && (
                <>
                    <Text BOLD>
                        {language === "KOR" && "체크리스트 범주별 평균 통계"}
                        {language === "ENG" && "SCAR by label"}
                    </Text>
                    <TextWrapper>
                        <Text>
                            MA :
                            {language === 'KOR' && '평가 총점 평균'}
                            {language === 'ENG' && 'Max Count AV.'}
                        </Text>
                        <Text>
                            CO :
                            {language === 'KOR' && '전체 점수 평균'}
                            {language === 'ENG' && 'Total Count AV'}
                        </Text>
                        <Text>
                            PE :
                            {language === 'KOR' && '평균 %'}
                            {language === 'ENG' && 'Percent AV.'}
                        </Text>
                        <Text>
                            RE :
                            {language === 'KOR' && '반복 위반 평균'}
                            {language === 'ENG' && 'Repeat Findings AV.'}
                        </Text>
                        <Text>
                            SC :
                            {language === 'KOR' && '스카 발행수 평균'}
                            {language === 'ENG' && 'SCAR Count AV.'}
                        </Text>
                    </TextWrapper>
                    <RowBox GRAY>
                        <Text width={120}>
                            {" "}
                            {language === "KOR" && "ITEM"}
                            {language === "ENG" && "ITEM"}
                        </Text>
                        <Text width={100} RIGHT>
                            {language === "KOR" && "MA./CO."}
                            {language === "ENG" && "MA./CO."}
                        </Text>
                        <Text width={70} RIGHT>
                            {language === "KOR" && "PE."}
                            {language === "ENG" && "PE."}
                        </Text>
                        <Text width={70} RIGHT>
                            {language === "KOR" && "RE."}
                            {language === "ENG" && "RE."}
                        </Text>
                        <Text width={70} RIGHT>
                            {language === "KOR" && "SC."}
                            {language === "ENG" && "SC."}
                        </Text>
                    </RowBox>
                    {checkListStatsData?.bySafetyCategoryStatistics?.categoryStatisticsList?.map((label, index) => (
                        <RowBox key={index}>
                            <Text width={120}>
                                {language === "KOR" && label.item_kr}
                                {language === "ENG" && label.item_eng}
                            </Text>
                            <Text width={100} RIGHT>
                                {label.MA} / {label.CO}
                            </Text>
                            <Text width={70} RIGHT>
                                {label.PE}
                            </Text>
                            <Text width={70} RIGHT>
                                {label.RE}
                            </Text>
                            <Text width={70} RIGHT>
                                {label.SC}
                            </Text>
                        </RowBox>
                    ))}
                    <RowBox>
                        <Text width={120}>
                            {language === "KOR" && "합계"}
                            {language === "ENG" && "Total"}
                        </Text>
                        <Text width={100} RIGHT>
                            {checkListStatsData?.bySafetyCategoryStatistics?.totalMA} / {checkListStatsData?.bySafetyCategoryStatistics?.totalCO}
                        </Text>
                        <Text width={70} RIGHT>
                            {checkListStatsData?.bySafetyCategoryStatistics?.totalPE}
                        </Text>
                        <Text width={70} RIGHT>
                            {checkListStatsData?.bySafetyCategoryStatistics?.totalRE}
                        </Text>
                        <Text width={70} RIGHT>
                            {checkListStatsData?.bySafetyCategoryStatistics?.totalSC}
                        </Text>
                    </RowBox>
                </>
            )}
        </Container>
    );
}
;

export default Table;
