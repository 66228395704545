import React, {useCallback, useContext, useEffect} from "react";
import CheckListPresenter from "./CheckListPresenter";
import {AppContext} from "App";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_PROJECT_CHECK_LIST} from "../../../../graphql/CheckList/query";
import {UPDATE_EVALUATOR} from "../../../../graphql/CheckList/mutation";

const CheckListContainer = () => {
    const {language} = useContext(AppContext);
    const navigate = useNavigate();
    const prjId = parseInt(localStorage.getItem('prjId'), 10);

    // Query
    const {data, loading, refetch} = useQuery(SEE_PROJECT_CHECK_LIST, {
        variables: {
            prjId: prjId,
        },
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {
        console.info('seeProjectCheckList', data?.seeProjectCheckList);
    }, [data]);

    // Mutation
    const [updateEvaluator] = useMutation(UPDATE_EVALUATOR);

    const handleEvaluation = useCallback(async wclId => {
        if (data?.seeProjectCheckList?.evaluationPermission === 0) {
            if (window.confirm(
                (language === "KOR" && "평가를 시작하시면 취소할 수 없으며\n다른 사람은 평가할 수 없습니다.") ||
                (language === "ENG" && "If you start the evaluation, you can't cancel it\nNo one else can evaluate it")
            )) {
                try {
                    const { data: updateEvaluatorData } = await updateEvaluator({
                        variables: {
                            wclId: data?.seeProjectCheckList?.thisWeekCheckList?.wcl_id
                        }
                    });

                    console.info('updateEvaluatorData : ', updateEvaluatorData);

                    if (updateEvaluatorData?.updateEvaluator?.result) {
                        localStorage.setItem('evaluationPermission', updateEvaluatorData?.updateEvaluator?.evaluationPermission);
                        navigate('/projectDetail/checkDetail');
                    }
                } catch(e) {
                    console.info(e.message);
                }
            }
        } else {
            localStorage.setItem('wclId', wclId);
            navigate('/projectDetail/checkDetail');
        }
    }, [data]);

    useEffect(() => {
        if (!loading && data) {
            localStorage.setItem('wclId', data?.seeProjectCheckList?.thisWeekCheckList?.wcl_id);
            localStorage.setItem('evaluationPermission', data?.seeProjectCheckList?.evaluationPermission);
        }
    }, [data, loading]);


    return (
            <CheckListPresenter
                language={language}
                navigate={navigate}
                loading={loading}
                completeCheckList={data?.seeProjectCheckList?.completeCheckList}
                thisWeekCheckList={data?.seeProjectCheckList?.thisWeekCheckList}
                evaluationPermission={data?.seeProjectCheckList?.evaluationPermission}
                handleEvaluation={handleEvaluation}
            />
        );
};

export default CheckListContainer;
