import React, { useState, useCallback, useContext } from "react";
import SignupPresenter from "./SignupPresenter";
import { useMutation } from "@apollo/client";
import { REQUEST_AUTH_CODE, CONFIRM_AUTH_CODE } from "graphql/Login/mutation";
import { toast } from "react-toastify";
import {
  groupChanger,
  validatePhoneNumber,
  getCountryNumberToCode,
  validateEmail,
} from "utils/common";
import { USER_SIGNUP } from "graphql/Login/mutation";
import { useNavigate } from "react-router-dom";
import { AppContext } from "App";

const SignupContainer = () => {
  const { language, setLanguage } = useContext(AppContext);
  const [profileImage, setProfileImage] = useState("");
  const [inputs, setInputs] = useState({
    signUpMode: "PHONE",
    nation: "+82",
    phoneNumber: "",
    profileImage: "",
    authcode: "",
    mail: "",
    name: "",
    rank: "",
    team: "",
    company: "",
    companyCode: "",
    sendcode: false,
    auth: false,
    agree: false,
  });

  const navigate = useNavigate();

  const [requestAuthCode] = useMutation(REQUEST_AUTH_CODE);
  const [confirmAuthCode] = useMutation(CONFIRM_AUTH_CODE);
  const [userSignup] = useMutation(USER_SIGNUP);

  const validateContryCode = {
    "+1": /^\d{9}$/,
    "+44": /^\d{10}$/,
    "+48": /^\d{10}$/,
    "+82": /^\d{11}$/,
    "+84": /^\d{10}$/,
    "+86": /^\d{11}$/,
    "+966": /^\d{9}$/,
  };

  const handleSignUpMode = useCallback((signUpMode) => {
    setInputs((prev) => ({ ...prev, signUpMode }));
  }, []);

  const handleInput = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs],
  );

  const handleSelect = useCallback(
    (name, value) => {
      setInputs({ ...inputs, [name]: value });
    },
    [inputs],
  );

  const goToLogin = useCallback(() => {
    navigate("/");
  }, []);

  const sendAuthCode = useCallback(async () => {
    if (inputs.nation === "선택") {
      toast.error(
        (language === "KOR" && "국가를 선택해주세요.") ||
          (language === "ENG" && "Please select country code."),
      );
      return;
    }
    if (inputs.signUpMode === "PHONE" && inputs.phoneNumber.trim() === "") {
      toast.error(
        (language === "KOR" && "휴대폰번호를 입력해주세요.") ||
          (language === "ENG" && "Please enter phone number."),
      );
      return;
    }

    if (inputs.signUpMode === "PHONE") {
      const refexPattern = validateContryCode[inputs.nation]; // 국가코드에 따른 정규식 선택
      const isValidNumber = refexPattern.test(inputs.phoneNumber); // 정규식 테스트

      if (!isValidNumber) {
        toast.error(
          (language === "KOR" && "휴대폰번호 형식에 맞게 입력해주세요.") ||
            (language === "ENG" &&
              "Enter the phone number according to the format."),
        );
        return;
      }
    }

    if (inputs.signUpMode === "EMAIL") {
      if (!validateEmail(inputs.mail)) {
        toast.error(
          (language === "KOR" && "이메일 형식에 맞게 입력해주세요.") ||
            (language === "ENG" && "Enter the e-mail according to the format."),
        );
        return;
      }
    }

    if (
      inputs.signUpMode === "PHONE" &&
      !validatePhoneNumber(
        inputs.phoneNumber.trim(),
        getCountryNumberToCode(inputs.nation),
      )
    ) {
      toast.error(
        (language === "KOR" && "휴대폰번호 형식에 맞게 입력해주세요.") ||
          (language === "ENG" &&
            "Enter the phone number according to the format."),
      );
      return;
    }

    try {
      const { data } = await requestAuthCode({
        variables: {
          type: inputs.signUpMode,
          countryCode: inputs.nation,
          cellphone: inputs.phoneNumber,
          authPage: false,
          email: inputs.mail,
        },
      });
      if (data?.requestAuthCode?.error === "1") {
        toast.error(
          (language === "KOR" && "가입되어있는 번호입니다.") ||
            (language === "ENG" && "This is a registered number."),
        );
        return;
      }
      if (data?.requestAuthCode?.error === "3") {
        toast.error(
          (language === "KOR" && "문자 전송에 실패하였습니다.") ||
            (language === "ENG" && "Message transfer failed."),
        );
        return;
      }
      if (data?.requestAuthCode?.error === "4") {
        toast.error(
          (language === "KOR" &&
            "정지된 사용자입니다. 관리자에게 문의해주세요.") ||
            (language === "ENG" &&
              "You are a stopped user. Please contact manager."),
        );
        return;
      }
      if (data?.requestAuthCode?.result) {
        toast.success(
          (language === "KOR" && "인증번호가 전송되었습니다.") ||
            (language === "ENG" && "The authentication number has been sent."),
        );
        // setInputs({ ...inputs, sendcode: true });
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  const confirmAuth = useCallback(async () => {
    if (inputs.authcode.length !== 6) {
      toast.error(
        (language === "KOR" && "인증번호 6자리를 입력해주세요.") ||
          (language === "ENG" &&
            "Please enter 6 digits of the authentication number."),
      );
      return;
    }
    try {
      const { data } = await confirmAuthCode({
        variables: {
          type: inputs.signUpMode,
          countryCode: inputs.nation,
          cellphone: inputs.phoneNumber,
          authcode: inputs.authcode,
          email: inputs.mail,
          authPage: false,
        },
      });
      if (data?.confirmAuthCode?.error === "1") {
        toast.error(
          (language === "KOR" && "인증번호 전송 후 시도해주세요.") ||
            (language === "ENG" &&
              "Please try after sending the authentication number."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "2") {
        toast.error(
          (language === "KOR" && "회원가입이 안된 번호입니다.") ||
            (language === "ENG" &&
              "This number is not registered as a member."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "5") {
        toast.error(
          (language === "KOR" && "탈퇴한 회원입니다.") ||
            (language === "ENG" && "Withdrawn member."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "4") {
        toast.error(
          (language === "KOR" && "인증번호가 틀렸습니다.") ||
            (language === "ENG" && "The authentication number is incorrect."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "6") {
        toast.error(
          (language === "KOR" && "PM본사 미승인 사용자입니다.") ||
            (language === "ENG" && "PM head office not approved user."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "7") {
        toast.error(
          (language === "KOR" && "정지된 사용자입니다.") ||
            (language === "ENG" && "You are a stopped user."),
        );
        return;
      }
      if (data?.confirmAuthCode?.result) {
        toast.success(
          (language === "KOR" && "휴대폰번호 인증을 완료하었습니다.") ||
            (language === "ENG" &&
              "Phone number verification has been completed."),
        );
        setInputs({ ...inputs, sendcode: true });
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  const handleSignup = useCallback(async () => {
    if (inputs.name.trim() === "") {
      toast.error(
        (language === "KOR" && "성함을 입력해주세요.") ||
          (language === "ENG" && "Please enter name."),
      );
      return;
    }
    if (!inputs.mail.includes("@")) {
      toast.error(
        (language === "KOR" && "이메일 형식이 맞지 않습니다.") ||
          (language === "ENG" && "The email format is not correct."),
      );
      return;
    }
    if (inputs.company.trim() === "") {
      toast.error(
        (language === "KOR" && "회사명을 입력해주세요.") ||
          (language === "ENG" && "Please enter Company"),
      );
      return;
    }
    if (inputs.team === "선택") {
      toast.error(
        (language === "KOR" && "소속을 선택해주세요.") ||
          (language === "ENG" && "Please select Role"),
      );
      return;
    }
    if (inputs.companyCode.trim() === "") {
      toast.error(
        (language === "KOR" && "소속회사 코드를 입력해주세요.") ||
          (language === "ENG" && "Please enter Affiliation code"),
      );
      return;
    }
    if (!inputs.agree) {
      toast.error(
        (language === "KOR" && "약관에 동의해주세요.") ||
          (language === "ENG" && "Please accept the terms and conditions."),
      );
      return;
    }
    try {
      let variables;
      if (inputs.profileImage === "") {
        variables = {
          userName: inputs.name,
          userRank: inputs.rank,
          userCountryCode: inputs.nation,
          userCellphone: inputs.phoneNumber,
          userEmail: inputs.mail,
          profileImagSelect: false,
          userGroup: groupChanger(inputs.team, language),
          userGroupCompany: inputs.company,
          groupAuthCode: inputs.companyCode,
        };
      } else {
        variables = {
          userName: inputs.name,
          userRank: inputs.rank,
          userCountryCode: inputs.nation,
          userCellphone: inputs.phoneNumber,
          userEmail: inputs.mail,
          profileImagSelect: true,
          userProfileImg: inputs.profileImage,
          userGroup: groupChanger(inputs.team, language),
          userGroupCompany: inputs.company,
          groupAuthCode: inputs.companyCode,
        };
      }
      const { data } = await userSignup({
        variables: variables,
      });

      if (data?.userSignup?.result) {
        toast.success("회원가입을 완료하였습니다.");
        goToLogin();
      }
      if (data?.userSignup?.error !== "") {
        toast.error(data.userSignup.error);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  const onChangeFile = (e) => {
    e.preventDefault();
    const files = e.target.files[0];

    if (
      !["jpeg", "jpg", "png"].includes(
        String(files.name).split(".").reverse()[0],
      )
    ) {
      toast.error(
        (language === "KOR" && "이미지만 업로드 가능합니다.") ||
          (language === "ENG" && "Please select only image"),
      );
      return;
    }

    if (files.size / 1024 / 1024 > 10) {
      toast.error(
        (language === "KOR" && "이미지 파일은 10MB 이하로 업로드해주세요.") ||
          (language === "ENG" && "Please upload the image file under 10MB."),
      );
      return;
    }

    if (files.length < 1) return;

    setInputs({ ...inputs, profileImage: files }); // 뮤테이션 보낼 데이터

    //썸네일
    setProfileImage([URL.createObjectURL(files)]);
  };

  return (
    <SignupPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleInput={handleInput}
      handleSelect={handleSelect}
      sendAuthCode={sendAuthCode}
      confirmAuth={confirmAuth}
      handleSignup={handleSignup}
      onChangeFile={onChangeFile}
      profileImage={profileImage}
      goToLogin={goToLogin}
      language={language}
      setLanguage={setLanguage}
      handleSignUpMode={handleSignUpMode}
    />
  );
};

export default SignupContainer;
