import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {colors} from "../../styles/colors";

const Wrapper = styled.div`
  width: ${({width}) => width};
  margin: ${({margin}) => margin};
`;
const LabelText = styled.div`
  font-size: 14px;
  color: ${colors.grayFontColor};
  margin-bottom: 14px;
`;
const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${colors.styledInputBorder};
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  font-size: 18px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: ${({placeholderFontSize}) => placeholderFontSize}px;
    color: ${colors.grayFontColor};
  }
`;

const StyledTextArea = ({
                            margin,
                            label,
                            commentModal,
                            value,
                            onChange,
                            maxLength = 500,
                            placeholder = '의견을 입력해주세요',
                            disabled
                        }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (commentModal) {
            textareaRef.current.focus();
        }
    }, [commentModal]);

    return (
        <Wrapper margin={margin}>
            {label && (
                <LabelText>{label}</LabelText>
            )}
            <InputBox>
                <Textarea
                    autoFocus
                    ref={textareaRef}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={disabled}
                />
            </InputBox>
        </Wrapper>
    )
}

export default React.memo(StyledTextArea);