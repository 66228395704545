import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { groupChanger } from "utils/common";
import { AppContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 661px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  display: flex;
  align-items: center;
`;

const ProfileRowBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  & > ${Text} + ${Text} {
    margin: 0 5px;
  }
`;

const ProfileImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-size: ${({ src }) => (src === "" ? "40%" : "cover")};
  background-repeat: no-repeat;
  border: ${({ src }) => src === "" && `1px solid ${colors.lightgrayBorder}`};
  background-position: 50%;
  margin-right: 10px;
`;

const CirculatorsScroll = styled.div`
  height: 80px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.scrollColor};
  }
`;

const MemberModal = ({ data, handleModal }) => {
  const { language } = useContext(AppContext);
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text BOLD>결재라인</Text>
        <FiX className="closeIcon" onClick={() => handleModal()} />

        <Text margin="40px 0 20px 20px" color={colors.grayFontColor}>
          발행자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.create_img} />
          <Text>{groupChanger(data?.create_group, language)}</Text>
          <Text BOLD>{data?.create_name}</Text>
          <Text BOLD>{data?.create_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={colors.grayFontColor}>
          발행확인자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.check_img} />
          <Text>{groupChanger(data?.check_group, language)}</Text>
          <Text BOLD>{data?.check_name}</Text>
          <Text BOLD>{data?.check_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={colors.grayFontColor}>
          조치자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.action_img} />
          <Text>{groupChanger(data?.action_group, language)}</Text>
          <Text BOLD>{data?.action_name}</Text>
          <Text BOLD>{data?.action_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={colors.grayFontColor}>
          책임자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.manager_img} />
          <Text>{groupChanger(data?.manager_group, language)}</Text>
          <Text BOLD>{data?.manager_name}</Text>
          <Text BOLD>{data?.manager_rank}</Text>
        </ProfileRowBox>

        <Text margin="20px 0 20px  20px" color={colors.grayFontColor}>
          조치확인자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data?.actionCheck_img} />
          <Text>{groupChanger(data?.actionCheck_group, language)}</Text>
          <Text BOLD>{data?.actionCheck_name}</Text>
          <Text BOLD>{data?.actionCheck_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={colors.grayFontColor}>
          최종승인자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.finalSign_img} />
          <Text>{groupChanger(data?.finalSign_group, language)}</Text>
          <Text BOLD>{data?.finalSign_name}</Text>
          <Text BOLD>{data?.finalSign_rank}</Text>
        </ProfileRowBox>

        <Text margin="25px 0 10px 20px" color={colors.grayFontColor}>
          회람자
        </Text>
        {data?.circulators?.length === 0 && (
          <Text margin="0 0 10px 20px">-</Text>
        )}
        <CirculatorsScroll>
          {data?.circulators?.map((circulator, index) => (
            <Text key={index} margin="0 0 10px 20px">
              {circulator?.circ_email + circulator?.circ_cellphone}{" "}
            </Text>
          ))}
        </CirculatorsScroll>
      </ModalContainer>
    </Container>
  );
};

export default MemberModal;
