import React, { useCallback, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import StyledSelect from "components/share/StyledSelect";
import memberDelIcon from "assets/icon/memberDelIcon.svg";
import emptyProfile from "assets/icon/emptyProfile.svg";
import StyledInput from "components/share/StyledInput";
import { nationList } from "components/feature/Login/nationNumber";
import { colors } from "styles/colors";
import { useMutation } from "@apollo/client";
import { ADD_PROJECT_MEMBER } from "graphql/Main/ProjectDetail/ProjectDetail/mutation";
import { toast } from "react-toastify";
import { groupChanger, onlyNumber, removeHyphen } from "utils/common";
import { AppContext } from "App";

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 20px;
  }
`;

const MemberBox = styled.div`
  height: 80px;
  display: grid;
  grid-template-columns: 48px 1fr 80px;
  row-gap: 8px;
  column-gap: 20px;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.styledInputBorder};
`;

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  grid-row: 1 / 3;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-repeat: no-repeat;
  background-size: ${({ src }) => (src === "" ? `40%` : `cover`)};
  background-position: 50%;
  background-color: ${colors.lightgrayBackgroundColor};
`;

const NameText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ color }) => color};
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GrayFont = styled.div`
  color: ${colors.grayFontColor};
  margin-left: ${({ RIGHT }) => RIGHT && "auto"};
  white-space: nowrap;
`;

const Icon = styled.img`
  margin-left: auto;
`;

const Button = styled.button`
  height: 26px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid ${colors.grayFontColor};
  ${({ select }) =>
    select &&
    css`
      background-color: ${colors.mainColor};
      color: ${colors.whiteColor};
      border: 1px solid ${colors.mainColor};
    `}
`;

const ButtonWrapper = styled.div`
  width: 220px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ScrollWrapper = styled.div`
  width: 394px;
  overflow: auto;

  ${({ scroll }) =>
    scroll &&
    css`
      width: 410px;
      padding-right: 7px;
    `}
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.scrollColor};
    border-radius: 10px;
  }
`;

const MemberSelection = ({ inputs, setInputs }) => {
  const { language } = useContext(AppContext);
  const [type, setType] = useState("phoneNumber");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [memberList, setMemberList] = useState([]);

  const [country, setCountry] = useState("+82");
  const [group, setGroup] = useState(
    (language === "KOR" && "PM현장") || (language === "ENG" && "PM"),
  );

  const [addProjectMember] = useMutation(ADD_PROJECT_MEMBER);

  const handleDelMember = useCallback(
    (idx) => {
      inputs.member.splice(idx, 1);
      memberList.splice(idx, 1);

      setInputs({
        ...inputs,
        member: inputs.member,
      });
      toast.success(
        (language === "KOR" && "구성원을 삭제하였습니다.") ||
          (language === "ENG" && "Member deleted successfully."),
      );
    },
    [inputs],
  );

  const includesMemberChecker = useCallback(() => {
    return inputs.member.find((member) => {
      if (type === "phoneNumber") {
        // 휴대폰번호
        return member.user_cellphone === removeHyphen(phoneNumber);
      } else {
        return member.user_email === email;
      }
    });
  }, [type, phoneNumber, email]);

  const handleAddMember = useCallback(async () => {
    if (group === "선택" || group === "Select") {
      toast.error(
        (language === "KOR" && "소속을 선택해주세요.") ||
          (language === "ENG" && "Please choose your team."),
      );
      return;
    }
    if (phoneNumber.trim() === "" && email.trim() === "") {
      toast.error(
        (language === "KOR" && "휴대폰번호 또는 이메일을 입력해주세요.") ||
          (language === "ENG" && "Please enter your phone number or email."),
      );
      return;
    }
    if (type === "email" && (!email.includes("@") || !email.includes("."))) {
      toast.error(
        (language === "KOR" && "이메일 형식이 맞지 않습니다.") ||
          (language === "ENG" && "The email format is not correct."),
      );
      return;
    }

    if (!!includesMemberChecker()) {
      toast.error(
        (language === "KOR" && "이미 추가하신 구성원입니다.") ||
          (language === "ENG" && "Member you have already added"),
      );
      return;
    }

    try {
      const { data } = await addProjectMember({
        variables: {
          group: group,
          emailPhone: type === "phoneNumber",
          countryCode: country,
          term: type === "phoneNumber" ? removeHyphen(phoneNumber) : email,
        },
      });
      if (data?.addProjectMember?.result) {
        toast.success(
          (language === "KOR" && "구성원이 추가되었습니다.") ||
            (language === "ENG" && "Members added successfully."),
        );
        setInputs({
          ...inputs,
          member: [
            ...inputs.member,
            {
              user_id: data.addProjectMember.inviMemberInfo.user_id,
              user_name: data.addProjectMember.inviMemberInfo.user_name,
              user_groupCompany:
                data.addProjectMember.inviMemberInfo.user_groupCompany,
              user_group: data.addProjectMember.inviMemberInfo.user_group,
              user_profileImg:
                data.addProjectMember.inviMemberInfo.user_profileImg,
              user_countryCode:
                data.addProjectMember.inviMemberInfo.user_countryCode,
              user_cellphone:
                data.addProjectMember.inviMemberInfo.user_cellphone,
              user_email: data.addProjectMember.inviMemberInfo.user_email,
            },
          ],
        });
      }
      // console.log(data);
      if (data?.addProjectMember?.error !== "") {
        toast.error(data?.addProjectMember?.error);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setGroup(
        (language === "KOR" && "선택") || (language === "ENG" && "Select"),
      );
      setPhoneNumber("");
      setEmail("");
    }
  }, [memberList, type, phoneNumber, email, country, group, language]);

  return (
    <>
      <RowBox>
        <StyledSelect
          label={
            (language === "KOR" && "소속") || (language === "ENG" && "Role")
          }
          labelFontSize={14}
          width={150}
          height={59}
          fontSize={18}
          options={
            (language === "KOR" && ["PM현장", "시공사", "발주처"]) ||
            (language === "ENG" && ["PM", "Contractor", "Client"])
          }
          value={group}
          placeholder={
            (language === "KOR" && "소속 선택") ||
            (language === "ENG" && "Choice of affiliation")
          }
          onChange={(_, value) => {
            setGroup(value);
          }}
        />

        <ButtonWrapper>
          <Button select={type === "email"} onClick={() => setType("email")}>
            {language === "KOR" && "이메일"}
            {language === "ENG" && "email"}
          </Button>
          <Button
            select={type === "phoneNumber"}
            onClick={() => setType("phoneNumber")}
          >
            {language === "KOR" && "휴대폰번호"}
            {language === "ENG" && "phone number"}
          </Button>
        </ButtonWrapper>
      </RowBox>
      <RowBox>
        {type === "email" ? (
          <StyledInput
            label={
              (language === "KOR" && "연락처 또는 메일로 추가하기*") ||
              (language === "ENG" && "Invite with phone number or email*")
            }
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            PLUSBTN
            width={395}
            handlePlusBtn={handleAddMember}
          />
        ) : (
          <>
            <StyledSelect
              label={
                (language === "KOR" && "국가") ||
                (language === "ENG" && "Country code")
              }
              labelFontSize={14}
              width={150}
              height={54}
              fontSize={18}
              options={nationList}
              value={country}
              onChange={(_, value) => setCountry(value)}
            />
            <StyledInput
              label={
                (language === "KOR" && "연락처 또는 메일로 추가하기*") ||
                (language === "ENG" && "Invite with phone number or email*")
              }
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              PLUSBTN
              width={220}
              handlePlusBtn={handleAddMember}
            />
          </>
        )}
      </RowBox>
      <ScrollWrapper scroll={inputs.member.length > 6}>
        {inputs.member.map((user, index) => (
          <MemberBox key={index}>
            <ProfileImage src={user.user_profileImg} />
            <NameText>{user.user_name}님</NameText>
            <GrayFont RIGHT>
              {user.user_id === 0 ? (
                <>
                  {language === "KOR" && "미가입자"}
                  {language === "ENG" && "unsign user"}
                </>
              ) : (
                <>
                  {language === "KOR" && "가입자"}
                  {language === "ENG" && "user"}
                </>
              )}
            </GrayFont>
            <GrayFont>
              {user.user_groupCompany} (
              {user.user_id === 0
                ? user.user_group
                : groupChanger(user.user_group, language)}
              )
            </GrayFont>
            <Icon src={memberDelIcon} onClick={() => handleDelMember(index)} />
          </MemberBox>
        ))}
      </ScrollWrapper>
    </>
  );
};

export default MemberSelection;
