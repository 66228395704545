import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { FiChevronRight } from "react-icons/fi";
import StyledInput from "../../components/share/StyledInput";
import StyledButton from "../../components/share/StyledButton";
import importantIcon from "assets/icon/importantIcon.svg";
import { dateFormat, groupChanger } from "utils/common";
import imgPlusIcon from "assets/icon/imgPlusIcon.svg";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { ProfileContext } from "../../App";
import WithdrawalModal from "./WithdrawalModal";

const Container = styled.div`
  width: 957px;
  /* min-height: calc(1080px - 82px); */
  min-height: calc(100vh - 154px - 81px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: flex-start;
  position: relative;
  padding: 50px 0 158px 0;
  margin: 0 auto;
`;

const EditInfoRowBox = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  margin-top: 26px;
`;

const EditInfoColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 26px;
`;

const MyInfoSummaryBox = styled.div`
  height: 159px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  background-color: ${colors.whiteColor};
  border-radius: 8px;
`;

const Text = styled.div`
  white-space: nowrap;
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
`;

const InfoSummaryTextWrapper = styled.div`
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > ${Text} {
    font-size: 18px;
  }
  &.key > ${Text} {
    color: ${colors.darkGrayFontColor};
    margin-right: 20px;
  }
  &.value > ${Text} {
    margin-right: 20px;
    display: flex;
    align-items: center;
    > ${Text} {
      font-size: 14px;
      font-weight: 500;
      color: ${colors.mainColor};
      margin-left: 30px;
    }
  }
`;

const TermsWrapper = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 95px;
  right: calc(-50px - 380px);
  border-radius: 8px;
  min-height: 225px;
`;

const Terms = styled.div`
  width: 380px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin: 20px 0 0 0;
  border-radius: 8px;
  background-color: ${colors.whiteColor};

  cursor: pointer;
  & > ${Text} {
    &:nth-child(2) {
      font-size: 14px;
      color: ${colors.grayFontColor};
      margin: 0 14px 0 auto;
    }
  }
  & > .icon {
    color: ${colors.grayFontColor};
  }
`;

const Icon = styled.img``;

const InfoEditBox = styled.div`
  height: 368px;
  position: relative;
  padding: 26px 30px;
  background-color: ${colors.whiteColor};
  border-radius: 8px;
  margin: 16px 0 30px 0;
`;

const RowBox = styled.div`
  display: flex;
`;

const SendButton = styled.button`
  width: 56px;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid ${colors.mainColor};
  color: ${colors.mainColor};
  margin: auto 0 10px 0;
`;

const ProfileImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: ${({ src }) => (src === "" ? `40%` : `cover`)};
  border-radius: 50%;
  /* background-position: 0 50%; */
`;

const ProfileImageThumbnail = styled.label`
  width: 107px;
  height: 107px;
  margin: 0 30px;
  border-radius: 50%;
  position: relative;
  /* overflow: hidden; */
  cursor: pointer;
`;

const ProfileEditButton = styled.img`
  position: absolute;
  right: 0;
  bottom: 10px;
`;

const ImageInput = styled.input`
  display: none;
`;

const ProfilePresenter = ({
  inputs,
  handleInput,
  profileImage,
  myProfile,
  editProfile,
  onChangeProfileImg,
  sendEmail,
  EditEmail,
  language,
}) => {
  const { withdrawalModal, handleOpenWithdrawal } = useContext(ProfileContext);

  return (
    <Container>
      <TitleText>
        {language === "KOR" && "내 프로필 정보"}
        {language === "ENG" && "My Profile"}
      </TitleText>
      <Text>
        {language === "KOR" && "내정보"}
        {language === "ENG" && "My Information"}
      </Text>
      <MyInfoSummaryBox>
        <ProfileImageThumbnail>
          <ProfileImage src={profileImage} />
          <ImageInput
            type="file"
            accept=".jpeg, .jpg, .png"
            onChange={onChangeProfileImg}
          />
          <ProfileEditButton src={imgPlusIcon} />
        </ProfileImageThumbnail>
        <InfoSummaryTextWrapper className="key">
          <Text>
            {language === "KOR" && "이름"}
            {language === "ENG" && "Name"}
          </Text>
          <Text>
            {language === "KOR" && "소속/소속명"}
            {language === "ENG" && "Role/Company"}
          </Text>
          <Text>
            {language === "KOR" && "메일주소"} {language === "ENG" && "Email"}
          </Text>
        </InfoSummaryTextWrapper>
        <InfoSummaryTextWrapper className="value">
          <Text>
            {myProfile?.user_name} {myProfile?.user_rank}
          </Text>
          <Text>
            {groupChanger(myProfile?.user_group, language)}{" "}
            {myProfile?.user_groupCompany}
          </Text>
          <Text>
            {myProfile?.user_email}
            <Text>
              {myProfile?.user_emailAuth ? (
                <>
                  {language === "KOR" && "인증완료"}
                  {language === "ENG" && "Certification completed"}
                </>
              ) : (
                <>
                  {language === "KOR" && "인증미완료"}
                  {language === "ENG" && "Certification not completed"}
                </>
              )}
            </Text>
          </Text>
        </InfoSummaryTextWrapper>
      </MyInfoSummaryBox>

      <EditInfoRowBox>
        <EditInfoColumnBox>
          <Text>
            {language === "KOR" && "내 정보 수정하기"}{" "}
            {language === "ENG" && "Modify my info"}
          </Text>
          <InfoEditBox>
            <RowBox>
              <StyledInput
                name="name"
                label={
                  (language === "KOR" && "성함") ||
                  (language === "ENG" && "Name")
                }
                width={168}
                value={inputs.name}
                onChange={handleInput}
                margin="0 auto 0 0"
              />
              <StyledInput
                name="rank"
                label={
                  (language === "KOR" && "직책") ||
                  (language === "ENG" && "Position")
                }
                width={187}
                value={inputs.rank}
                onChange={handleInput}
              />
            </RowBox>
            <StyledInput
              READONLY
              name="team"
              label={
                (language === "KOR" && "소속") || (language === "ENG" && "Role")
              }
              value={inputs.team}
              onChange={handleInput}
              margin=" 14px 0"
            />
            <RowBox>
              <Icon src={importantIcon} />
              <Text margin="0 0 0 6px" color={colors.grayFontColor}>
                {language === "KOR" && "소속 변경시 관리자에게 문의주세요."}
                {language === "ENG" &&
                  "If you change your affiliation, please contact manager."}
              </Text>
            </RowBox>
            <StyledInput
              name="company"
              label={
                (language === "KOR" && "소속회사명") ||
                (language === "ENG" && "Affiliated company name")
              }
              value={inputs.company}
              onChange={handleInput}
              margin="20px 0 0 0"
            />
          </InfoEditBox>
          <StyledButton
            width={454}
            height={48}
            label={
              (language === "KOR" && "정보 변경하기") ||
              (language === "ENG" && "Change information")
            }
            fontSize={18}
            onClick={editProfile}
          />
        </EditInfoColumnBox>
        <EditInfoColumnBox>
          <Text>
            {language === "KOR" && "메일 인증하기"}{" "}
            {language === "ENG" && "Authenticate mail"}
          </Text>
          <InfoEditBox>
            <RowBox>
              <StyledInput
                name="mail"
                label={
                  (language === "KOR" && "메일") ||
                  (language === "ENG" && "Email")
                }
                width={308}
                value={inputs.mail}
                onChange={handleInput}
                margin="0 auto 14px 0"
              />
              <SendButton onClick={() => sendEmail()}>
                {language === "KOR" && "전송"}
                {language === "ENG" && "Send"}
              </SendButton>
            </RowBox>

            <StyledInput
              name="mailCode"
              label={
                (language === "KOR" && "메일로 수신된 6자리 코드 입력") ||
                (language === "ENG" &&
                  "Enter the 6 digit code received by mail")
              }
              value={inputs.mailCode}
              onChange={handleInput}
            />
          </InfoEditBox>
          <StyledButton
            width={454}
            height={48}
            label={
              (language === "KOR" && "인증하기") ||
              (language === "ENG" && "Authenticate email")
            }
            fontSize={18}
            onClick={EditEmail}
          />
        </EditInfoColumnBox>
      </EditInfoRowBox>

      <TermsWrapper>
        <Text>
          {language === "KOR" && "약관"} {language === "ENG" && "Terms"}
        </Text>
        <Terms
          onClick={() => window.open("https://agreementshg.riskzero.ai/policy")}
        >
          <Text>
            {" "}
            {language === "KOR" && "개인정보처리방침"}
            {language === "ENG" && "Personal Information Policy"}
          </Text>
          <Text DATE>
            {dateFormat(
              new Date(Number(myProfile?.user_privacyPolicyAgreeDate)),
              "yyyy.MM.dd",
            )}{" "}
            {language === "KOR" && "동의"}
            {language === "ENG" && "Agree"}
          </Text>
          <FiChevronRight className="icon" />
        </Terms>
        <Terms
          onClick={() =>
            window.open("https://agreementshg.riskzero.ai/service")
          }
        >
          <Text>
            {language === "KOR" && "서비스이용약관"}
            {language === "ENG" && "Terms and conditions"}
          </Text>
          <Text DATE>
            {dateFormat(
              new Date(Number(myProfile?.user_tosAgreeDate)),
              "yyyy.MM.dd",
            )}{" "}
            {language === "KOR" && "동의"}
            {language === "ENG" && "Agree"}
          </Text>
          <FiChevronRight className="icon" />
        </Terms>

        {/*<Text margin="26px 0 0">*/}
        {/*  {language === "KOR" && "기타"} {language === "ENG" && "Etc"}*/}
        {/*</Text>*/}

        {/*<Terms onClick={handleOpenWithdrawal}>*/}
        {/*  <Text>*/}
        {/*    {language === "KOR" && "회원탈퇴"}*/}
        {/*    {language === "ENG" && "Withdrawal"}*/}
        {/*  </Text>*/}
        {/*  <Text DATE></Text>*/}
        {/*  <FiChevronRight className="icon" />*/}
        {/*</Terms>*/}
      </TermsWrapper>
      {withdrawalModal && <WithdrawalModal />}
    </Container>
  );
};

export default ProfilePresenter;
