import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const AuthProvider = ({ isLoggedIn: isLoggedInProps, children }) => {
  const navigate = useNavigate();

  const userLogin = async (token) => {
    try {
      localStorage.setItem("hanmi_scar_token", token);
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (e) {
      console.log(e);
    }
  };

  const userLogout = () => {
    try {
      localStorage.removeItem("hanmi_scar_token");
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (e) {
      console.log(e);
    } finally {
      localStorage.clear();
    }
  };

  return (
    <AuthContext.Provider value={{ userLogin, userLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
