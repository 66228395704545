import React, {useCallback, useContext, useRef, useState} from "react";
import styled from "styled-components";
import {colors} from "styles/colors";
import resetIcon from "assets/icon/resetIcon.svg";
import {AppContext} from "App";
import {StatsContext} from "routes/Main/ProjectDetail/Stats/StatsContainer";
import Date_Range_Picker from "./Date_Range_Picker";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const WhiteBox = styled.div`
  width: 380px;
  height: 207px;
  padding: 26px 30px;
  margin: 135px 0 30px 0;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const TitleText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({color}) => color};
`;

const ResetButton = styled.div`
  font-size: 14px;
  color: ${colors.grayFontColor};
  cursor: pointer;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${({padding}) => padding};
  margin: ${({margin}) => margin};
  border-bottom: ${({BORDER}) =>
          BORDER && `1px solid ${colors.lightgrayBorder}`};
`;

const ResetIcon = styled.img`
  margin: 0 11px 0 auto;
  cursor: pointer;

  &.rotate {
    transition: transform 1s;
    transform: rotate(-360deg);
  }
`;

const StatisticBtn = styled.div`
  width: 153px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: ${({click}) => click && "bold"};
  color: ${({click}) =>
          click ? `${colors.blackColor}` : `${colors.grayFontColor}`};
  border: 1px solid ${({click}) =>
          click ? `${colors.blueBorder}` : `${colors.grayFontColor}`};
  margin: 20px auto auto auto;
  cursor: pointer;
`;

const SearchScar = () => {
    const icon = useRef();
    const {language} = useContext(AppContext);
    const {inputs, setInputs, handleReset, statsType, setStatsType} =
        useContext(StatsContext);
    const [rotateClass, setRotateClass] = useState(false);

    const rotateIcon = (e) => {
        handleReset();
        e.preventDefault();
        setRotateClass(true);
        setTimeout(() => setRotateClass(false), [1000]);
    };

    return (
        <Container>
            <WhiteBox>
                <RowBox>
                    <TitleText>
                        {language === "KOR" && "통계 기간 설정하기"}
                        {language === "ENG" && "Set statistical period"}
                    </TitleText>
                    <ResetIcon
                        src={resetIcon}
                        ref={icon}
                        onClick={rotateIcon}
                        className={rotateClass && "rotate"}
                    />
                    <ResetButton onClick={rotateIcon}>
                        {language === "KOR" && "초기화"}
                        {language === "ENG" && "Reset"}
                    </ResetButton>
                </RowBox>

                <Date_Range_Picker/>
                <RowBox>
                    <StatisticBtn
                        click={statsType === "scar"}
                        onClick={() => setStatsType("scar")}
                    >
                        {language === "KOR" && "SCAR 통계"}
                        {language === "ENG" && "SCAR STATS"}
                    </StatisticBtn>

                    <StatisticBtn
                        click={statsType === "checkList"}
                        onClick={() => setStatsType("checkList")}
                    >
                        {language === "KOR" && "CHECK LIST 통계"}
                        {language === "ENG" && "CHECKLIST STATS"}
                    </StatisticBtn>
                </RowBox>
            </WhiteBox>
        </Container>
    );
};

export default SearchScar;
