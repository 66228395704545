import React from "react";
import styled from "styled-components";
import GuideHeader from "components/layout/GuideHeader";
import { colors } from "styles/colors";
import goBackIcon from "assets/icon/goBackIcon.svg";
import { css } from "styled-components";
import { engMonthChang } from "../../../../lib/ProjectDetailUtils";
import CheckListStartKorButton from "../../../../assets/images/button/btn-checkList-start-kor.png";
import CheckListStartEngButton from "../../../../assets/images/button/btn-checkList-start-eng.png";
import CheckListInProgressKorButton from "../../../../assets/images/button/btn-checkList-inprogress-kor.png";
import CheckListInProgressEngButton from "../../../../assets/images/button/btn-checkList-inprogress-eng.png";
import CheckListCompletedKorButton from "../../../../assets/images/button/btn-checkList-completed-kor.png";
import CheckListCompletedEngButton from "../../../../assets/images/button/btn-checkList-completed-eng.png";

const Container = styled.div`
  min-height: calc(100vh - 154px - 81px - 32px);
  background-color: ${colors.whiteColor};
  position: relative;
`;

const GoBackIconBtn = styled.img`
  position: absolute;
  top: 50px;
  left: 40px;
  cursor: pointer;
`;

const CenterColumn = styled.div`
  width: 957px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 18)}px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  margin: ${({ margin }) => (margin ? margin : "")};
  color: ${({ color }) => color};
  cursor: ${({ POINTER }) => POINTER && "pointer"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
`;

const CheckRowCard = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.grayFontColor};
  ${({ TOP }) =>
    TOP &&
    css`
      border-top: 1px solid ${colors.blackColor};
      border-bottom: 1px solid ${colors.blackColor};
    `}
  cursor: pointer;
`;

const RowBox = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  align-items: ${({ center }) => center && "center"};
  justify-content: ${({ spaceBetween }) => spaceBetween && "space-between"};
  padding: ${({ padding }) => (padding ? padding : "")};
`;

const CheckListPresenter = ({
  language,
  navigate,
  completeCheckList,
  thisWeekCheckList,
  evaluationPermission,
  handleEvaluation,
}) => {
  return (
    <>
      <GuideHeader
        labelList={
          (language === "KOR" && ["프로젝트 목록", "프로젝트 상세"]) ||
          (language === "ENG" && ["Project List", "Project Detail"])
        }
      />
      <Container>
        <GoBackIconBtn
          src={goBackIcon}
          onClick={() => navigate("/projectDetail")}
        />
        <CenterColumn>
          <Text BOLD fontSize={24} margin="50px 0 26px 0">
            {language === "KOR" && "체크리스트 평가하기"}
            {language === "ENG" && "Review Weekly Checklist"}
          </Text>
          <Text BOLD fontSize={16} margin="0 0 16px 0">
            {language === "KOR" && "체크리스트 목록"}
            {language === "ENG" && "Checklist"}
          </Text>
          <CheckRowCard TOP>
            <RowBox spaceBetween center>
              <Text BOLD margin="30px 0">
                {language === "KOR" &&
                  `${thisWeekCheckList?.wcl_year}년 ${thisWeekCheckList?.wcl_month}월 ${thisWeekCheckList?.wcl_week}주차`}
                {language === "ENG" &&
                  `${engMonthChang(thisWeekCheckList?.wcl_month)}. ${
                    thisWeekCheckList?.wcl_week
                  }nd week, ${thisWeekCheckList?.wcl_year}`}
              </Text>
              <Text
                BOLD
                color={
                  evaluationPermission === 0
                    ? colors.blackColor
                    : colors.mainColor
                }
                POINTER
                onClick={() => handleEvaluation(thisWeekCheckList?.wcl_id)}
              >
                {evaluationPermission === 0 ? (
                  <>
                    {language === "KOR" && (
                      <img src={CheckListStartKorButton} alt="평가시작하기" />
                    )}
                    {language === "ENG" && (
                      <img src={CheckListStartEngButton} alt="Create" />
                    )}
                  </>
                ) : (
                  <>
                    {language === "KOR" && (
                      <img
                        src={CheckListInProgressKorButton}
                        alt="평가진행중"
                      />
                    )}
                    {language === "ENG" && (
                      <img
                        src={CheckListInProgressEngButton}
                        alt="In Progress"
                      />
                    )}
                  </>
                )}
              </Text>
            </RowBox>
          </CheckRowCard>
          {completeCheckList?.map((list) => (
            <CheckRowCard
              key={list.wcl_id}
              onClick={() => {
                navigate("/projectDetail/checkDetail");
                localStorage.setItem("wclId", list.wcl_id);
              }}
            >
              <RowBox padding="20px 0" spaceBetween>
                <div>
                  <Text BOLD>
                    {language === "KOR" &&
                      `${list?.wcl_year}년 ${list?.wcl_month}월 ${list?.wcl_week}주차`}
                    {language === "ENG" &&
                      `${engMonthChang(list?.wcl_month)}. ${
                        list?.wcl_week
                      }nd week, ${list?.wcl_year}`}
                  </Text>
                  <div
                    style={{ marginTop: "20px", display: "flex", gap: "40px" }}
                  >
                    <Text>
                      {language === "KOR" && "준수율"}
                      {language === "ENG" && "Rate"}
                      &nbsp;:&nbsp;
                      {list?.wcl_complyRate}%
                    </Text>
                    <Text>
                      {language === "KOR" && "총점"}
                      {language === "ENG" && "Total"}
                      &nbsp;:&nbsp;
                      {list?.wcl_totalScore}
                    </Text>
                    <Text>
                      {language === "KOR" && "평가점수"}
                      {language === "ENG" && "Score"}
                      &nbsp;:&nbsp;
                      {list?.wcl_evaluationScore}
                    </Text>
                    <Text>
                      {language === "KOR" && "반복위반"}
                      {language === "ENG" && "Repeat"}
                      &nbsp;:&nbsp;
                      {list?.wcl_repeatViolationCount}
                    </Text>
                  </div>
                </div>

                <div>
                  {language === "KOR" && (
                    <img src={CheckListCompletedKorButton} alt="평가완료" />
                  )}
                  {language === "ENG" && (
                    <img src={CheckListCompletedEngButton} alt="Completed" />
                  )}
                </div>
              </RowBox>
            </CheckRowCard>
          ))}
        </CenterColumn>
      </Container>
    </>
  );
};

export default CheckListPresenter;
