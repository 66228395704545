import React from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import searchIcon from "assets/icon/searchIcon.svg";
import blueplus from "assets/icon/blueplus.svg";

const Container = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${({ margin }) => margin};
  flex-shrink: 0;
`;

const LabelText = styled.div`
  font-size: 14px;
  color: ${colors.grayFontColor};
  margin-bottom: 14px;
`;

const Input = styled.input`
  width: 100%;
  font-size: 18px;
  padding-bottom: 14px;
  border-bottom: 1px solid ${colors.styledInputBorder};
  padding-left: ${({ SEARCHICON }) => SEARCHICON && 30}px;
  color: ${({ READONLY }) => READONLY && `${colors.grayFontColor}`};
  ${({ PLUSBTN }) =>
    PLUSBTN &&
    css`
      padding-right: 50px;
    `}
  ::placeholder {
    color: ${colors.grayFontColor};
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  left: 0;
  bottom: 17.5px;
`;

const PlusButton = styled.div`
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 8px;
  right: 0;
  border-radius: 6px;
  border: 1px solid ${colors.styledInputBorder};
  background-image: url(${blueplus});
  background-repeat: no-repeat;
  background-position: 50%;

  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.lightGrayHover};
  }
`;

const StyledInput = ({
  READONLY,
  name,
  label,
  margin,
  width,
  value,
  onChange,
  maxLength,
  SEARCHICON,
  placeholder = "",
  PLUSBTN,
  handlePlusBtn,
}) => {
  return (
    <Container margin={margin} width={width}>
      <LabelText>{label}</LabelText>
      <Input
        READONLY={READONLY}
        readOnly={READONLY}
        name={name}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        SEARCHICON={SEARCHICON}
        PLUSBTN={PLUSBTN}
      />
      {SEARCHICON && <SearchIcon src={searchIcon} />}
      {PLUSBTN && <PlusButton onClick={handlePlusBtn} />}
    </Container>
  );
};

export default StyledInput;
