import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import StyledButton from "components/share/StyledButton";
import { FiX } from "react-icons/fi";
import StyledInput from "components/share/StyledInput";
import checkIcon from "assets/icon/checkIcon.svg";
import { ProjectDetailContext } from "routes/Main/ProjectDetail/ProjectDetail/ProjectDetailContainer";
import InputForm from "components/feature/Main/CreateProject/InputForm";
import StyledFileInput from "components/share/StyledFileInput";
import DateRangePicker from "../../CreateProject/DateRangePicker";
import { EDIT_PROJECT } from "graphql/Main/ProjectDetail/ProjectDetail/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AppContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 574px;
  height: 876px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: bold;
  color: ${({ color }) => color};
`;

const ButtonWrapper = styled.div`
  width: 168px;
  display: flex;
  justify-content: space-between;
  margin: auto 0 0 auto;
`;

const RowBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`;

const CheckButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url(${checkIcon});
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: ${({ color }) => color};
  margin-right: 6px;
`;

const ProjectEditModal = () => {
  const { language } = useContext(AppContext);
  const { handlePrjEditModal, project, prjDetailRefetch } =
    useContext(ProjectDetailContext);
  const [imgThumbnail, setImgThumbnail] = useState([]);
  const [inputs, setInputs] = useState({
    title: "",
    address: "",
    addressDetail: "",
    startDate: "",
    endDate: "",
    number: "",
    open: false,
    image: "",
  });

  const [editProject] = useMutation(EDIT_PROJECT);

  const handleInput = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs],
  );

  const onChangeFile = useCallback(
    (e) => {
      e.preventDefault();
      const files = e.target.files[0];

      if (
        !["jpeg", "jpg", "png"].includes(
          String(files.name).split(".").reverse()[0],
        )
      ) {
        toast.error(
          (language === "KOR" && "이미지만 업로드 가능합니다.") ||
            (language === "ENG" && "Please select only image"),
        );
        return;
      }

      if (files.size / 1024 / 1024 > 10) {
        toast.error(
          (language === "KOR" && "이미지 파일은 10MB 이하로 업로드해주세요.") ||
            (language === "ENG" && "Please upload the image file under 10MB."),
        );
        return;
      }

      if (files.length < 1) return;

      setInputs({ ...inputs, image: files }); // 뮤테이션 보낼 데이터

      //썸네일
      setImgThumbnail([URL.createObjectURL(files)]);
    },
    [inputs, imgThumbnail],
  );

  const handleImageDelBtn = useCallback(() => {
    setInputs({ ...inputs, image: "" });
    setImgThumbnail([]);
  }, [inputs, imgThumbnail]);

  const handleSave = useCallback(async () => {
    if (inputs.title.trim() === "") {
      toast.error(
        (language === "KOR" && "프로젝트명을 입력해주세요.") ||
          (language === "ENG" && "Please enter the project name"),
      );
      return;
    }
    if (inputs.number.trim() === "") {
      toast.error(
        (language === "KOR" && "문서번호를 입력해주세요.") ||
          (language === "ENG" && "Please enter the "),
      );
      return;
    }
    try {
      let variables;
      if (typeof inputs.image === "string") {
        variables = {
          prjTitle: inputs.title,
          prjAddress: inputs.address + " " + inputs.addressDetail,
          prjStartDate: inputs.startDate,
          prjEndDate: inputs.endDate,
          prjNumber: inputs.number,
          prjOpenAvailable: inputs.open,
          prjId: project?.prj_id,
          prjImgSame: true,
        };
      } else {
        variables = {
          prjTitle: inputs.title,
          prjAddress: inputs.address + " " + inputs.addressDetail,
          prjStartDate: inputs.startDate,
          prjEndDate: inputs.endDate,
          prjNumber: inputs.number,
          prjOpenAvailable: inputs.open,
          prjId: project?.prj_id,
          prjImgSame: false,
          prjImg: inputs.image,
        };
      }
      const { data } = await editProject({ variables });
      if (data?.editProject?.result) {
        await prjDetailRefetch();
        toast.success(
          (language === "KOR" && "프로젝트를 수정하였습니다.") ||
            (language === "ENG" && "Modified the project."),
        );
        handlePrjEditModal();
      }
      if (data?.editProject?.error) {
        toast.error(data?.editProject?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  useEffect(() => {
    // console.log();
    setInputs({
      title: project?.prj_title,
      address: project?.prj_address,
      addressDetail: "",
      startDate: new Date(Number(project?.prj_startDate)),
      endDate: new Date(Number(project?.prj_endDate)),
      number: project?.prj_number,
      open: project?.prj_openAvailable,
      image: project?.prj_img,
    });
    setImgThumbnail([project?.prj_img]);
  }, []);

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text>
          {language === "KOR" && "프로젝트 수정하기"}
          {language === "ENG" && "Edit Project Information"}
        </Text>
        <FiX className="closeIcon" onClick={() => handlePrjEditModal()} />
        <StyledInput
          name="title"
          label={`${
            (language === "KOR" && "프로젝트명") ||
            (language === "ENG" && "Project Name")
          } ( ${inputs.title.length} / 50 )`}
          placeholder={
            (language === "KOR" && "프로젝트명 입력") ||
            (language === "ENG" && "Enter project name")
          }
          value={inputs.title}
          onChange={handleInput}
          margin="40px  0"
          maxLength={79}
        />
        <InputForm
          name="addressDetail"
          ADDRESS
          INPUT
          inputs={inputs}
          setInputs={setInputs}
          label={
            (language === "KOR" && "현장주소") ||
            (language === "ENG" && "Site Address")
          }
          placeholder={
            (language === "KOR" && "상세주소 입력") ||
            (language === "ENG" && "Enter site address")
          }
          value={inputs.addressDetail}
          onChange={handleInput}
          margin="0 0 30px 0"
        />
        <DateRangePicker inputs={inputs} setInputs={setInputs} />
        <StyledInput
          name="number"
          label={`${
            (language === "KOR" && "문서번호") ||
            (language === "ENG" && "Document Numbere*")
          }`}
          placeholder={"HG-SCAR"}
          value={inputs.number}
          onChange={handleInput}
          margin="40px  0"
          maxLength={79}
        />
        <StyledFileInput
          name="image"
          image={imgThumbnail}
          onChange={onChangeFile}
          delClick={handleImageDelBtn}
          margin="30px 0 auto 0"
          label={
            (language === "KOR" && "프로젝트 메인 사진") ||
            (language === "ENG" && "Project Main Image")
          }
          guideLabel={
            (language === "KOR" && "1장의 메인 사진을 업로드해주세요.") ||
            (language === "ENG" && "Please upload 1 main image.")
          }
        />
        <RowBox
          SAVE
          onClick={() => setInputs({ ...inputs, open: !inputs.open })}
        >
          <CheckButton
            color={!inputs.open ? colors.mainColor : colors.scrollColor}
          />
          <Text color={!inputs.open ? colors.mainColor : colors.grayFontColor}>
            {language === "KOR" && "비공개"}
            {language === "ENG" && "Nondisclosure"}
          </Text>
        </RowBox>
        <ButtonWrapper>
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            BORDER
            borderColor={colors.grayFontColor}
            labelColor={colors.grayFontColor}
            label={
              (language === "KOR" && "취소") || (language === "ENG" && "Cancel")
            }
            onClick={() => handlePrjEditModal()}
          />
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            label={
              (language === "KOR" && "수정") || (language === "ENG" && "Edit")
            }
            margin="0 0 0 20px"
            onClick={() => handleSave()}
          />
        </ButtonWrapper>
      </ModalContainer>
    </Container>
  );
};

export default ProjectEditModal;
