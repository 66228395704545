import { gql } from "@apollo/client";

export const REQUEST_AUTH_CODE = gql`
  mutation requestAuthCode(
    $type: String
    $countryCode: String
    $cellphone: String
    $authPage: Boolean
    $email: String
  ) {
    requestAuthCode(
      type: $type
      countryCode: $countryCode
      cellphone: $cellphone
      authPage: $authPage
      email: $email
    ) {
      result
      error
    }
  }
`;

export const CONFIRM_AUTH_CODE = gql`
  mutation confirmAuthCode(
    $countryCode: String
    $cellphone: String
    $email: String
    $authcode: String
    $authPage: Boolean
  ) {
    confirmAuthCode(
      countryCode: $countryCode
      cellphone: $cellphone
      email: $email
      authcode: $authcode
      authPage: $authPage
    ) {
      result
      error
      token
    }
  }
`;

export const USER_SIGNUP = gql`
  mutation userSignup(
    $userName: String
    $userRank: String
    $userCountryCode: String
    $userCellphone: String
    $userEmail: String
    $profileImagSelect: Boolean
    $userProfileImg: Upload
    $userGroup: projectPermission
    $userGroupCompany: String
    $groupAuthCode: String
  ) {
    userSignup(
      user_name: $userName
      user_rank: $userRank
      user_countryCode: $userCountryCode
      user_cellphone: $userCellphone
      user_email: $userEmail
      profileImagSelect: $profileImagSelect
      user_profileImg: $userProfileImg
      user_group: $userGroup
      user_groupCompany: $userGroupCompany
      groupAuthCode: $groupAuthCode
    ) {
      result
      error
    }
  }
`;
