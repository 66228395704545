import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiChevronRight } from "react-icons/fi";

const Contaienr = styled.div`
  width: 100%;
  min-width: 1920px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  border-top: 1px solid ${colors.lightgrayBorder};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grayFontColor};
  & > .icon {
    margin-right: 0 12px;
  }
`;

const GuideHeader = ({ labelList }) => {
  return (
    <Contaienr>
      {labelList.map((label, index) => (
        <Text key={index}>
          {label}
          {labelList.length !== index + 1 && (
            <FiChevronRight className="icon" />
          )}
        </Text>
      ))}
    </Contaienr>
  );
};

export default GuideHeader;
