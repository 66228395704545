import React, { useState, useCallback, createContext, useContext } from "react";
import LoginPresenter from "./LoginPresenter";
import { useMutation } from "@apollo/client";
import { REQUEST_AUTH_CODE, CONFIRM_AUTH_CODE } from "graphql/Login/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { AppContext } from "App";
import {
  getCountryNumberToCode,
  validateEmail,
  validatePhoneNumber,
} from "utils/common";

export const LoginContext = createContext();

const LoginContainer = () => {
  const { language, setLanguage } = useContext(AppContext);
  const { userLogin } = useContext(AuthContext);
  const [loginMode, setLoginMode] = useState("PHONE");

  const [inputs, setInputs] = useState({
    nation: "+82",
    phoneNumber: "",
    authcode: "",
    sendcode: true,
    auth: true,
    email: "",
  });

  const navigate = useNavigate();

  const [requestAuthCode] = useMutation(REQUEST_AUTH_CODE);
  const [confirmAuthCode] = useMutation(CONFIRM_AUTH_CODE);

  const handleLoginMode = useCallback(
    (loginMode) => setLoginMode(loginMode),
    [],
  );

  const handleInput = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs],
  );

  const handleSelect = useCallback(
    (name, value) => {
      setInputs({ ...inputs, [name]: value });
    },
    [inputs],
  );

  const goToSignup = useCallback(() => {
    navigate("/signup");
  }, []);

  const sendAuthCode = useCallback(
    async (loginPage) => {
      if (loginMode === "PHONE" && inputs.phoneNumber.trim() === "") {
        toast.error(
          (language === "KOR" && "휴대폰번호를 입력해주세요.") ||
            (language === "ENG" && "Enter the phone number"),
        );
        return;
      }
      if (loginMode === "EMAIL" && inputs.email.trim() === "") {
        toast.error(
          (language === "KOR" && "이메일을 입력해주세요.") ||
            (language === "ENG" && "Enter the e-mail"),
        );
        return;
      }
      if (
        loginMode === "PHONE" &&
        !validatePhoneNumber(
          inputs.phoneNumber.trim(),
          getCountryNumberToCode(inputs.nation),
        )
      ) {
        toast.error(
          (language === "KOR" && "휴대폰번호 형식에 맞게 입력해주세요.") ||
            (language === "ENG" &&
              "Enter the phone number according to the format."),
        );
        return;
      }
      if (loginMode === "EMAIL" && !validateEmail(inputs.email.trim())) {
        toast.error(
          (language === "KOR" && "이메일 형식에 맞게 입력해주세요.") ||
            (language === "ENG" && "Enter the e-mail according to the format."),
        );
        return;
      }
      try {
        const { data } = await requestAuthCode({
          variables: {
            type: loginMode,
            countryCode: inputs.nation,
            cellphone: inputs.phoneNumber,
            email: inputs.email,
            authPage: loginPage,
          },
        });
        if (data?.requestAuthCode?.result) {
          toast.success(
            (language === "KOR" && "인증번호가 전송되었습니다.") ||
              (language === "ENG" &&
                "The authentication number has been sent."),
          );
          setInputs({ ...inputs, sendcode: true });
        }
        if (data?.requestAuthCode?.error === "2") {
          toast.error(
            (language === "KOR" && "회원가입이 안된 번호입니다.") ||
              (language === "ENG" &&
                "This number is not registered as a member."),
          );
          return;
        }
        if (data?.requestAuthCode?.error === "3") {
          toast.error(
            (language === "KOR" && "문자 전송에 실패하였습니다.") ||
              (language === "ENG" && "Message transfer failed."),
          );
          return;
        }
        if (data?.requestAuthCode?.error === "4") {
          toast.error(
            (language === "KOR" &&
              "정지된 사용자입니다. 관리자에게 문의해주세요.") ||
              (language === "ENG" &&
                "You are a stopped user. Please contact manager."),
          );
          return;
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
    [inputs, loginMode],
  );

  const confirmAuth = useCallback(async () => {
    if (inputs.authcode.length !== 6) {
      toast.error(
        (language === "KOR" && "인증번호 6자리를 입력해주세요.") ||
          (language === "ENG" &&
            "Please enter 6 digits of the authentication number."),
      );
      return;
    }
    try {
      const { data } = await confirmAuthCode({
        variables: {
          countryCode: inputs.nation,
          cellphone: inputs.phoneNumber,
          email: inputs.email,
          authcode: inputs.authcode,
          authPage: true,
        },
      });
      if (data?.confirmAuthCode?.error === "1") {
        toast.error(
          (language === "KOR" && "인증번호 전송 후 시도해주세요.") ||
            (language === "ENG" &&
              "Please try after sending the authentication number."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "2") {
        toast.error(
          (language === "KOR" && "회원가입이 안된 번호입니다.") ||
            (language === "ENG" &&
              "This number is not registered as a member."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "5") {
        toast.error(
          (language === "KOR" && "탈퇴한 회원입니다.") ||
            (language === "ENG" && "Withdrawn member."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "4") {
        toast.error(
          (language === "KOR" && "인증번호가 틀렸습니다.") ||
            (language === "ENG" && "The authentication number is incorrect."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "6") {
        toast.error(
          (language === "KOR" && "PM본사 미승인 사용자입니다.") ||
            (language === "ENG" && "PM head office not approved user."),
        );
        return;
      }
      if (data?.confirmAuthCode?.error === "7") {
        toast.error(
          (language === "KOR" && "정지된 사용자입니다.") ||
            (language === "ENG" && "You are a stopped user."),
        );
        return;
      }
      if (data?.confirmAuthCode?.token !== "") {
        userLogin(data.confirmAuthCode.token);
      }
      if (data?.confirmAuthCode?.result) {
        toast.success(
          (language === "KOR" && "휴대폰번호 인증을 완료하었습니다.") ||
            (language === "ENG" &&
              "Phone number verification has been completed."),
        );
        // setInputs({ ...inputs, auth: true });
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  return (
    <LoginContext.Provider
      value={{
        inputs,
        setInputs,
        handleInput,
        handleSelect,
        sendAuthCode,
        confirmAuth,
        goToSignup,
        loginMode,
        handleLoginMode,
      }}
    >
      <LoginPresenter language={language} setLanguage={setLanguage} />
    </LoginContext.Provider>
  );
};

export default LoginContainer;
