import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import cameraIcon from "assets/icon/cameraIcon.svg";
import defaultProject from "assets/defaultProject.png";
import redDelIcon from "assets/icon/redDelIcon.svg";

const Contaienr = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`;

const Text = styled.div`
  color: ${colors.grayFontColor};
`;

const Input = styled.input`
  display: none;
`;

const InputLabel = styled.label`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${colors.mainColor};
  cursor: pointer;
  margin-top: 16px;
`;

const ImageBox = styled.div`
  position: relative;
  margin-right: 10px;
  margin-top: 16px;
`;

const Image = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 8px;
  background-image: ${({ src }) => `url(${src})`};
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${colors.lightGrayHover};
`;

const GuideText = styled.div`
  white-space: nowrap;
  right: -100%;
  font-size: 14px;
  margin-left: 10px;
  color: ${colors.grayFontColor};
`;

const CameraIcon = styled.img``;

const RowBox = styled.div`
  display: flex;
  align-items: center;
`;

const DelButton = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: -8px;
  border-radius: 50%;
  background-image: url(${redDelIcon});
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${colors.whiteColor};
  cursor: pointer;
`;

const StyledFileInput = ({
  CHECK_LIST,
  multiple,
  name,
  label = "사진",
  guideLabel = "최대 5장까지 업로드할 수 있습니다.",
  onChange,
  delClick,
  image = [],
  margin = "",
  upload = true,
}) => {
  return (
    <Contaienr margin={margin}>
      <Text>{label}</Text>
      {upload && (
        <RowBox>
          <InputLabel htmlFor={name}>
            <Input
              multiple={multiple}
              type="file"
              accept=".jpeg, .jpg, .png"
              name={name}
              id={name}
              onChange={onChange}
            />
            <CameraIcon src={cameraIcon} />
          </InputLabel>
          <GuideText>{guideLabel}</GuideText>
        </RowBox>
      )}
      <RowBox>
        {!CHECK_LIST &&
          image?.map((img, index) => (
            <ImageBox key={index}>
              <Image src={img} />
              {upload && <DelButton onClick={() => delClick(index)} />}
            </ImageBox>
          ))}
        {CHECK_LIST &&
          image?.map((img) => (
            <ImageBox key={img.wcli_id}>
              <Image src={img.wcli_url} />
              {upload && <DelButton onClick={() => delClick(img.wcli_id)} />}
            </ImageBox>
          ))}
      </RowBox>
    </Contaienr>
  );
};

export default StyledFileInput;
