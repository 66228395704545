import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import { dateFormat } from "utils/common";
import NoticeModal from "./NoticeModal";

const Container = styled.div`
  width: 380px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  cursor: pointer;
`;

const Text = styled.div`
  flex-shrink: 0;
  width: ${({ width }) => width}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ DATE }) =>
    DATE &&
    css`
      font-size: 14px;
      color: ${colors.grayFontColor};
    `}
`;

const NoticeCard = ({ notice }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <Container onClick={() => setModal(true)}>
        <Text width={250}>{notice?.noti_title}</Text>
        <Text DATE>
          {dateFormat(new Date(Number(notice?.noti_createdAt)), "yyyy.MM.dd")}
        </Text>
      </Container>
      {modal && (
        <NoticeModal notice={notice} handleModal={() => setModal(false)} />
      )}
    </>
  );
};

export default NoticeCard;
