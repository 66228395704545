import { gql } from "@apollo/client";

export const EDIT_MY_PROFILE = gql`
  mutation editMyProfile(
    $user_name: String
    $user_rank: String
    $user_groupCompany: String
  ) {
    editMyProfile(
      user_name: $user_name
      user_rank: $user_rank
      user_groupCompany: $user_groupCompany
    ) {
      result
      error
    }
  }
`;

export const EDIT_MY_PROFILE_IMG = gql`
  mutation editMyProfileImg($user_profileImg: Upload) {
    editMyProfileImg(user_profileImg: $user_profileImg) {
      result
      error
    }
  }
`;

export const SEND_EMAIL_AUTH_CODE = gql`
  mutation sendEmailAuthCode($email: String) {
    sendEmailAuthCode(email: $email) {
      result
      error
    }
  }
`;

export const EDIT_MY_EMAIL = gql`
  mutation editMyEmail($email: String, $authCode: String) {
    editMyEmail(email: $email, authCode: $authCode) {
      result
      error
    }
  }
`;

export const WITHDRAWAL = gql`
  mutation withdrawal {
    withdrawal {
      result
      error
    }
  }
`;
