import React, { memo } from "react";
import styled, { css } from "styled-components";
import goBackIcon from "assets/icon/goBackIcon.svg";
import { colors } from "styles/colors";
import GuideHeader from "components/layout/GuideHeader";
import StyledFileInput from "components/share/StyledFileInput";
import StyledButton from "components/share/StyledButton";
import { groupChanger } from "../../../../utils/common";
import StyledTextArea from "../../../../components/share/StyledTextArea";
import { engMonthChang } from "../../../../lib/ProjectDetailUtils";
import downloadIcon from "../../../../assets/icon/downloadIcon.svg";
import CheckListCheckboxButton from "../../../../assets/images/button/btn-checkList-checkbox.png";
import CheckListCheckboxActiveButton from "../../../../assets/images/button/btn-checkList-checkbox-active.png";

const Container = styled.div`
  min-width: 1920px;
  width: 100%;
  min-height: calc(100vh - 154px - 81px - 32px);
  background-color: ${colors.whiteColor};
  position: relative;
  padding-bottom: 300px;
`;
const GoBackIconBtn = styled.img`
  position: absolute;
  top: 50px;
  left: 40px;
  cursor: pointer;
`;
const CenterColumn = styled.div`
  width: 957px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;
const Text = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 18)}px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => color};
  cursor: ${({ POINTER }) => POINTER && "pointer"};
  display: flex;
  align-items: center;
`;
const CategoryText = styled.div`
  max-width: 85%;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0;
  line-height: 1.2;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const RowBox = styled.div`
  display: flex;

  &.checkList {
    justify-content: space-between;
  }
`;
const CheckRowCard = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.grayFontColor};
  padding: 30px 0;
  margin: ${({ margin }) => (margin ? margin : 0)};

  ${({ TOP }) =>
    TOP &&
    css`
      border-top: 1px solid ${colors.blackColor};
      border-bottom: 1px solid ${colors.blackColor};
    `};
`;
const CriteriaBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 140px;
  left: 40px;

  & > ${Text} {
    margin: 12px 0 0 0;
  }

  & > ${Text}:first-child {
    margin: 0;
  }
`;
const Icon = styled.img`
  margin: ${({ margin }) => (margin ? margin : 0)};
  cursor: ${({ cursor }) => (cursor ? cursor : "none")};
`;

const CheckDetailPresenter = ({
  language,
  navigate,
  handleImage,
  handleImageDelBtn,
  thumbnail,
  comment,
  setComment,
  checkListDetail,
  handleEnterComments,
  handleCompleteEvaluation,
  evaluationPermission,
}) => {
  return (
    <>
      <GuideHeader
        labelList={
          (language === "KOR" && ["프로젝트 목록", "프로젝트 상세"]) ||
          (language === "ENG" && ["Project List", "Project Detail"])
        }
      />
      <Container>
        <GoBackIconBtn
          src={goBackIcon}
          onClick={() => navigate("/projectDetail/checkList")}
        />
        <CriteriaBox>
          <Text BOLD>점수 설명</Text>
          <Text>
            0 :&nbsp;
            {language === "KOR" && "준수하지 않음"}
            {language === "ENG" && "Unsatisfactory"}
          </Text>
          <Text>
            1 :&nbsp;
            {language === "KOR" && "일부 준수(0~50%)"}
            {language === "ENG" && "Marginal(0~50%)"}
          </Text>
          <Text>
            2 :&nbsp;
            {language === "KOR" && "대부분 준수(50~80%)"}
            {language === "ENG" && "Partial(50~80%)"}
          </Text>
          <Text>
            3 :&nbsp;
            {language === "KOR" && "준수(80~100%)"}
            {language === "ENG" && "Satisfactory(80~100%)"}
          </Text>
          <Text>
            N/A :&nbsp;
            {language === "KOR" && "해당없음"}
            {language === "ENG" && "Not applicable"}
          </Text>
        </CriteriaBox>
        <CenterColumn>
          <RowBox className="checkList">
            <Text BOLD fontSize={24}>
              {language === "KOR" && "체크리스트 평가 상세정보"}
              {language === "ENG" && "Review Weekly Detail Checklist"}
            </Text>
            {checkListDetail?.wcl_status === "complete" && (
              <Icon
                cursor="pointer"
                src={downloadIcon}
                onClick={() => window.open(checkListDetail?.wcl_evaluationPdf)}
              />
            )}
          </RowBox>
          <Text fontSize={16} margin="26px 0 16px 0">
            {language === "KOR" && "평가자"}
            {language === "ENG" && "Inspector"}
            &nbsp;: {groupChanger(
              checkListDetail?.wcl_evaluatorGroup,
              "KOR",
            )}{" "}
            {checkListDetail?.wcl_evaluatorInfo}
          </Text>
          <CheckRowCard TOP>
            <RowBox>
              <Text BOLD margin="0 0 30px 0">
                {language === "KOR" &&
                  `${checkListDetail?.wcl_year}년 ${checkListDetail?.wcl_month}월 ${checkListDetail?.wcl_week}주차`}
                {language === "ENG" &&
                  `${engMonthChang(checkListDetail?.wcl_month)}. ${
                    checkListDetail?.wcl_week
                  }nd week, ${checkListDetail?.wcl_year}`}
              </Text>
            </RowBox>
            <RowBox>
              <Text margin="0 40px 0 0">
                {language === "KOR" && "준수율"}
                {language === "ENG" && "Rate"}
                &nbsp;:&nbsp;
                {checkListDetail?.wcl_complyRate}%
              </Text>
              <Text margin="0 40px 0 0">
                {language === "KOR" && "총점"}
                {language === "ENG" && "Total"}
                &nbsp;:&nbsp;
                {checkListDetail?.wcl_totalScore}
              </Text>
              <Text margin="0 40px 0 0">
                {language === "KOR" && "평가점수"}
                {language === "ENG" && "Score"}
                &nbsp;:&nbsp;
                {checkListDetail?.wcl_evaluationScore}
              </Text>
              <Text margin="0 40px 0 0">
                {language === "KOR" && "반복위반"}
                {language === "ENG" && "Repeat"}
                &nbsp;:&nbsp;
                {checkListDetail?.wcl_repeatViolationCount}
              </Text>
            </RowBox>
          </CheckRowCard>
          <CheckRowCard margin="0 0 30px">
            {checkListDetail?.weeklySafetyCategory.map((item) => (
              <RowBox className="checkList" key={item.wsc_id}>
                <CategoryText BOLD color={colors.blackColor}>
                  {language === "KOR" && item.wsc_safetyCategory_kr}
                  {language === "ENG" && item.wsc_safetyCategory_eng}
                </CategoryText>
                <Text
                  color={
                    item?.wsc_status === "complete"
                      ? colors.grayFontColor
                      : colors.blackColor
                  }
                  POINTER
                  onClick={() => {
                    navigate("/projectDetail/check");
                    localStorage.setItem("wscId", item.wsc_id);
                  }}
                >
                  ({item?.wsc_complyRate}%)&nbsp;
                  {item?.wsc_status !== "notEvaluation" ? (
                    <img
                      src={CheckListCheckboxActiveButton}
                      alt={
                        (language === "KOR" && "평가완료") ||
                        (language === "ENG" && "Completed")
                      }
                    />
                  ) : (
                    <img
                      src={CheckListCheckboxButton}
                      alt={
                        (language === "KOR" && "평가진행중") ||
                        (language === "ENG" && "In Progress")
                      }
                    />
                  )}
                </Text>
              </RowBox>
            ))}
          </CheckRowCard>

          <Text BOLD>
            {(language === "KOR" && "평가 사진 및 의견") ||
              (language === "ENG" && "Pictures and Comments")}
          </Text>

          <StyledTextArea
            margin="20px 0 0"
            label={
              (language === "KOR" &&
                `종합 검토 의견 (${comment?.length} / 500)`) ||
              (language === "ENG" && `Comments (${comment?.length} / 500)`)
            }
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            disabled={
              evaluationPermission !== 1 ||
              checkListDetail?.wcl_status === "complete"
            }
          />
          {evaluationPermission === 1 &&
            checkListDetail?.wcl_status !== "complete" && (
              <StyledButton
                label={
                  (language === "KOR" && "의견 저장하기") ||
                  (language === "ENG" && "Saved Comments")
                }
                width={479}
                height={54}
                fontSize={18}
                margin="20px 0"
                onClick={handleEnterComments}
              />
            )}
          <StyledFileInput
            CHECK_LIST
            multiple
            guideLabel={
              (language === "KOR" && "최대 5장까지 업로드 할 수 있습니다.") ||
              (language === "ENG" && "You can upload up to 5 images.")
            }
            label={
              (language === "KOR" && "사진") ||
              (language === "ENG" && "Pictures")
            }
            name="checkDetailImage"
            image={thumbnail}
            onChange={handleImage}
            delClick={handleImageDelBtn}
            margin="16px 0 0 0"
            upload={
              evaluationPermission === 1 &&
              checkListDetail?.wcl_status !== "complete"
            }
          />
          {evaluationPermission === 1 &&
            checkListDetail?.wcl_status !== "complete" && (
              <StyledButton
                label={
                  (language === "KOR" && "사진 저장하기") ||
                  (language === "ENG" && "Saved Pictures")
                }
                width={479}
                height={54}
                fontSize={18}
                margin="40px 0"
                onClick={handleCompleteEvaluation}
              />
            )}
        </CenterColumn>
      </Container>
    </>
  );
};

export default memo(CheckDetailPresenter);
