import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import ApprovalList from "components/feature/Main/Main/ApprovalList";
import SearchProject from "components/feature/Main/Main/SearchProject";
import {colors} from "styles/colors";
import ProjectCard from "components/feature/Main/Main/ProjectCard";
import {MainContext} from "./MainContainer";

const Container = styled.div`
  width: 1920px;
  /* min-height: calc(1080px - 81px); */
  min-height: calc(100vh - 154px - 81px - 100px);
  display: grid;
  grid-template-columns: 380px 957px 400px;
  column-gap: 50px;
  padding: 0 40px;
  margin: 0 auto 100px auto;
`;

const ProjectListContainer = styled.div`
  width: 957px;
  display: flex;
  flex-direction: column;
  padding: 50px 0 0 0;
  border-radius: 1px solid red;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-left: 23px;
`;

const RowTable = styled.div`
  width: 100%;
  display: flex;
  padding-left: 33px;
  margin: 26px 0 16px 0;
`;
const Text = styled.div`
  width: ${({width}) => width}px;
  color: ${colors.grayFontColor};
`;

const MainPresenter = ({language}) => {
    const {projectList} = useContext(MainContext);

    useEffect(() => {
        sessionStorage.setItem("sort", "");
    }, []);

    return (
        <Container>
            <SearchProject language={language}/>
            <ProjectListContainer>
                <TitleText>
                    {language === "KOR" && "프로젝트 목록"}
                    {language === "ENG" && "Project List"}
                </TitleText>
                <RowTable>
                    <Text width={89}>
                        {language === "KOR" && "사진"}
                        {language === "ENG" && "Image"}
                    </Text>
                    <Text width={222}>
                        {language === "KOR" && "프로젝트명"}
                        {language === "ENG" && "Project Title"}
                    </Text>
                    <Text width={259}>
                        {language === "KOR" && "위치"}
                        {language === "ENG" && "Location"}
                    </Text>
                    <Text width={238}>
                        {language === "KOR" && "사업기간"}
                        {language === "ENG" && "Period"}
                    </Text>
                    <Text width={77}>
                        {language === "KOR" && "상태"}
                        {language === "ENG" && "State"}
                    </Text>
                </RowTable>
                {projectList.map((project, index) => (
                    <ProjectCard key={index} project={project}/>
                ))}
            </ProjectListContainer>
            <ApprovalList language={language}/>
        </Container>
    );
};

export default MainPresenter;
