import React from "react";
import styled, { css } from "styled-components";
import GuideHeader from "components/layout/GuideHeader";
import { colors } from "styles/colors";
import serviceCenterImage from "assets/serviceCenterImage.png";
import messageIcon from "assets/icon/messageIcon.svg";
import callIcon from "assets/icon/callIcon.svg";
import { FaChevronRight } from "react-icons/fa";
import OneOnOneModal from "components/feature/ServiceCenter/OneOnOneModal";
import NoticeCard from "../../components/feature/ServiceCenter/NoticeCard";
import QuestionCard from "../../components/feature/ServiceCenter/QuestionCard";

const Container = styled.div`
  width: 1824px;
  /* min-height: calc(1080px - 82px); */
  min-height: calc(100vh - 154px - 81px - 32px);
  display: grid;
  grid-template-columns: 380px 468px 468px 380px;
  justify-content: space-between;
  margin: 0 auto;
  overflow-x: hidden;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 50px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 140px;
`;

const ServiceCenterInfoBox = styled.div`
  height: 523px;
  display: flex;
  flex-direction: column;
  padding: 26px 30px;
  margin-top: 140px;
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  ${({ GRAY }) =>
    GRAY &&
    css`
      color: ${colors.grayFontColor};
      line-height: 1.3;
    `}
`;

const ServiceCenterImage = styled.img`
  width: 320px;
  height: 182px;
  margin: 15px 0 25px 0;
  border-radius: 8px;
`;

const Icon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 6px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${({ NObg }) => !NObg && `${colors.mainColor}`};
`;

const ButtonText = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  font-size: 18px;
  border-bottom: 1px solid ${colors.styledInputBorder};
  & > .icon {
    color: ${colors.grayFontColor};
  }
`;

const Button = styled.button`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0;
  &:nth-last-child(2) {
    margin-top: 19px;
  }
`;

const SubTitleText = styled.div`
  position: absolute;
  top: 105px;
`;

const ScrollWrapper = styled.div`
  height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    /* width: 10px; */
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    /* border-radius: 10px;
    background-color: ${colors.scrollColor}; */
  }
`;

const ServiceCenterPresenter = ({
  oneOnOneModal,
  vocNumData,
  handleModal,
  faqList,
  noticeList,
  oneList,
  oneRefetch,
  language,
}) => {
  return (
    <>
      <GuideHeader labelList={["고객센터"]} />
      <Container>
        <ServiceCenterInfoBox>
          <Text BOLD>
            {language === "KOR" && "고객센터"}{" "}
            {language === "ENG" && "Service Center"}
          </Text>
          <ServiceCenterImage src={serviceCenterImage} />
          <Text GRAY>
            {language === "KOR" && "리스크제로HG의 고객센터 운영시간은"}
            {language === "ENG" &&
              "RiskZero HG customer center operating hours"}
          </Text>
          <Text GRAY>
            {language === "KOR" && "09:00 ~ 18:00까지이며, 점심시간은"}
            {language === "ENG" && "are from 09:00 to 18:00, lunch time is"}
          </Text>
          <Text GRAY>
            {language === "KOR" && "12:00 ~ 13:00입니다."}
            {language === "ENG" && "12:00 to 13:00."}
          </Text>
          <Button>
            <Icon src={callIcon} />

            <ButtonText
              onClick={() =>
                (document.location.href = `tel:${vocNumData?.seeVocNumberUser?.vocNumber}`)
              }
            >
              {vocNumData?.seeVocNumberUser?.vocNumber}
            </ButtonText>
          </Button>
          <Button onClick={() => handleModal()}>
            <Icon src={messageIcon} />

            <ButtonText>
              {language === "KOR" && "일대일 문의하기"}
              {language === "ENG" && "Go to one-on-one questions"}{" "}
              <FaChevronRight className="icon" />
            </ButtonText>
          </Button>
        </ServiceCenterInfoBox>
        <Column>
          <TitleText>
            {language === "KOR" && "고객센터"}
            {language === "ENG" && "Service Center"}
          </TitleText>
          <SubTitleText>
            {language === "KOR" && "자주 묻는 질문"}
            {language === "ENG" && "Frequently Asked Questions"}
          </SubTitleText>
          <ScrollWrapper>
            {faqList.map((faq, index) => (
              <QuestionCard
                key={index}
                question={faq?.faq_question}
                answerText={faq?.faq_answer}
              />
            ))}
          </ScrollWrapper>
        </Column>
        <Column>
          <SubTitleText>
            {language === "KOR" && "나의 일대일 문의내역"}{" "}
            {language === "ENG" && "One-on-One Questions"}
          </SubTitleText>
          {oneList?.length === 0 && (
            <QuestionCard
              NOBG
              question={
                (language === "KOR" && "내역이 없습니다.") ||
                (language === "ENG" && "There's no history.")
              }
              ANSWER={false}
            />
          )}
          <ScrollWrapper>
            {oneList.map((one, index) => (
              <QuestionCard
                key={index}
                ARROW
                question={one?.one_question}
                answerText={one?.one_answerText}
                ANSWER={one?.one_answer}
              />
            ))}
          </ScrollWrapper>
        </Column>
        <Column>
          <SubTitleText>
            {language === "KOR" && "공지"}
            {language === "ENG" && "Notice"}
          </SubTitleText>
          <ScrollWrapper>
            {noticeList.map((notice, index) => (
              <NoticeCard key={index} notice={notice} />
            ))}
          </ScrollWrapper>
        </Column>
      </Container>
      {oneOnOneModal && (
        <OneOnOneModal handleModal={handleModal} oneRefetch={oneRefetch} />
      )}
    </>
  );
};

export default ServiceCenterPresenter;
