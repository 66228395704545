import React, {useCallback, useContext, useEffect} from "react";
import CheckPresenter from "./CheckPresenter";
import {AppContext} from "App";
import {useNavigate} from "react-router-dom";
import {useLazyQuery} from "@apollo/client";
import {SEE_CHECK_ITEM_LIST} from "../../../../graphql/CheckList/query";
import {toast} from "react-toastify";

const CheckContainer = () => {
    const navigate = useNavigate();
    const {language} = useContext(AppContext);

    const wscId = parseInt(localStorage.getItem('wscId'), 10);

    const [seeCheckItemList, {data, loading, refetch}] = useLazyQuery(SEE_CHECK_ITEM_LIST);

    // 뭐 하나 추가하기
    const handleSaveEvaluation = useCallback(async () => {
        try {
            toast.success(
                (language === "KOR" && "평가를 완료했습니다.") ||
                (language === "ENG" && "Evaluation has been completed")
            )
            navigate('/projectDetail/checkDetail');
            await refetch();
        } catch(e) {
            console.info(e);
        }
    }, []);

    useEffect(() => {
        if (wscId !== null) {
            (async () => {
                try {
                    await seeCheckItemList({
                        variables: {
                            wscId: wscId
                        },
                        fetchPolicy: 'network-only'
                    });
                } catch (e) {
                    console.info(e.message);
                    alert(e.message);
                }
            })();
        }
    }, [wscId]);

    return (
        <CheckPresenter
            language={language}
            navigate={navigate}
            data={data?.seeCheckItemList}
            weeklySafetyCategory={data?.seeCheckItemList?.prjWeeklyCheckItemList}
            refetch={refetch}
            handleSaveEvaluation={handleSaveEvaluation}
        />
    );
};

export default CheckContainer;
