import React, { useState, useCallback, useContext } from "react";
import CreateProjectPresenter from "./CreateProjectPresenter";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_PROJECT } from "graphql/Main/CreateProject/mutation";
import { toast } from "react-toastify";
import { AppContext } from "App";

const CreateProjectContainer = () => {
  const { language } = useContext(AppContext);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    projectName: "",
    address:
      (language === "KOR" && "주소 검색 하기") ||
      (language === "ENG" && "Search Address"),
    addressDetail: "",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    docNumber: "",
    image: "",
    member: [],
    open: true,
  });

  const [imgThumbnail, setImgThumbnail] = useState([]);

  const [createProject] = useMutation(CREATE_PROJECT);

  const handleInput = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs],
  );

  const onChangeFile = useCallback(
    (e) => {
      e.preventDefault();
      const files = e.target.files[0];

      if (
        !["jpeg", "jpg", "png"].includes(
          String(files.name).split(".").reverse()[0],
        )
      ) {
        toast.error(
          (language === "KOR" && "이미지만 업로드 가능합니다.") ||
            (language === "ENG" && "Please select only image"),
        );
        return;
      }

      if (files.size / 1024 / 1024 > 10) {
        toast.error(
          (language === "KOR" && "이미지 파일은 10MB 이하로 업로드해주세요.") ||
            (language === "ENG" && "Please upload the image file under 10MB."),
        );
        return;
      }

      if (files.length < 1) return;

      setInputs({ ...inputs, image: files }); // 뮤테이션 보낼 데이터

      //썸네일
      setImgThumbnail([URL.createObjectURL(files)]);
    },
    [inputs, imgThumbnail],
  );

  const handleImageDelBtn = useCallback(() => {
    setInputs({ ...inputs, image: "" });
    setImgThumbnail([]);
  }, [inputs, imgThumbnail]);

  const handleCreate = useCallback(async () => {
    if (inputs.projectName.trim() === "") {
      toast.error(
        (language === "KOR" && "프로젝트명을 입력해주세요.") ||
          (language === "ENG" && "Please enter the Project name"),
      );
      return;
    }
    if (
      inputs.address.trim() === "주소 검색 하기" ||
      inputs.address.trim() === "Search Address"
    ) {
      toast.error(
        (language === "KOR" && "현장 주소를 입력해주세요.") ||
          (language === "ENG" && "Please enter the site address."),
      );
      return;
    }
    if (inputs.docNumber.trim() === "") {
      toast.error(
        (language === "KOR" && "문서 번호를 입력해주세요.") ||
          (language === "ENG" && "Please enter the Document Number."),
      );
      return;
    }
    if (inputs.image === "") {
      toast.error(
        (language === "KOR" && "이미지를 업로드해주세요.") ||
          (language === "ENG" && "Please upload the Image."),
      );
      return;
    }
    if (
      window.confirm(
        (language === "KOR" && "프로젝트를 생성하시겠습니까?") ||
          (language === "ENG" && "Do you want to create a project?"),
      )
    ) {
      try {
        let variables;
        if (inputs.image === "") {
          variables = {
            prj_openAvailable: inputs.open,
            prj_title: inputs.projectName,
            prj_address: inputs.address + " " + inputs.addressDetail,
            prj_startDate: inputs.startDate,
            prj_endDate: inputs.endDate,
            prj_number: inputs.docNumber,
            selectImg: false,
            prj_members: inputs.member,
          };
        } else {
          variables = {
            prj_openAvailable: inputs.open,
            prj_title: inputs.projectName,
            prj_address: inputs.address + " " + inputs.addressDetail,
            prj_startDate: inputs.startDate,
            prj_endDate: inputs.endDate,
            prj_number: inputs.docNumber,
            selectImg: true,
            prj_img: inputs.image,
            prj_members: inputs.member,
          };
        }
        const { data } = await createProject({ variables });
        if (data?.createProject?.result) {
          window.alert(
            (language === "KOR" && "프로젝트를 생성했습니다.") ||
              (language === "ENG" && "Created a project."),
          );
          navigate("/");
          window.location.reload();
        }
        if (data?.createProject?.error !== "") {
          toast.error(data.createProject.error);
          return;
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [inputs]);

  return (
    <CreateProjectPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleInput={handleInput}
      handleCreate={handleCreate}
      onChangeFile={onChangeFile}
      imgThumbnail={imgThumbnail}
      handleImageDelBtn={handleImageDelBtn}
      language={language}
    />
  );
};

export default CreateProjectContainer;
