import { ApolloClient, InMemoryCache } from "@apollo/client";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createUploadLink } from "apollo-upload-client";

const httpLink = createUploadLink({
  uri: `//${process.env.REACT_APP_SERVER_IP}`,
  headers: {
    "Apollo-Require-Preflight": "true",
    Authorization: !localStorage.getItem("hanmi_scar_token")
      ? ""
      : `Bearer ${localStorage.getItem("hanmi_scar_token")}`,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${process.env.REACT_APP_SERVER_IP}`,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export default new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
