import React from "react";
import GuideHeader from "components/layout/GuideHeader";
import styled from "styled-components";
import { colors } from "styles/colors";
import InputForm from "components/feature/Main/CreateProject/InputForm";
import StyledInput from "components/share/StyledInput";
import StyledFileInput from "../../../components/share/StyledFileInput";
import StyledButton from "../../../components/share/StyledButton";
import DateRangePicker from "../../../components/feature/Main/CreateProject/DateRangePicker";
import MemberSelection from "components/feature/Main/CreateProject/MemberSelection";
import checkIcon from "assets/icon/checkIcon.svg";

const Container = styled.div`
  width: 957px;
  min-height: calc(100vh - 154px - 81px - 32px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  align-content: flex-start;
  margin: 50px auto 100px auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 26px 0;
  grid-column: 1 / 3;
`;

const WhiteBoxTitle = styled.div`
  margin-bottom: 16px;
`;

const WhiteBox = styled.div`
  width: 454px;
  display: flex;
  flex-direction: column;
  padding: 26px 30px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const CheckButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url(${checkIcon});
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: ${({ color }) => color};
  margin-right: 6px;
`;

const Text = styled.div`
  color: ${({ color }) => color};
`;

const RowBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 20px 0 0 0;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreateProjectPresenter = ({
  inputs,
  setInputs,
  handleInput,
  handleCreate,
  onChangeFile,
  imgThumbnail,
  handleImageDelBtn,
  language,
}) => {
  return (
    <>
      <GuideHeader labelList={["프로젝트 목록", "프로젝트 생성하기"]} />
      <Container>
        <Title>
          {language === "KOR" && "프로젝트 생성하기"}
          {language === "ENG" && "Create Project"}
        </Title>
        <WhiteBoxTitle>
          {language === "KOR" && "프로젝트 정보 입력"}
          {language === "ENG" && "Enter project information"}
        </WhiteBoxTitle>
        <WhiteBoxTitle>
          {language === "KOR" && "구성원 추가하기"}
          {language === "ENG" && "Invite Project Members"}
        </WhiteBoxTitle>
        <WhiteBox>
          <StyledInput
            name="projectName"
            label={
              (language === "KOR" && "프로젝트명*") ||
              (language === "ENG" && "Project Name*")
            }
            margin="0 0 14px 0"
            value={inputs.projectName}
            onChange={handleInput}
            maxLength={50}
          />
          <InputForm
            name="addressDetail"
            ADDRESS
            INPUT
            inputs={inputs}
            setInputs={setInputs}
            maxLength={50}
            label={
              (language === "KOR" && "현장주소*") ||
              (language === "ENG" && "Work Site Address*")
            }
            placeholder={
              (language === "KOR" && "상세주소 입력") ||
              (language === "ENG" && "Enter detailed address")
            }
            value={inputs.addressDetail}
            onChange={handleInput}
          />
          <DateRangePicker inputs={inputs} setInputs={setInputs} />
          <StyledInput
            name="docNumber"
            label={
              (language === "KOR" && "문서번호*") ||
              (language === "ENG" && "Document Number*")
            }
            placeholder="HG-SCAR"
            value={inputs.docNumber}
            onChange={handleInput}
            margin="0 0 14px 0"
          />
          <StyledFileInput
            name="image"
            image={imgThumbnail}
            onChange={onChangeFile}
            delClick={handleImageDelBtn}
            label={
              (language === "KOR" && "프로젝트 메인 사진*") ||
              (language === "ENG" && "Project Main Image*")
            }
            guideLabel={
              (language === "KOR" && "1장의 메인 사진을 업로드해주세요.") ||
              (language === "ENG" && "Please upload 1 main image.")
            }
          />
        </WhiteBox>
        <WhiteBox>
          <MemberSelection inputs={inputs} setInputs={setInputs} />
        </WhiteBox>

        <ColumnBox>
          <RowBox onClick={() => setInputs({ ...inputs, open: !inputs.open })}>
            <CheckButton
              color={!inputs.open ? colors.mainColor : colors.scrollColor}
            />
            <Text
              color={!inputs.open ? colors.mainColor : colors.grayFontColor}
            >
              {language === "KOR" && "비공개"}
              {language === "ENG" && "Nondisclosure"}
            </Text>
          </RowBox>
          <StyledButton
            label={
              (language === "KOR" && "프로젝트 생성하기") ||
              (language === "ENG" && "Create Project")
            }
            width={453}
            height={48}
            fontSize={18}
            CREATEICON
            margin="26px 0 0 0"
            onClick={handleCreate}
          />
        </ColumnBox>
      </Container>
    </>
  );
};

export default CreateProjectPresenter;
