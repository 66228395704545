import React, { useContext, useState, useCallback, useEffect } from "react";
import CheckDetailPresenter from "./CheckDetailPresenter";
import { AppContext } from "App";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_CHECK_LIST_DETAIL } from "../../../../graphql/CheckList/query";
import {
  DELETE_CHECK_LIST_IMG,
  EDIT_CHECK_LIST_EVALUATION,
  UPDATE_CHECK_LIST_EVALUATION,
} from "../../../../graphql/CheckList/mutation";
import { getExtension } from "../../../../utils/common";
import { ALLOW_FILE_EXTENSION } from "../../../../utils/constants";

const CheckDetailContainer = () => {
  const { language } = useContext(AppContext);
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [imgFile, setImgFile] = useState([]);

  const wclId = parseInt(localStorage.getItem("wclId"), 10);
  const evaluationPermission = parseInt(
    localStorage.getItem("evaluationPermission"),
    10,
  );

  // Query
  const { data, loading, refetch } = useQuery(SEE_CHECK_LIST_DETAIL, {
    variables: {
      wclId: wclId,
    },
  });

  // Mutation
  const [editCheckListEvaluation] = useMutation(EDIT_CHECK_LIST_EVALUATION);
  const [updateCheckListEvaluation] = useMutation(UPDATE_CHECK_LIST_EVALUATION);
  const [deleteCheckListImg] = useMutation(DELETE_CHECK_LIST_IMG);

  const handleImage = useCallback(
    async (e) => {
      let files = e.target.files;

      if (data?.seeCheckListDetail?.wcl_img.length + files.length > 5) {
        alert(
          (language === "KOR" && "최대 5장까지 업로드할 수 있습니다.") ||
            (language === "ENG" && "You can upload up to 5 images"),
        );
        files = null;
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i].size / 1024 / 1024 > 10) {
          alert(
            (language === "KOR" &&
              "이미지 파일은 10MB 이하로 업로드해주세요.") ||
              (language === "ENG" && "Please upload the image file under 10MB"),
          );
          return;
        }

        let ext = getExtension(files[i].name);

        if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === "") {
          alert(
            (language === "KOR" && "이미지 파일만 업로드 가능합니다.") ||
              (language === "ENG" && "Only image files can be uploaded"),
          );
          return;
        }
      }
      setImgFile([...imgFile, ...files]); // 뮤테이션 보낼 데이터

      const { data: updateCheckListEvaluationData } =
        await updateCheckListEvaluation({
          variables: {
            wclId: wclId,
            wciImgs: files,
          },
        });

      if (updateCheckListEvaluationData?.updateCheckListEvaluation) {
        await refetch();
      }
    },
    [imgFile, data, wclId],
  );

  // 이미지 삭제
  const handleImageDelBtn = useCallback(async (wcliId) => {
    try {
      const { data } = await deleteCheckListImg({
        variables: {
          wcliId: wcliId,
        },
      });

      if (data?.deleteCheckListImg) {
        await refetch();
      }
    } catch (e) {
      console.info(e.message);
      alert(e.message);
    }
  }, []);

  // 종합 검토 의견 입력하기
  const handleEnterComments = useCallback(async () => {
    if (comment === "") {
      alert(
        (language === "Kor" && "의견을 입력해주세요.") ||
          (language === "Eng" && "Please enter your comments"),
      );
      return;
    }
    try {
      const { data } = await editCheckListEvaluation({
        variables: {
          wclEvaluation: comment,
          wclId: wclId,
        },
      });
      if (data?.editCheckListEvaluation) {
        await refetch();
        alert(
          (language === "KOR" && "의견을 입력했습니다.") ||
            (language === "ENG" && "You've entered your comments"),
        );
      }
    } catch (e) {
      console.info(e);
    }
  }, [comment]);

  // 평가 완료
  const handleCompleteEvaluation = useCallback(() => {}, [wclId]);

  useEffect(() => {
    if (!loading && data) {
      setComment(data?.seeCheckListDetail?.wcl_evaluation);
    }
  }, [data, loading]);

  return (
    <CheckDetailPresenter
      language={language}
      navigate={navigate}
      handleImage={handleImage}
      handleImageDelBtn={handleImageDelBtn}
      thumbnail={data?.seeCheckListDetail?.wcl_img}
      comment={comment}
      setComment={setComment}
      checkListDetail={data?.seeCheckListDetail}
      handleEnterComments={handleEnterComments}
      handleCompleteEvaluation={handleCompleteEvaluation}
      evaluationPermission={evaluationPermission}
    />
  );
};

export default CheckDetailContainer;
