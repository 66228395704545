export const colors = {
  // active
  mainColor: "#00C0F3",
  blackColor: "#000",
  whiteColor: "#fff",
  yellowColor: "#FFC400",
  greenColor: "#73EB6E",
  skyblueColor: "#54DAFD",
  // Font
  grayFontColor: "#B5B5B5",
  redFontColor: "#EE3335",
  darkGrayFontColor: "#909090",

  // Border
  styledInputBorder: "#E6E6E6",
  lightgrayBorder: "#F2F2F2",
  blueBorder: "#2564B1",

  // hover
  lightGrayHover: "#F5F5F5",
  mainColorHover: "#0BBCEB",

  // Background
  defaultBackgroundColor: "#FAFAFA",
  footerBackgroundColor: "#013662",
  lightgrayBackgroundColor: "#F9F9F9",
  scrollColor: "#B5B5B5",
  modalDarkBgColor: "rgba(0, 0, 0, 0.6)",
  miniPieChartSkyBlueColor: "#D4E0F0",
  pieChartBlueColor: "#008AF9",
  pieChartOrangeColor: "#FF804B",
  pieChartYellowColor: "#FFBB41",
};
