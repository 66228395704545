import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import bellIcon from "assets/icon/bellIcon.svg";
import { ProjectDetailContext } from "routes/Main/ProjectDetail/ProjectDetail/ProjectDetailContainer";
import { useNavigate } from "react-router-dom";
import { REQUEST_CLOSE_PROJECT } from "graphql/Main/ProjectDetail/ProjectDetail/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { ProfileContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 296px;
  height: 554px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.lightgrayBorder};
`;

const Icon = styled.img`
  width: 30px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  font-size: 18px;
  color: ${({ color }) => color};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  margin: ${({ BOLD }) => !BOLD && "12px 0 32px 0"};
`;

const NaviModal = ({ language }) => {
  const navigate = useNavigate();
  const {
    handleNaviModal,
    handleMemberEditModal,
    handlePrjEditModal,
    handleLeaveProject,
    project,
    prjDetailRefetch,
  } = useContext(ProjectDetailContext);
  const { myProfile } = useContext(ProfileContext);
  const [requestCloseProject] = useMutation(REQUEST_CLOSE_PROJECT);

  // console.log(project);

  const handleRequestClose = useCallback(async () => {
    // if (
    //   project.prj_endRequestDate !== "" &&
    //   Number(project.prj_endRequestDate) < new Date().getTime()
    // ) {
    //   alert(
    //     (language === "KOR" && "종료된 프로젝트입니다.") ||
    //       (language === "ENG" && "Project terminated.")
    //   );
    //   return;
    // }
    // if (Number(project.prj_endDate) < new Date().getTime()) {
    //   alert("종료된 프로젝트입니다.");
    //   return;
    // }
    if (
      !window.confirm(
        (language === "KOR" &&
          "관리자가 프로젝트 종료시 진행중인 SCAR는 최종완료처리됩니다. 프로젝트 종료 요청을 진행할까요?") ||
          (language === "ENG" &&
            "The SCAR in progress is completed when the administrator finishes the project. Shall we proceed with the project termination request?")
      )
    ) {
      return;
    }
    try {
      const { data } = await requestCloseProject({
        variables: {
          prj_id: project?.prj_id,
        },
      });
      if (data?.requestCloseProject?.result) {
        toast.success("종료 요청을 보냈습니다.");
        await prjDetailRefetch();
        handleNaviModal();
      }
      if (data?.requestCloseProject?.error !== "") {
        toast.error(data?.requestCloseProject?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  const editPermission = useCallback(
    (type) => {
      // console.log(
      //   project.prj_endRequestDate !== "",
      //   Number(project.prj_endRequestDate),
      //   Number(project.prj_endDate),
      //   new Date().getTime()
      // );
      // if (
      //   project.prj_endRequestDate !== "" &&
      //   Number(project.prj_endRequestDate) < new Date().getTime()
      // ) {
      //   toast.error(
      //     (language === "KOR" && "종료된 프로젝트입니다.") ||
      //       (language === "ENG" && "Project terminated.")
      //   );
      //   return;
      // }
      // if (Number(project.prj_endDate) < new Date().getTime()) {
      //   toast.error(
      //     (language === "KOR" && "종료된 프로젝트입니다.") ||
      //       (language === "ENG" && "Project terminated.")
      //   );
      //   return;
      // }
      if (type === "edit") {
        // 프로젝트 수정
        if (
          // 내가 PM본사  || 프로젝트 생성한 사람이면 수정 가능
          myProfile?.user_group === "head" ||
          project?.prj_creatorInfo ===
            myProfile?.user_group + " " + myProfile?.user_name
        ) {
          handleNaviModal();
          handlePrjEditModal();
        } else {
          toast.error(
            (language === "KOR" && "권한이 없습니다.") ||
              (language === "ENG" && "You don't have permission.")
          );
        }
      }
      if (type === "member") {
        //프로젝트 멤버 수정
        if (
          // 내가 PM본사 || PM현장 ||  프로젝트 생성한 사람이면 수정 가능
          myProfile?.user_group === "head" ||
          myProfile?.user_group === "local" ||
          project?.prj_creatorInfo ===
            myProfile?.user_group + " " + myProfile?.user_name
        ) {
          handleNaviModal();
          handleMemberEditModal();
        } else {
          toast.error(
            (language === "KOR" && "권한이 없습니다.") ||
              (language === "ENG" && "You don't have permission.")
          );
        }
      }
    },
    [myProfile, project]
  );

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Icon src={bellIcon} />
        <Text BOLD>
          {language === "KOR" && "알림"}
          {language === "ENG" && "Setting"}
        </Text>
        <Text>
          {language === "KOR" && "어떤 작업을 하시겠어요?"}
          {language === "ENG" && "Select the menu"}
        </Text>
        <Button onClick={() => navigate("/projectDetail/stats")}>
          {language === "KOR" && "통계"}
          {language === "ENG" && "Statistics"}
        </Button>
        <Button onClick={() => editPermission("edit")}>
          {language === "KOR" && "프로젝트 수정하기"}
          {language === "ENG" && "Edit Project Details"}
        </Button>
        <Button onClick={() => editPermission("member")}>
          {language === "KOR" && "구성원 수정하기"}
          {language === "ENG" && "Edit Members"}
        </Button>
        <Button onClick={() => handleRequestClose()}>
          {language === "KOR" && "프로젝트 종료 요청하기"}
          {language === "ENG" && "Request to close project"}
        </Button>
        <Button
          color={colors.redFontColor}
          onClick={() => handleLeaveProject()}
        >
          {language === "KOR" && "프로젝트 탈퇴하기"}
          {language === "ENG" && "Quit Project"}
        </Button>
        <Button color={colors.grayFontColor} onClick={handleNaviModal}>
          {language === "KOR" && "닫기"}
          {language === "ENG" && "Close"}
        </Button>
      </ModalContainer>
    </Container>
  );
};

export default NaviModal;
