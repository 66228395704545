import React, {memo, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import {PieChart, Pie, Cell} from "recharts";
import {colors} from "../../../../../styles/colors";
import {AppContext} from "App";
import {StatsContext} from "routes/Main/ProjectDetail/Stats/StatsContainer";

const Wrapper = styled.div`
  width: 468px;
  height: 100%;
  margin-top: 26px;
`;

const Header = styled.div`
  padding-bottom: 9px;
  font-weight: bold;
`;

const ChartBox = styled.div``;

const StatsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Stats = styled.div`
  margin-bottom: 16px;
`;

const Text = styled.div`
  white-space: nowrap;
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 400)};
  color: ${({fontColor, theme}) => fontColor ? fontColor : theme.defaultFontColor};

  & + & {
    margin-top: 6px;
  }
`;

const RowBox = styled.div`
  display: flex;
  margin-top: 9px;
  background-color: ${colors.whiteColor};
`;

const CheckCircleChart = ({}) => {
    const {language} = useContext(AppContext);
    const COLORS = [
        colors.pieChartBlueColor,
        colors.pieChartOrangeColor,
        colors.pieChartYellowColor,
        colors.grayFontColor,
    ];

    const {checkListStatsData} = useContext(StatsContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (checkListStatsData) {
            console.info(',,,', checkListStatsData?.checkListSocreRate)

            setData([
                {
                    name: '준수',
                    value: checkListStatsData?.checkListSocreRate?.compliantCount,
                    ratio: checkListStatsData?.checkListSocreRate?.compliant
                },
                {
                    name: '대부분준수',
                    value: checkListStatsData?.checkListSocreRate?.mainlyCompliantCount,
                    ratio: checkListStatsData?.checkListSocreRate?.mainlyCompliant
                },
                {
                    name: '일부준수',
                    value: checkListStatsData?.checkListSocreRate?.partiallyCompliantCount,
                    ratio: checkListStatsData?.checkListSocreRate?.partiallyCompliant
                },
                {
                    name: '해당없음',
                    value: checkListStatsData?.checkListSocreRate?.notapplicableCount,
                    ratio: checkListStatsData?.checkListSocreRate?.notapplicable
                },
            ]);
        }
    }, [checkListStatsData]);

    return (
        <Wrapper>
            <Header>
                {language === "KOR" && "체크리스트 비율 분포"}
                {language === "ENG" && "Checklist Ratio Chart"}
            </Header>
            <RowBox>
                <ChartBox>
                    <PieChart width={256} height={256}>
                        <Pie
                            data={data}
                            cx={118}
                            cy={118}
                            innerRadius={83}
                            outerRadius={100}
                            paddingAngle={5}
                            dataKey="value"
                            startAngle={90}
                            endAngle={-360}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                            ))}
                        </Pie>
                    </PieChart>
                </ChartBox>
                <StatsBox>
                    {data.map((stats, index) => (
                        <Stats key={index}>
                            <Text>{stats.name}</Text>
                            <Text fontWeight={600} fontColor={COLORS[index]}>
                                <CountUp end={stats.ratio} duration={1}/>% ({stats.value} / {checkListStatsData?.checkListSocreRate?.totalCheckItemCount}
                                )
                            </Text>
                        </Stats>
                    ))}
                </StatsBox>
            </RowBox>
        </Wrapper>
    );
};

export default memo(CheckCircleChart);
