import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import MainPresenter from "./MainPresenter";
import { useQuery } from "@apollo/client";
import { SEE_MY_PROJECT } from "graphql/Main/Main/query";
import { prjStatusChanger, sortChanger } from "lib/MainUtils";
import { ProfileContext, AppContext } from "App";

export const MainContext = createContext();

const MainContainer = () => {
  const now = new Date();
  const searchStartDate = new Date(2019, 0, 1);
  const searchEndDate = new Date(now.getFullYear() + 1, 11, 31);
  const { myProfile } = useContext(ProfileContext);
  const { language } = useContext(AppContext);
  const [projectList, setProjectList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    state: "전체",
    startDate: searchStartDate,
    endDate: searchEndDate,
    sort: "내림차순",
  });

  const {
    data: filterData,
    loading: filterLoading,
    refetch: filterRefetch,
  } = useQuery(SEE_MY_PROJECT, {
    variables: {
      searchTerm: inputs.searchTerm,
      status: prjStatusChanger(inputs.state),
      startDate: inputs.startDate,
      endDate: inputs.endDate,
      sort: sortChanger(inputs.sort),
    },
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      state: "전체",
      startDate: new Date(2019, 0, 1),
      endDate: new Date(now.getFullYear() + 1, 11, 31),
      sort: "내림차순",
    });
  }, [inputs]);

  useEffect(() => {
    if (filterData?.seeMyProject?.result) {
      setProjectList(filterData.seeMyProject.projectList);
    }
  }, [filterData]);

  return (
    <MainContext.Provider
      value={{ inputs, setInputs, handleReset, projectList, myProfile }}
    >
      <MainPresenter language={language} />
    </MainContext.Provider>
  );
};

export default MainContainer;
