import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiChevronDown } from "react-icons/fi";

const Container = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
  flex-shrink: 0;
  /* border: 1px solid red; */
`;

const LabelText = styled.div`
  color: ${colors.grayFontColor};
  font-size: ${({ labelFontSize }) => labelFontSize}px;
`;

const ValueDiv = styled.div`
  width: 100%;
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: ${({ NOBORDER }) =>
    !NOBORDER && `1px solid ${colors.styledInputBorder}`};
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ grayFontColor }) => grayFontColor && `${colors.grayFontColor}`};
  & > .downIcon {
    position: absolute;
    right: 0;
    font-size: 24px;
    color: ${colors.blackColor};
  }
`;

const OptionWrapper = styled.div`
  width: 100%;
  max-height: 180px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  overflow-y: auto;
  background-color: ${colors.defaultBackgroundColor};
  z-index: 1;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.scrollColor};
    border-radius: 5px;
  }
`;

const Option = styled.div`
  height: 59px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-left: 5px;
  font-size: 18px;
  color: ${colors.blackColor};
  border-bottom: 1px solid ${colors.styledInputBorder};
  &:hover {
    background-color: ${colors.lightGrayHover};
  }
`;

const StyledSelect = ({
  name,
  label,
  margin,
  width,
  value,
  options,
  onChange,
  NOBORDER,
  BOLD,
  height = 69,
  labelFontSize = 16,
  fontSize = 24,
  placeholder = "",
}) => {
  const [optionOpen, setOptionOpen] = useState(false);
  const selectbox = useRef();

  useEffect(() => {
    const click = (e) => {
      if (!selectbox?.current?.contains(e.target)) setOptionOpen(false);
    };
    window.addEventListener("click", click);
    return () => window.removeEventListener("click", click);
  }, []);

  return (
    <Container margin={margin} width={width}>
      <LabelText labelFontSize={labelFontSize}>{label}</LabelText>
      <ValueDiv
        height={height}
        NOBORDER={NOBORDER}
        ref={selectbox}
        fontSize={fontSize}
        BOLD={BOLD}
        onClick={(e) => {
          e.preventDefault();
          setOptionOpen(!optionOpen);
        }}
        grayFontColor={value === ""}
      >
        {value === "" ? placeholder : value}{" "}
        <FiChevronDown className="downIcon" />
        {optionOpen && (
          <OptionWrapper>
            {options.map((op, index) => (
              <Option key={index} onClick={() => onChange(name, op)}>
                {op}
              </Option>
            ))}
          </OptionWrapper>
        )}
      </ValueDiv>
    </Container>
  );
};
export default StyledSelect;
