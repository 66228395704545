import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 154px - 81px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 1920px;
`;

const GuideLinkText = styled.div`
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: ${colors.darkGrayFontColor};
  }
`;

const GuidePresenter = ({ language }) => {
  return (
    <Container>
      <GuideLinkText onClick={() => window.open("https://myurl.ai/zbPftQ")}>
        {language === "KOR" && "가이드 영상 보러가기"}
        {language === "ENG" && "Go watch the guide video"}
      </GuideLinkText>
    </Container>
  );
};

export default GuidePresenter;
