import React from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";

const Container = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${({ margin }) => margin};
  flex-shrink: 0;
`;

const LabelText = styled.div`
  font-size: 18px;
  color: ${colors.grayFontColor};
`;

const Input = styled.input`
  width: 100%;
  font-size: 24px;
  padding-bottom: 22px;
  margin-top: 18px;
  border-bottom: 1px solid ${colors.styledInputBorder};
  ::placeholder {
    color: ${colors.grayFontColor};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          background: none;
        `
      : css``}
`;

const InputForm = ({
  name,
  label,
  margin,
  width,
  value,
  onChange,
  maxLength,
  placeholder = "",
  disabled = false,
}) => {
  return (
    <Container margin={margin} width={width}>
      <LabelText>{label}</LabelText>
      <Input
        name={name}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
      />
    </Container>
  );
};

export default InputForm;
