import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import questionIcon from "assets/icon/questionIcon.svg";
import answerIcon from "assets/icon/answerIcon.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Container = styled.div`
  width: 468px;
  display: flex;
  flex-direction: column;
  padding: 26px 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: ${({ NOBG }) => !NOBG && `${colors.whiteColor}`};
  cursor: pointer;
`;

const Icon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 6px;
  background-image: url(${questionIcon});
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${({ grayBg }) =>
    grayBg ? `${colors.lightgrayBorder}` : `${colors.mainColor}`};
  flex-shrink: 0;
`;

const QuestionText = styled.div`
  width: 330px;
  white-space: normal;
  word-break: break-all;
  font-size: 18px;
  margin: 0 auto 0 10px;
  line-height: 1.4;
`;

const Arrow = styled.img`
  flex-shrink: 0;
  transform: ${({ isrotate }) => !isrotate && `rotate(180deg)`};
  ${({ ANSWER }) =>
    ANSWER &&
    css`
      transform: rotate(0deg);
      margin: 14px 15px 0 16px;
    `}
`;

const AnswerText = styled.div`
  line-height: 1.4;
  margin-top: 13px;
  white-space: normal;
  word-break: break-all;
`;

const RowBox = styled.div`
  display: flex;
  align-items: ${({ CENTER }) => (CENTER ? "center" : "flex-start")};

  & > .icon {
    margin-top: 10px;
  }
`;

const QuestionCard = ({
  NOBG,
  ARROW,
  ANSWER = true,
  question = "",
  answerText = "",
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <Container onClick={() => ANSWER && setClicked(!clicked)} NOBG={NOBG}>
      <RowBox isrotate={clicked}>
        <Icon grayBg={!ANSWER} />
        <QuestionText>{question}</QuestionText>
        {ANSWER ? (
          clicked ? (
            <FaChevronUp className="icon" />
          ) : (
            <FaChevronDown className="icon" />
          )
        ) : (
          ""
        )}
      </RowBox>
      {clicked &&
        (ARROW ? (
          <RowBox>
            <Arrow src={answerIcon} ANSWER />
            <AnswerText>{answerText}</AnswerText>
          </RowBox>
        ) : (
          <AnswerText>{answerText}</AnswerText>
        ))}
    </Container>
  );
};

export default QuestionCard;
