import React, {useContext} from "react";
import ProjectMember from "components/feature/Main/ProjectDetail/ProjectDetail/ProjectMember";
import SearchScar from "components/feature/Main/ProjectDetail/Stats/SearchScar";
import styled from "styled-components";
import excelIcon from "assets/icon/excelIcon.svg";
import {StatsContext} from "routes/Main/ProjectDetail/Stats/StatsContainer";
import ScarInfo from "components/feature/Main/ProjectDetail/ProjectDetail/ScarInfo";
import CircleChart from "components/feature/Main/ProjectDetail/Stats/CircleChart";
import Table from "components/feature/Main/ProjectDetail/Stats/Table";
import GuideHeader from "components/layout/GuideHeader";
import CheckCircleChart from "components/feature/Main/ProjectDetail/Stats/CheckCircleChart";
import StickChart from "../../../../components/feature/Main/ProjectDetail/Stats/StickChart";

const Container = styled.div`
  width: 1920px;
  min-height: calc(100vh - 154px - 81px - 32px);
  display: grid;
  grid-template-columns: 380px 957px 400px;
  align-content: flex-start;
  column-gap: 50px;
  padding: 0 40px;
  margin: auto;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin: 50px 0 100px 0;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  align-self: flex-start;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const SubTitleText = styled.div`
  grid-column: 1 / 3;
  margin: 26px 0 16px 0;
`;

const ExcelIcon = styled.img`
  margin-left: auto;
  cursor: pointer;
`;

const StatsPresenter = ({language, checkListStatsData}) => {
    const {handleExcel, statsType} = useContext(StatsContext);

    return (
        <>
            <GuideHeader labelList={["프로젝트 목록", "프로젝트 상세", "통계"]}/>
            <Container>
                <SearchScar/>
                <StatContainer>
                    <TitleText>
                        {language === "KOR" && "프로젝트 통계"}{" "}
                        {language === "ENG" && "Project SCAR Report"}
                    </TitleText>
                    <ExcelIcon src={excelIcon} onClick={() => handleExcel()}/>
                    {statsType === "scar" && (
                        <>
                            <ColumnBox>
                                <Table SCARTOTAL/>
                                <Table SCARLABEL/>
                            </ColumnBox>
                            <ColumnBox>
                                <Table SCARTOTAL2/>
                                <CircleChart/>
                            </ColumnBox>
                        </>
                    )}
                    {statsType === "checkList" && (
                        <>
                            <ColumnBox>
                                <Table
                                    CHECKTOTAL
                                    checkListStatsData={checkListStatsData}
                                />
                                <Table
                                    CHECKLABEL
                                    checkListStatsData={checkListStatsData}
                                />
                            </ColumnBox>
                            <ColumnBox>
                                <CheckCircleChart/>
                                <StickChart
                                    data={checkListStatsData?.byMonthStatistics}
                                />
                            </ColumnBox>
                        </>
                    )}
                </StatContainer>
                <ProjectMember/>
            </Container>
        </>
    );
};

export default StatsPresenter;
