import {gql} from '@apollo/client';

export const SEE_PROJECT_CHECK_LIST = gql`
    query seeProjectCheckList ($prjId: Int) {
        seeProjectCheckList(prj_id: $prjId) {
            completeCheckList {
                wcl_id
                wcl_year
                wcl_month
                wcl_week
                wcl_complyRate
                wcl_totalScore
                wcl_evaluationScore
                wcl_repeatViolationCount
                wcl_status
            }
            thisWeekCheckList {
                wcl_id
                wcl_year
                wcl_month
                wcl_week
                wcl_status
            }
            evaluationPermission
        }
    }
`;

export const SEE_CHECK_LIST_DETAIL = gql`
    query seeCheckListDetail($wclId: Int!) {
        seeCheckListDetail (wcl_id: $wclId) {
            wcl_id
            wcl_year
            wcl_month
            wcl_week
            wcl_status
            wcl_evaluatorGroup
            wcl_evaluatorInfo
            wcl_evaluatorImg
            wcl_complyRate
            wcl_totalScore
            wcl_evaluationScore
            wcl_repeatViolationCount
            weeklySafetyCategory {
                wsc_id
                wsc_safetyCategory_kr
                wsc_safetyCategory_eng
                wsc_complyRate
                wsc_status
            }
            wcl_evaluation
            wcl_img {
                wcli_id
                wcli_url
            }
            wcl_evaluationPdf
        }
    }
`;

export const SEE_CHECK_ITEM_LIST = gql`
    query seeCheckItemList($wscId: Int!) {
        seeCheckItemList(wsc_id: $wscId) {
            scStatus
            safetyCategoryKr
            safetyCategoryEng
            scComplyRate
            prjWeeklyCheckItemList {
                wci_id
                wci_checkItem_kr
                wci_checkItem_eng
                wci_score
                wci_comment
                wci_repeatedViolation
                lastWeekScore
            }
        }
    }
`;