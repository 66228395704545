import React, { useState } from "react";
import styled from "styled-components";
import defaultProject from "assets/defaultProject.png";
import fullViewIcon from "assets/icon/fullViewIcon.svg";
import { colors } from "styles/colors";
import FullViewModal from "./FullViewModal";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 20px 0;
`;

const BigImage = styled.div`
  width: calc(50% - 5px);
  aspect-ratio: 1 / 1;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 8px;
  &:first-child {
    margin-right: 10px;
  }
`;

const SmallImage = styled.div`
  width: 55px;
  height: 55px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 10px;
`;

const SmallImageWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
`;

const FullButton = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  border: 1px solid ${colors.mainColor};
  color: ${colors.mainColor};
  cursor: pointer;
  & > img {
    width: 15px;
  }
`;

const Image = ({ image }) => {
  const [fullView, setFullView] = useState(false);

  return (
    <>
      <Container>
        {image?.length >= 1 && <BigImage src={image[0]} />}
        {image?.length >= 2 && <BigImage src={image[1]} />}
        <SmallImageWrapper>
          {image?.length >= 3 && <SmallImage src={image[2]} />}
          {image?.length >= 4 && <SmallImage src={image[3]} />}
          {image?.length >= 5 && <SmallImage src={image[4]} />}
          {image?.length >= 1 && (
            <FullButton onClick={() => setFullView(true)}>
              <img src={fullViewIcon} />
              전체보기
            </FullButton>
          )}
        </SmallImageWrapper>
      </Container>
      {fullView && <FullViewModal image={image} setFullView={setFullView} />}
    </>
  );
};

export default Image;
