import React, { useCallback, useEffect, useState, useContext } from "react";
import ServiceCenterPresenter from "./ServiceCenterPresenter";
import { useQuery } from "@apollo/client";
import {
  SEE_VOC_NUMBER_USER,
  SEE_FAQ_LIST_USER,
  SEE_NOTICE_LIST_USER,
  SEE_MY_ONE_LIST,
} from "graphql/ServiceCenter/query";
import { AppContext } from "App";

const ServiceCenterContainer = () => {
  const { language } = useContext(AppContext);
  const [oneOnOneModal, setOneOnOneModal] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [oneList, setOneList] = useState([]);

  const {
    data: vocNumData,
    loading: vocNumLoading,
    refetch: vodNumRefetch,
  } = useQuery(SEE_VOC_NUMBER_USER);

  const {
    data: faqData,
    loading: faqLoading,
    refetch: faqRefetch,
  } = useQuery(SEE_FAQ_LIST_USER);

  const {
    data: noticeData,
    loading: noticeLoading,
    refetch: noticeRefetch,
  } = useQuery(SEE_NOTICE_LIST_USER);

  const {
    data: oneData,
    loading: oneLoading,
    refetch: oneRefetch,
  } = useQuery(SEE_MY_ONE_LIST);

  const handleModal = useCallback(() => {
    setOneOnOneModal(!oneOnOneModal);
  }, [oneOnOneModal]);

  useEffect(() => {}, [vocNumData]);

  useEffect(() => {
    if (faqData?.seeFAQListUser?.result) {
      setFaqList(faqData.seeFAQListUser.FAQList);
    }
  }, [faqData]);

  useEffect(() => {
    if (noticeData?.seeNoticeListUser?.result) {
      setNoticeList(noticeData.seeNoticeListUser.noticeList);
    }
  }, [noticeData]);

  useEffect(() => {
    if (oneData?.seeMyOneList?.myOneOnOneInfo) {
      setOneList(oneData.seeMyOneList.myOneOnOneInfo);
    }
  }, [oneData]);

  return (
    <ServiceCenterPresenter
      oneOnOneModal={oneOnOneModal}
      handleModal={handleModal}
      vocNumData={vocNumData}
      faqList={faqList}
      noticeList={noticeList}
      oneList={oneList}
      oneRefetch={oneRefetch}
      language={language}
    />
  );
};

export default ServiceCenterContainer;
