import React, { useState, useCallback, useContext, useEffect } from "react";
import ProfilePresenter from "./ProfilePresenter";
import { ProfileContext } from "App";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import {
  EDIT_MY_EMAIL,
  EDIT_MY_PROFILE,
  EDIT_MY_PROFILE_IMG,
  SEND_EMAIL_AUTH_CODE,
} from "graphql/Profile/mutation";
import { groupChanger } from "utils/common";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { AppContext } from "App";

const ProfileContainer = () => {
  const { language } = useContext(AppContext);
  const { myProfile, myProfileRefetch } = useContext(ProfileContext);
  const [profileImage, setProfileImage] = useState(emptyProfile);
  const [notiList, setNotiList] = useState([]);
  const [inputs, setInputs] = useState({
    name: "",
    rank: "",
    team: "",
    company: "",
    mail: "",
    mailCode: "",
  });

  const [editMyProfile] = useMutation(EDIT_MY_PROFILE);
  const [editMyProfileImg] = useMutation(EDIT_MY_PROFILE_IMG);
  const [sendEmailAuthCode] = useMutation(SEND_EMAIL_AUTH_CODE);
  const [editMyEmail] = useMutation(EDIT_MY_EMAIL);

  const handleInput = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs],
  );

  const editProfile = useCallback(async () => {
    if (!window.confirm("정보를 수정하시겠습니까?")) {
      return;
    }
    try {
      const { data } = await editMyProfile({
        variables: {
          user_name: inputs.name,
          user_rank: inputs.rank,
          user_groupCompany: inputs.company,
        },
      });
      if (data?.editMyProfile?.result) {
        toast.success("정보를 변경하였습니다.");
        setInputs({ ...inputs, name: "", rank: "", company: "" });
        await myProfileRefetch();
      }
      if (data?.editMyProfile?.error !== "") {
        toast.error(data?.editMyProfile?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  const onChangeProfileImg = useCallback(async (e) => {
    if (
      !window.confirm(
        (language === "KOR" && "프로필 사진을 변경하시겠습니까?") ||
          (language === "ENG" && "Do you want to change the image?"),
      )
    ) {
      return;
    }
    try {
      e.preventDefault();
      const files = e.target.files[0];

      if (
        !["jpeg", "jpg", "png"].includes(
          String(files.name).split(".").reverse()[0],
        )
      ) {
        toast.error(
          (language === "KOR" && "이미지만 업로드 가능합니다.") ||
            (language === "ENG" && "Please select only image"),
        );
        return;
      }

      if (files.size / 1024 / 1024 > 10) {
        toast.error(
          (language === "KOR" && "이미지 파일은 10MB 이하로 업로드해주세요.") ||
            (language === "ENG" && "Please upload the image file under 10MB."),
        );
        return;
      }

      if (files.length < 1) return;
      // files;
      const { data } = await editMyProfileImg({
        variables: {
          user_profileImg: files,
        },
      });
      if (data?.editMyProfileImg?.result) {
        await myProfileRefetch();
        toast.success("프로필사진을 변경하였습니다.");
      }
      if (data?.editMyProfileImg?.error !== "") {
        toast.error(data?.editMyProfileImg?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }

    //썸네일
  }, []);

  const sendEmail = useCallback(async () => {
    if (inputs.mail.trim() === "") {
      toast.error("메일을 입력해주세요.");
      return;
    }
    if (!inputs.mail.includes("@") || !inputs.mail.includes(".")) {
      toast.error("메일 형식이 올바르지 않습니다..");
      return;
    }
    try {
      const { data } = await sendEmailAuthCode({
        variables: {
          email: inputs.mail,
        },
      });
      if (data?.sendEmailAuthCode?.result) {
        toast.success("메일을 전송하였습니다.");
      }
      if (data?.sendEmailAuthCode?.error !== "") {
        toast.error(data?.sendEmailAuthCode?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  useEffect(() => {
    if (myProfile) {
      setInputs({
        ...inputs,
        team: groupChanger(myProfile.user_group, language),
      });
      setProfileImage(myProfile.user_profileImg);
    }
  }, [myProfile]);

  const EditEmail = useCallback(async () => {
    if (inputs.mail.trim === "" || inputs.mailCode.trim() === "") {
      toast.error("빈칸을 모두 입력해주세요.");
      return;
    }
    try {
      const { data } = await editMyEmail({
        variables: {
          email: inputs.mail,
          authCode: inputs.mailCode,
        },
      });
      if (data?.editMyEmail?.result) {
        await myProfileRefetch();
        toast.success("메일 인증이 완료되었습니다.");
        setInputs({ ...inputs, mailCode: "" });
      } else {
        toast.error(data?.editMyEmail?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  useEffect(() => {
    if (myProfile) {
      setInputs({
        name: myProfile?.user_name,
        rank: myProfile?.user_rank,
        team: groupChanger(myProfile?.user_group, language),
        company: myProfile?.user_groupCompany,
        mail: myProfile?.user_email,
        mailCode: "",
      });
      setNotiList(myProfile?.notification);
    }
  }, [myProfile]);

  return (
    <ProfilePresenter
      inputs={inputs}
      handleInput={handleInput}
      myProfile={myProfile}
      editProfile={editProfile}
      profileImage={profileImage}
      onChangeProfileImg={onChangeProfileImg}
      sendEmail={sendEmail}
      EditEmail={EditEmail}
      notiList={notiList}
      language={language}
    />
  );
};

export default ProfileContainer;
