import React, { useCallback, useState, useContext } from "react";
import styled, { css } from "styled-components";
import StyledInput from "components/share/StyledInput";
import StyledSelect from "components/share/StyledSelect";
import { nationList } from "components/feature/Login/nationNumber";
import { colors } from "styles/colors";
import { toast } from "react-toastify";
import { AppContext } from "App";
import { onlyNumber } from "utils/common";

const Container = styled.div`
  /* border: 1px solid red; */
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const Button = styled.button`
  height: 26px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid ${colors.grayFontColor};
  ${({ select }) =>
    select &&
    css`
      background-color: ${colors.mainColor};
      color: ${colors.whiteColor};
      border: 1px solid ${colors.mainColor};
    `}
`;

const ButtonWrapper = styled.div`
  width: 220px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const CirculatorsInput = ({ setInputs, inputs }) => {
  const { language } = useContext(AppContext);
  const [select, setSelect] = useState(true);
  const [phoneOrMail, setPhoneOrMail] = useState("");
  const [country, setCountry] = useState("+82");
  // const [group, setGroup] = useState("");

  const handlePlus = useCallback(async () => {
    if (!select && !phoneOrMail.includes("@")) {
      // 이메일인 경우 && 골뱅이 없는 경우
      toast.error(
        (language === "KOR" && "이메일 형식이 맞지 않습니다.") ||
          (language === "ENG" && "The email format is not correct.")
      );
      return;
    }
    if (select && phoneOrMail.replaceAll(/[0-9 ]/gim, "").trim() !== "") {
      toast.error(
        (language === "KOR" && "숫자만 입력해주세요.") ||
          (language === "ENG" && "Please only number")
      );
      return;
    }
    if (phoneOrMail.trim() === "") {
      toast.error("값을 입력해주세요.");
      return;
    }

    toast.success("회람자를 추가하였습니다.");
    setInputs({
      ...inputs,
      circulators: [
        ...inputs.circulators,
        select ? country + " " + phoneOrMail : phoneOrMail,
      ],
    });

    setSelect(true);
    setPhoneOrMail("");
    setCountry("+82");
  }, [select, phoneOrMail, country, inputs]);

  return (
    <Container>
      <RowBox>
        {/* <StyledSelect
          label="소속"
          labelFontSize={14}
          width={150}
          height={59}
          fontSize={18}
          options={["PM현장", "시공사", "발주처"]}
          value={group}
          placeholder="소속 선택"
          onChange={(_, value) => {
            setGroup(value);
          }}
        /> */}
        <ButtonWrapper>
          <Button select={!select} onClick={() => setSelect(false)}>
            {language === "KOR" && "이메일"}
            {language === "ENG" && "Email"}
          </Button>
          <Button select={select} onClick={() => setSelect(true)}>
            {language === "KOR" && "휴대폰번호"}
            {language === "ENG" && "Phone Number"}
          </Button>
        </ButtonWrapper>
      </RowBox>
      <RowBox>
        {select && (
          <StyledSelect
            label={
              (language === "KOR" && "국가") ||
              (language === "ENG" && "Country code")
            }
            labelFontSize={14}
            width={150}
            height={59}
            fontSize={18}
            options={nationList}
            value={country}
            onChange={(_, value) => {
              setCountry(value);
            }}
          />
        )}

        <StyledInput
          label={
            select ? (
              <>
                {language === "KOR" && "연락처로 추가하기*"}
                {language === "ENG" && "Add By phone number or Email*"}
              </>
            ) : (
              <>
                {language === "KOR" && "메일로 추가하기*"}
                {language === "ENG" && "Add By phone number or Email*"}
              </>
            )
          }
          name="phoneOrMail"
          value={select ? onlyNumber(phoneOrMail) : phoneOrMail}
          onChange={(e) => setPhoneOrMail(e.target.value)}
          PLUSBTN
          margin={!select && "3px 0 0 0"}
          width={select ? 220 : 390}
          handlePlusBtn={handlePlus}
        />
      </RowBox>
    </Container>
  );
};

export default CirculatorsInput;
