import React, {memo, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import {PieChart, Pie, Cell} from "recharts";
import {colors} from "../../../../../styles/colors";
import {AppContext} from "App";
import {StatsContext} from "routes/Main/ProjectDetail/Stats/StatsContainer";

const Wrapper = styled.div`
  width: 468px;
  height: 100%;
  margin-top: 26px;
`;

const Header = styled.div`
  color: ${({theme}) => theme.defaultFontColor};
  padding-bottom: 9px;
`;

const ChartBox = styled.div``;

const StatsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Stats = styled.div`
  margin-bottom: 16px;
`;

const Text = styled.div`
  white-space: nowrap;
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 400)};
  color: ${({fontColor, theme}) =>
          fontColor ? fontColor : theme.defaultFontColor};

  & + & {
    margin-top: 6px;
  }
`;

const RowBox = styled.div`
  display: flex;
  margin-top: 9px;
  background-color: ${colors.whiteColor};
`;

const CircleChart = ({}) => {
    const {language} = useContext(AppContext);
    const COLORS = [
        colors.pieChartBlueColor,
        colors.pieChartOrangeColor,
        colors.pieChartYellowColor,
    ];

    const {byStatus} = useContext(StatsContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (byStatus) {
            setData([
                {
                    name: language === "KOR" ? "최종완료" : "Closed",
                    value: byStatus.completeCount,
                    ratio: byStatus.completeRatio,
                },
                {
                    name: language === "KOR" ? "진행중" : "In Progress",
                    value: byStatus.inProgressCount,
                    ratio: byStatus.inProgressRatio,
                },
                {
                    name: language === "KOR" ? "지연" : "Delay",
                    value: byStatus.delayCount,
                    ratio: byStatus.delayRatio,
                },
            ]);
        }
    }, [byStatus]);

    return (
        <Wrapper>
            <Header>
                {language === "KOR" && "SCAR 처리 상태별"}
                {language === "ENG" && "SCAR Processing Status"}
            </Header>
            <RowBox>
                <ChartBox>
                    <PieChart width={256} height={256}>
                        <Pie
                            data={data}
                            cx={118}
                            cy={118}
                            innerRadius={83}
                            outerRadius={100}
                            paddingAngle={5}
                            dataKey="value"
                            startAngle={120}
                            endAngle={-360}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                            ))}
                        </Pie>
                    </PieChart>
                </ChartBox>
                <StatsBox>
                    {data.map((stats, index) => (
                        <Stats key={`${stats.name}-${stats.value}`}>
                            <Text>{stats.name}</Text>
                            <Text fontWeight={600} fontColor={COLORS[index]}>
                                <CountUp end={stats.ratio} duration={1}/>% ({stats.value} /{" "}
                                {byStatus?.totalCount})
                            </Text>
                        </Stats>
                    ))}
                </StatsBox>
            </RowBox>
        </Wrapper>
    );
};

export default  memo(CircleChart);
