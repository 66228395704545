import React, { memo } from "react";
import GuideHeader from "components/layout/GuideHeader";
import styled, { css } from "styled-components";
import ProfileCard from "components/feature/Main/ProjectDetail/ScarDetail/ProfileCard";
import { colors } from "styles/colors";
import StyledInput from "components/share/StyledInput";
import StyledSelect from "components/share/StyledSelect";
import redDelIcon from "assets/icon/redDelIcon.svg";
import StyledFileInput from "components/share/StyledFileInput";
import checkIcon from "assets/icon/checkIcon.svg";
import StyledButton from "components/share/StyledButton";
import SelectMember from "../../../../components/feature/Main/ProjectDetail/CreateScar/SelectMember";
import CirculatorsInput from "components/feature/Main/ProjectDetail/CreateScar/CirculatorsInput";
import RiskcodeModal from "components/feature/Main/ProjectDetail/CreateScar/RiskcodeModal";
import { FiChevronRight } from "react-icons/fi";
import LineSelectModal from "components/feature/Main/ProjectDetail/CreateScar/LineSelectModal";

const Container = styled.div`
  width: 1800px;
  min-height: calc(100vh - 154px - 81px - 32px);
  display: grid;
  grid-template-columns: 370px 454px 454px 370px;
  justify-content: space-between;
  padding: 50px 0 100px 0;
  margin: 0 auto;
`;

const Column = styled.div`
  width: 100%;
  margin: ${({ margin }) => margin};

  &:nth-child(3) {
    padding: 50px 0 0 0;
  }
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 26px;
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize}px;
  color: ${({ color }) => color};
`;

const WhiteBox = styled.div`
  height: ${({ height }) => height}px;
  padding: 26px 30px;
  margin: 16px 0 28px 0;
  background-color: ${colors.whiteColor};
`;

const LabelBox = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 16px;
  margin: 0 14px 16px 0;
  border-radius: 8px;
  border: 1px solid ${colors.lightgrayBorder};
`;

const LabelDelIcon = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  right: -3px;
  top: -5px;
  background-image: url(${redDelIcon});
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${colors.whiteColor};
  cursor: pointer;
`;

const LabelTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  overflow-y: auto;
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ SAVE }) =>
    SAVE &&
    css`
      justify-content: start;
      align-items: center;
      cursor: pointer;
    `}
`;

const DangerText = styled.div`
  font-size: 14px;
  color: ${colors.redFontColor};
  margin: 10px 0 14px 0;
`;

const CheckButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url(${checkIcon});
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: ${({ color }) => color};
  margin-right: 6px;
`;

const RiskCodeText = styled.div`
  font-size: 18px;
`;

const RiskCodeButton = styled.div`
  width: 188px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.styledInputBorder};
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: space-between;

    & > .icon {
      font-size: 24px;
    }
  }

  & > ${RiskCodeText}:first-child {
    font-size: 14px;
    color: ${colors.grayFontColor};
    margin-bottom: 18px;
  }
`;

const CreateScarPresenter = ({
  inputs,
  setInputs,
  handleInput,
  handleSelect,
  handleCreate,
  myProfile,
  labelList,
  selectLabel,
  deleteLabel,
  onChangeFile,
  imageThumbnail,
  deleteImageFile,
  memberList,
  deleteCirculator,
  riskcodeModal,
  setRiskcodeModal,
  selectLineModal,
  setSelectLinetModal,
  language,
}) => {
  return (
    <>
      <GuideHeader
        labelList={
          (language === "KOR" && [
            "프로젝트 목록",
            "프로젝트 상세",
            "SCAR 발행하기",
          ]) ||
          (language === "ENG" && [
            "Project List",
            "Project Detail",
            "Create SCAR",
          ])
        }
      />
      <Container>
        <Column margin="80px 0 0 0">
          <ProfileCard
            NOPATCH
            title={
              (language === "KOR" && "발행자") ||
              (language === "ENG" && "Creator")
            }
            profileImg={myProfile?.user_profileImg}
            group={myProfile?.user_group}
            name={myProfile?.user_name}
            rank={myProfile?.user_rank}
            phoneNumber={
              myProfile?.user_countryCode + " " + myProfile?.user_cellphone
            }
            mail={myProfile?.user_email}
          />
        </Column>
        <Column>
          <TitleText>
            {language === "KOR" && "SCAR 발행하기"}
            {language === "ENG" && "Create SCAR"}
          </TitleText>
          <Text>
            {language === "KOR" && "SCAR 발행 정보 입력"}
            {language === "ENG" && "Enter SCAR issue information"}
          </Text>
          <WhiteBox height={704}>
            <StyledInput
              name="content"
              label={`${
                (language === "KOR" && "위반사항") ||
                (language === "ENG" && "Violation Description")
              }* (${inputs.content.length} / 80)`}
              placeholder={
                (language === "KOR" && "위반사항 입력") ||
                (language === "ENG" && "Enter Violation Description")
              }
              value={inputs.content}
              onChange={handleInput}
              maxLength={79}
            />
            <StyledInput
              name="position"
              label={`${
                (language === "KOR" && "위반위치") ||
                (language === "ENG" && "Location")
              } (${inputs.position.length} / 80)`}
              placeholder={
                (language === "KOR" && "위반위치 입력") ||
                (language === "ENG" && "Enter Violation Locationn")
              }
              value={inputs.position}
              onChange={handleInput}
              margin="14px 0"
              maxLength={79}
            />
            <StyledSelect
              name="label"
              label={
                (language === "KOR" && "라벨*") ||
                (language === "ENG" && "Label*")
              }
              placeholder={
                (language === "KOR" && "라벨 선택하기") ||
                (language === "ENG" && "Select Label")
              }
              fontSize={18}
              height={59}
              labelFontSize={14}
              value={inputs.label}
              onChange={selectLabel}
              options={labelList}
            />
            <LabelTagWrapper>
              {inputs.labelList.map((lb, index) => (
                <LabelBox key={index}>
                  {lb}
                  <LabelDelIcon onClick={() => deleteLabel(lb)} />
                </LabelBox>
              ))}
            </LabelTagWrapper>
            <RowBox>
              <StyledSelect
                name="grade"
                label={
                  (language === "KOR" && "위험등급*") ||
                  (language === "ENG" && "Risk Impact*")
                }
                width={186}
                fontSize={18}
                height={59}
                value={inputs.grade}
                onChange={handleSelect}
                labelFontSize={14}
                options={
                  (language === "KOR" && ["상", "중", "하"]) ||
                  (language === "ENG" && ["High", "Medium", "Low"])
                }
                placeholder={
                  (language === "KOR" && "선택") ||
                  (language === "ENG" && "Select")
                }
              />
              <RiskCodeButton onClick={() => setRiskcodeModal(true)}>
                <RiskCodeText>
                  {language === "KOR" && "리스크코드"}
                  {language === "ENG" && "Risk Code"}
                </RiskCodeText>
                <div>
                  <RiskCodeText>
                    {inputs.riskCode === "" ? (
                      <>
                        {language === "KOR" && "선택하기"}
                        {language === "ENG" && "Select"}
                      </>
                    ) : (
                      inputs.riskCode
                    )}
                  </RiskCodeText>
                  <FiChevronRight className="icon" />
                </div>
              </RiskCodeButton>
            </RowBox>
            <DangerText>
              {inputs.grade === "상" || inputs.grade === "High" ? (
                <>
                  {language === "KOR" && "발행일 자정까지 조치"}
                  {language === "ENG" &&
                    "Action by midnight on the date of issue"}
                </>
              ) : inputs.grade === "중" || inputs.grade === "Medium" ? (
                <>
                  {language === "KOR" && "발행일 익일 자정까지 조치"}
                  {language === "ENG" &&
                    "Action by midnight the day after issue"}
                </>
              ) : (
                <>
                  {language === "KOR" && "발행일 4일 후 자정까지 조치"}
                  {language === "ENG" &&
                    "Action taken until midnight four days after the date of issue"}
                </>
              )}
            </DangerText>
            <StyledFileInput
              multiple
              name="image"
              onChange={onChangeFile}
              image={imageThumbnail}
              delClick={deleteImageFile}
              label={
                (language === "KOR" && "사진*") ||
                (language === "ENG" && "Image*")
              }
              guideLabel={
                (language === "KOR" &&
                  "최대 5장의 사진을 업로드할 수 있습니다.") ||
                (language === "ENG" && "Up to five photos can be uploaded.")
              }
            />
          </WhiteBox>
        </Column>
        <Column>
          <Text>
            {language === "KOR" && "결재라인 설정"}
            {language === "ENG" && "Set Approval Process"}
          </Text>
          <WhiteBox height={1024}>
            <StyledSelect
              name="line"
              label={
                (language === "Kor" && "결재라인설정하기") ||
                (language === "ENG" && "Set Approval Process")
              }
              options={
                (language === "KOR" && ["직접입력하기", "불러오기"]) ||
                (language === "ENG" && ["Select", "Load"])
              }
              fontSize={18}
              value={inputs.line}
              onChange={handleSelect}
            />
            <SelectMember
              name="create"
              label={
                (language === "KOR" && "발행자") ||
                (language === "ENG" && "Creator")
              }
              value={inputs.create}
              onChange={handleSelect}
              placeholder={
                (language === "KOR" && "발행자 선택하기") ||
                (language === "ENG" && "Select Creator")
              }
              NOARROW
            />
            <SelectMember
              name="check"
              label={
                (language === "KOR" && "발행확인자") ||
                (language === "ENG" && "Resident Engineer (PM)")
              }
              value={inputs.check}
              onChange={handleSelect}
              placeholder={
                (language === "KOR" && "발행확인자 선택하기") ||
                (language === "ENG" && "Select Resident Engineer (PM)")
              }
              options={memberList}
            />
            <SelectMember
              name="action"
              label={
                (language === "KOR" && "조치자") ||
                (language === "ENG" && "Safety Officer")
              }
              value={inputs.action}
              onChange={handleSelect}
              placeholder={
                (language === "KOR" && "조치자 선택하기") ||
                (language === "ENG" && "Select Safety Officer")
              }
              options={memberList}
            />
            <SelectMember
              name="manager"
              label={
                (language === "KOR" && "책임자") ||
                (language === "ENG" && "Safety Manager")
              }
              value={inputs.manager}
              onChange={handleSelect}
              placeholder={
                (language === "KOR" && "책임자 선택하기") ||
                (language === "ENG" && "Select Safety Manager")
              }
              options={memberList}
            />
            <SelectMember
              name="actionCheck"
              label={
                (language === "KOR" && "조치확인자") ||
                (language === "ENG" && "HSE Officer (PM)")
              }
              value={inputs.actionCheck}
              onChange={handleSelect}
              placeholder={
                (language === "KOR" && "조치확인자 선택하기") ||
                (language === "ENG" && "Select HSE Officer (PM)")
              }
              options={memberList}
            />
            <SelectMember
              name="finalSign"
              label={
                (language === "KOR" && "최종승인자") ||
                (language === "ENG" && "Resident Engineer")
              }
              value={inputs.finalSign}
              onChange={handleSelect}
              placeholder={
                (language === "KOR" && "최종승인자 선택하기") ||
                (language === "ENG" && "Select Resident Engineer")
              }
              options={memberList}
            />
            <Text fontSize={18}>
              {language === "KOR" && "회람자"}
              {language === "ENG" && "Distribution"}
            </Text>
            <CirculatorsInput setInputs={setInputs} inputs={inputs} />
            <LabelTagWrapper>
              {inputs?.circulators.map((circulator, index) => (
                <LabelBox key={index}>
                  {circulator}
                  <LabelDelIcon onClick={() => deleteCirculator(circulator)} />
                </LabelBox>
              ))}
            </LabelTagWrapper>
            <Text fontSize={18}>
              {language === "KOR" && "결제라인 이름"}
              {language === "ENG" && "Approval Process Name"}
            </Text>
            <StyledInput
              name="name"
              placeholder={
                (language === "KOR" && "결제라인 이름 입력") ||
                (language === "ENG" && "Enter Approval Process Name")
              }
              value={inputs.name}
              onChange={handleInput}
              margin="14px 0"
              maxLength={99}
            />
          </WhiteBox>
          <RowBox
            SAVE
            onClick={() => setInputs({ ...inputs, save: !inputs.save })}
          >
            <CheckButton
              color={inputs.save ? colors.mainColor : colors.scrollColor}
            />
            <Text color={inputs.save ? colors.mainColor : colors.grayFontColor}>
              {language === "KOR" && "위 결재 라인을 저장합니다."}
              {language === "ENG" && "Save the above process"}
            </Text>
          </RowBox>
          <StyledButton
            label={
              (language === "KOR" && "SCAR 발행하기") ||
              (language === "ENG" && "Create SCAR")
            }
            width={453}
            height={48}
            fontSize={18}
            CREATEICON
            margin="29px 0 0 0"
            onClick={handleCreate}
          />
        </Column>
      </Container>

      {riskcodeModal && (
        <RiskcodeModal
          language={language}
          handleModal={() => setRiskcodeModal(false)}
          setInputs={setInputs}
          inputs={inputs}
        />
      )}

      {selectLineModal && (
        <LineSelectModal
          handleModal={() => setSelectLinetModal(false)}
          inputs={inputs}
          setInputs={setInputs}
        />
      )}
    </>
  );
};

export default memo(CreateScarPresenter);
