import React, {useCallback, useContext, useState} from "react";
import styled from "styled-components";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {colors} from "../../../styles/colors";
import {AppContext} from "../../../App";
import {CREATE_CHECK_ITEM_EVALUATE, EDIT_CHECK_LIST_ITEM_COMMENT} from "../../../graphql/CheckList/mutation";

const Wrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.lightgrayBorder};
  border-top: 1px solid ${({top}) => top ? `${colors.blackColor}` : `${colors.lightgrayBorder7}`};
`;
const Title = styled.div`
  font-size: ${({fontSize}) => (fontSize ? fontSize : 18)}px;
  font-weight: ${({BOLD}) => BOLD && "bold"};
  color: ${({color}) => color};
  line-height: 1.2;
  cursor: ${({POINTER}) => POINTER && "pointer"};
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-right: 12px;
  word-break: keep-all;
  flex: 1;
`;
const NumberBtn = styled.div`
  width: 60px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({click}) => click ? `${colors.whiteColor}` : `${colors.grayFontColor}`};
  background-color: ${({click}) => click ? `${colors.mainColor}` : `${colors.defaultBackgroundColor}`};
  cursor: ${({ cursor }) => cursor ? cursor : 'none'};
`;
const RowBox = styled.div`
  display: flex;
  margin-bottom: 12px;

  & > ${NumberBtn} + ${NumberBtn} {
    margin-left: 40px;
  }
`;
const WarningText = styled.div`
  height: 18px;
  color: ${colors.redFontColor};
  font-family: "AppleSDGothicNeoMedium";
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  margin: 12px 0 -10px;
`;
const Input = styled.input`
  height: 50px;
  flex: 1;
  padding: 0 14px;
  border-radius: 4px;
  border: 1px solid ${colors.lightgrayBorder};
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'row'};
`;
const SaveButton = styled.button`
  width: 60px;
  height: 50px;
  color: ${colors.whiteColor};
  margin-left: 16px;
  border-radius: 4px;
  background-color: ${colors.mainColor};
`;

const CheckItem = ({title = '', top, item, refetch, status}) => {
    const {language} = useContext(AppContext);
    const [score, setScore] = useState(item.wci_score || null);
    const [comment, setComment] = useState(item?.wci_comment || '');

    const evaluationPermission = parseInt(localStorage.getItem('evaluationPermission'), 10);

    const [createCheckItemEvaluate] = useMutation(CREATE_CHECK_ITEM_EVALUATE);
    const [editCheckListItemComment] = useMutation(EDIT_CHECK_LIST_ITEM_COMMENT);

    // 점수 평가
    const handleCheckItemEvaluate = useCallback(async score => {
        if (evaluationPermission !== 1 || status === 'complete') return;
        try {
            const {data} = await createCheckItemEvaluate({
                variables: {
                    wciId: item.wci_id,
                    wciScore: score,
                    wciRepeatedViolation: (item?.lastWeekScore < 3) && (score < 3)
                }
            });

            if (data?.createCheckItemEvaluate?.result) {
                toast.success(
                    (language === 'KOR' && '평가 점수를 저장했습니다.') ||
                    (language === 'ENG' && 'Evaluation score has been saved')
                );
                await refetch();
                setScore(score);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [item, evaluationPermission]);

    // 의견 입력
    const handleCheckListItemComment = useCallback(async () => {
        try {
            const {data} = await editCheckListItemComment({
                variables: {
                    wciId: item.wci_id,
                    wciComment: comment,
                }
            });

            if (data?.editCheckListItemComment) {
                toast.success((language === 'KOR' && '의견을 저장했습니다.') || (language === 'ENG' && 'Saved your comments'));
                await refetch();
            }
        } catch (e) {
            alert(e.message);
        }
    }, [comment]);

    return (
        <Wrapper top={top}>
            <Title>{title}</Title>
            <FlexBox flexDirection='column'>
                <RowBox>
                    <NumberBtn
                        click={score === 0}
                        onClick={() => handleCheckItemEvaluate(0)}
                        cursor={evaluationPermission === 1 ? 'pointer' : 'none'}
                    >
                        0
                    </NumberBtn>
                    <NumberBtn
                        click={score === 1}
                        onClick={() => handleCheckItemEvaluate(1)}
                        cursor={evaluationPermission === 1 ? 'pointer' : 'auto'}
                    >
                        1
                    </NumberBtn>
                    <NumberBtn
                        click={score === 2}
                        onClick={() => handleCheckItemEvaluate(2)}
                        cursor={evaluationPermission === 1 ? 'pointer' : 'auto'}
                    >
                        2
                    </NumberBtn>
                    <NumberBtn
                        click={score === 3}
                        onClick={() => handleCheckItemEvaluate(3)}
                        cursor={evaluationPermission === 1 ? 'pointer' : 'auto'}
                    >
                        3
                    </NumberBtn>
                    <NumberBtn
                        click={score === 999}
                        onClick={() => handleCheckItemEvaluate(999)}
                        cursor={evaluationPermission === 1 ? 'pointer' : 'auto'}
                    >
                        N/A</NumberBtn>
                </RowBox>
                <FlexBox>
                    <Input
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        placeholder={
                            (language === 'KOR' && '의견을 입력해주세요.') ||
                            (language === 'ENG' && 'Please enter your comments')
                        }
                        disabled={(evaluationPermission !== 1) || (status !== 'inProgress')}
                    />
                    {((evaluationPermission === 1) && status === 'inProgress') && (
                        <SaveButton onClick={handleCheckListItemComment}>
                            {(language === 'KOR' && '저장') || (language === 'ENG' && 'Save')}
                        </SaveButton>
                    )}
                </FlexBox>
                {((item?.lastWeekScore) < 3 && (score < 3) && (score !== null)) && (
                    <WarningText>
                        {language === 'KOR' && '반복위반'}
                        {language === 'ENG' && 'Repeat '}
                    </WarningText>
                )}
            </FlexBox>
        </Wrapper>
    )
}

export default React.memo(CheckItem);