import React, { useContext } from "react";
import GuidePresenter from "./GuidePresenter";
import { AppContext } from "App";

const GuideContainer = () => {
  const { language } = useContext(AppContext);
  return <GuidePresenter language={language} />;
};

export default GuideContainer;
