import { gql } from "@apollo/client";

export const SEE_SCAR_LABEL_LIST = gql`
  query seeScarLabelList($lb_language: String) {
    seeScarLabelList(lb_language: $lb_language) {
      result
      error
      labelList {
        lb_id
        lb_name
      }
    }
  }
`;

export const SEE_SIGN_LINE_HISTORY = gql`
  query seeSignLineHistory($prj_id: Int) {
    seeSignLineHistory(prj_id: $prj_id) {
      result
      error
      signLine {
        sl_id
        createdAt
        create_id
        create_group
        create_name
        create_rank
        create_countryCode
        create_cellphone
        create_email
        create_img
        create_status
        create_rejectStatus
        create_rejectContent
        check_id
        check_group
        check_name
        check_rank
        check_img
        check_countryCode
        check_cellphone
        check_email
        check_status
        check_rejectStatus
        check_rejectContent
        action_id
        action_group
        action_name
        action_rank
        action_img
        action_countryCode
        action_cellphone
        action_email
        action_status
        action_rejectStatus
        action_rejectContent
        manager_id
        manager_group
        manager_name
        manager_rank
        manager_img
        manager_countryCode
        manager_cellphone
        manager_email
        manager_status
        manager_rejectStatus
        manager_rejectContent
        actionCheck_id
        actionCheck_group
        actionCheck_name
        actionCheck_rank
        actionCheck_img
        actionCheck_countryCode
        actionCheck_cellphone
        actionCheck_email
        actionCheck_status
        actionCheck_rejectStatus
        actionCheck_rejectContent
        finalSign_id
        finalSign_group
        finalSign_name
        finalSign_rank
        finalSign_img
        finalSign_countryCode
        finalSign_cellphone
        finalSign_email
        finalSign_status
        finalSign_rejectStatus
        finalSign_rejectContent
        circulators {
          circ_id
          circ_cellphone
          circ_email
        }
      }
    }
  }
`;

export const SEARCH_RISK_CODE = gql`
  query searchRiskCode($riskCodeKeyword: String) {
    searchRiskCode(riskCodeKeyword: $riskCodeKeyword) {
      result
      error
      riskCodeList {
        rc_id
        rc_name
        rc_title
        rc_text
      }
    }
  }
`;

export const SEARCH_RISK_CODE_V2 = gql`
  query searchRiskCode_v2($riskCodeKeyword: String) {
    searchRiskCode_v2(riskCodeKeyword: $riskCodeKeyword) {
      result
      checkItemList {
        cli_id
        cli_riskCode
        cli_safetyCategory_kr
        cli_safetyCategory_eng
        cli_checkItem_kr
        cli_checkItem_eng
      }
    }
  }
`;

export const SEE_SAVE_SIGN_LINE = gql`
  query seeSaveSignLine($prjId: Int) {
    seeSaveSignLine(prj_id: $prjId) {
      result
      signLine {
        ssl_id
        name
        createdAt
        create_id
        create_group
        create_name
        create_rank
        create_countryCode
        create_cellphone
        create_email
        create_img
        create_status
        create_rejectStatus
        create_rejectContent
        check_id
        check_group
        check_name
        check_rank
        check_img
        check_countryCode
        check_cellphone
        check_email
        check_status
        check_rejectStatus
        check_rejectContent
        action_id
        action_group
        action_name
        action_rank
        action_img
        action_countryCode
        action_cellphone
        action_email
        action_status
        action_rejectStatus
        action_rejectContent
        manager_id
        manager_group
        manager_name
        manager_rank
        manager_img
        manager_countryCode
        manager_cellphone
        manager_email
        manager_status
        manager_rejectStatus
        manager_rejectContent
        actionCheck_id
        actionCheck_group
        actionCheck_name
        actionCheck_rank
        actionCheck_img
        actionCheck_countryCode
        actionCheck_cellphone
        actionCheck_email
        actionCheck_status
        actionCheck_rejectStatus
        actionCheck_rejectContent
        finalSign_id
        finalSign_group
        finalSign_name
        finalSign_rank
        finalSign_img
        finalSign_countryCode
        finalSign_cellphone
        finalSign_email
        finalSign_status
        finalSign_rejectStatus
        finalSign_rejectContent
        saveCirculator {
          circ_id
          circ_cellphone
          circ_email
        }
      }
    }
  }
`;
