import React, {useCallback, useContext, useRef, useState} from "react";
import {MainContext} from "routes/Main/Main/MainContainer";
import styled, {css} from "styled-components";
import {colors} from "styles/colors";
import resetIcon from "assets/icon/resetIcon.svg";
import StyledInput from "components/share/StyledInput";
import StyledButton from "components/share/StyledButton";
import {useNavigate} from "react-router-dom";
import Date_Range_Picker from "./Date_Range_Picker";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const WhiteBox = styled.div`
  width: 380px;
  height: 384px;
  padding: 26px 30px;
  margin: 135px 0 30px 0;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const TitleText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({color}) => color};
`;
const ResetButton = styled.div`
  font-size: 14px;
  color: ${colors.grayFontColor};
  cursor: pointer;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({padding}) => padding};
  margin: ${({margin}) => margin};
  border-bottom: ${({BORDER}) =>
          BORDER && `1px solid ${colors.lightgrayBorder}`};
`;

const SelectTitle = styled.div`
  width: 50px;
  flex-shrink: 0;
`;

const ResetIcon = styled.img`
  margin: 0 11px 0 auto;
  cursor: pointer;

  &.rotate {
    transition: transform 1s;
    transform: rotate(-360deg);
  }
`;

const SelectButton = styled.button`
  height: 29px;
  padding: 0 12px;
  white-space: nowrap;
  border-radius: ${({SORT}) => (SORT ? 15 : 8)}px;
  color: ${colors.grayFontColor};
  font-weight: ${({selected}) => selected && "bold"};
  border: 1px solid ${colors.lightgrayBorder};
  color: ${({selected}) => selected && `${colors.whiteColor}`};
  background-color: ${({selected}) =>
          selected ? `${colors.mainColor}` : `${colors.lightgrayBackgroundColor}`};
  margin-left: 3px;
`;

const SearchProject = ({language}) => {
    const icon = useRef();
    const {inputs, setInputs, handleReset, myProfile} = useContext(MainContext);
    const [rotateClass, setRotateClass] = useState(false);
    const navigate = useNavigate();

    const rotateIcon = (e) => {
        handleReset();
        e.preventDefault();
        setRotateClass(true);
        setTimeout(() => setRotateClass(false), [1000]);
    };

    const onChangeSearchTerm = useCallback(
        (e) => {
            setInputs({...inputs, searchTerm: e.target.value});
        },
        [inputs]
    );

    const onChangeState = useCallback(
        (state) => {
            setInputs({...inputs, state: state});
        },
        [inputs]
    );

    const onChangeSort = useCallback(
        (sort) => {
            setInputs({...inputs, sort: sort});
        },
        [inputs]
    );

    return (
        <Container>
            <WhiteBox>
                <RowBox>
                    <TitleText>
                        {language === "KOR" && "프로젝트 검색 조건"}
                        {language === "ENG" && "Search Project"}
                    </TitleText>
                    <ResetIcon
                        src={resetIcon}
                        ref={icon}
                        onClick={rotateIcon}
                        className={rotateClass && "rotate"}
                    />
                    <ResetButton onClick={rotateIcon}>
                        {language === "KOR" && "초기화"}
                        {language === "ENG" && "Reset"}
                    </ResetButton>
                </RowBox>
                <StyledInput
                    SEARCHICON
                    placeholder={
                        (language === "KOR" && "검색하기") ||
                        (language === "ENG" && "search")
                    }
                    value={inputs.searchTerm}
                    onChange={onChangeSearchTerm}
                />
                <RowBox padding="25px 0" BORDER>
                    <SelectTitle>
                        {language === "KOR" && "상태"}
                        {language === "ENG" && "State"}
                    </SelectTitle>
                    <SelectButton
                        selected={inputs.state === "전체"}
                        onClick={() => onChangeState("전체")}
                    >
                        {language === "KOR" && "전체"}
                        {language === "ENG" && "All"}
                    </SelectButton>
                    <SelectButton
                        selected={inputs.state === "진행중"}
                        onClick={() => onChangeState("진행중")}
                    >
                        {language === "KOR" && "진행중"}
                        {language === "ENG" && "Ongoing"}
                    </SelectButton>
                    <SelectButton
                        selected={inputs.state === "종료예정"}
                        onClick={() => onChangeState("종료예정")}
                    >
                        {language === "KOR" && "종료예정"}
                        {language === "ENG" && "Closing"}
                    </SelectButton>
                    <SelectButton
                        selected={inputs.state === "종료"}
                        onClick={() => onChangeState("종료")}
                    >
                        {language === "KOR" && "종료"}
                        {language === "ENG" && "Closed"}
                    </SelectButton>
                </RowBox>
                <RowBox padding="20px 0 0 0">
                    <SelectTitle>
                        {language === "KOR" && "기간"}
                        {language === "ENG" && "Period"}
                    </SelectTitle>
                    <Date_Range_Picker/>
                </RowBox>
                <RowBox padding="20px 0">
                    <SelectTitle>
                        {language === "KOR" && "정렬"}
                        {language === "ENG" && "Sort"}
                    </SelectTitle>
                    <SelectButton
                        SORT
                        selected={inputs.sort === "내림차순"}
                        onClick={() => onChangeSort("내림차순")}
                    >
                        {language === "KOR" && "내림차순"}
                        {language === "ENG" && "desc"}
                    </SelectButton>
                    <SelectButton
                        SORT
                        selected={inputs.sort === "오름차순"}
                        onClick={() => onChangeSort("오름차순")}
                    >
                        {language === "KOR" && "오름차순"}
                        {language === "ENG" && "asc"}
                    </SelectButton>
                </RowBox>
            </WhiteBox>
            {myProfile?.user_group === "head" && (
                <StyledButton
                    label={
                        (language === "KOR" && "프로젝트 생성하기") ||
                        (language === "ENG" && "Create Project")
                    }
                    width={380}
                    height={48}
                    fontSize={18}
                    CREATEICON
                    // margin="26px 0 0 0"
                    onClick={() => navigate("/createProject")}
                />
            )}
        </Container>
    );
};

export default SearchProject;
