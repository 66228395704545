import React, { useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import { SEE_SAVE_SIGN_LINE } from "graphql/Main/ProjectDetail/CreateScar/query";
import { useMutation, useQuery } from "@apollo/client";
import StyledButton from "components/share/StyledButton";
import emptyProfile from "assets/icon/emptyProfile.svg";
import checkIcon from "assets/icon/checkIcon.svg";
import { groupChanger } from "utils/common";
import { AppContext } from "App";
import { toast } from "react-toastify";
import { DELETE_SAVE_SIGN_LINE } from "graphql/Main/ProjectDetail/CreateScar/mutation";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 661px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const LineWrapper = styled.div`
  flex: 1;
  margin: 20px 0;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.scrollColor};
  }
`;

const LineBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  flex-shrink: 0;
  ${({ width }) =>
    width &&
    css`
      width: ${({ width }) => width}px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;

const RowBox = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileRowBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  & > ${Text} + ${Text} {
    margin: 0 5px;
  }
`;

const ProfileImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-size: ${({ src }) => (src === "" ? "40%" : "cover")};
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 10px;
  flex-shrink: 0;
`;

const RadioButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url(${checkIcon});
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${({ active }) =>
    active ? `${colors.greenColor}` : `${colors.grayFontColor}`};
  margin-right: 10px;
  cursor: pointer;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: end;
`;

const LineSelectModal = ({ handleModal, inputs, setInputs }) => {
  const { language } = useContext(AppContext);
  const { data: signLineData, refetch: refetSignLineData } = useQuery(
    SEE_SAVE_SIGN_LINE,
    {
      variables: {
        prj_id: Number(localStorage.getItem("prjId")),
      },
      fetchPolicy: "network-only",
    }
  );

  const [deleteSaveSignLine] = useMutation(DELETE_SAVE_SIGN_LINE);

  const getSignLineName = useCallback(
    (name) => {
      if (name) return name;
      if (language === "KOR") return `라인`;
      return `Line`;
    },
    [language, signLineData]
  );

  const handleRemove = useCallback(async () => {
    if (inputs.selectLine === null) {
      toast.error(
        (language === "KOR" && "삭제할 결제 라인을 선택해주세요.") ||
          (language === "ENG" && "Please select sign line.")
      );
      return;
    }
    const { ssl_id: sslId } = signLineData?.seeSaveSignLine?.signLine.find(
      (line) => line.ssl_id === inputs.selectLine
    );

    try {
      const { data } = await deleteSaveSignLine({
        variables: {
          sslId,
        },
      });

      if (inputs.selectLine === sslId) {
        setInputs({
          ...inputs,
          create: null,
          check: null,
          action: null,
          manager: null,
          actionCheck: null,
          finalSign: null,
          circulators: [],
        });
      }

      if (data?.deleteSaveSignLine?.result) {
        toast.info(
          (language === "KOR" && "결제 라인 삭제 성공!") ||
            (language === "ENG" && "Success Delete Sign Line!")
        );

        refetSignLineData();
      }
    } catch (e) {
      console.log(e);
      toast.error(
        (language === "KOR" && "결제 라인 삭제 실패!") ||
          (language === "ENG" && "Fail Delete Sign Line!")
      );
    }
  }, [
    deleteSaveSignLine,
    inputs,
    language,
    refetSignLineData,
    setInputs,
    signLineData?.seeSaveSignLine?.signLine,
  ]);

  const handleSave = useCallback(() => {
    if (inputs.selectLine === null) {
      handleModal();
      return;
    }
    const line = signLineData?.seeSaveSignLine?.signLine.find(
      (line) => line.ssl_id === inputs.selectLine
    );
    setInputs({
      ...inputs,
      create: {
        id: line?.create_id,
        group: line?.create_group,
        name: line?.create_name,
        rank: line?.create_rank,
        countryCode: line?.create_countryCode,
        cellphone: line?.create_cellphone,
        email: line?.create_email,
        img: line?.create_img,
      },
      check: {
        id: line?.check_id,
        group: line?.check_group,
        name: line?.check_name,
        rank: line?.check_rank,
        countryCode: line?.check_countryCode,
        cellphone: line?.check_cellphone,
        email: line?.check_email,
        img: line?.check_img,
      },
      action: {
        id: line?.action_id,
        group: line?.action_group,
        name: line?.action_name,
        rank: line?.action_rank,
        countryCode: line?.action_countryCode,
        cellphone: line?.action_cellphone,
        email: line?.action_email,
        img: line?.action_img,
      },
      manager: {
        id: line?.manager_id,
        group: line?.manager_group,
        name: line?.manager_name,
        rank: line?.manager_rank,
        countryCode: line?.manager_countryCode,
        cellphone: line?.manager_cellphone,
        email: line?.manager_email,
        img: line?.manager_img,
      },
      actionCheck: {
        id: line?.actionCheck_id,
        group: line?.actionCheck_group,
        name: line?.actionCheck_name,
        rank: line?.actionCheck_rank,
        countryCode: line?.actionCheck_countryCode,
        cellphone: line?.actionCheck_cellphone,
        email: line?.actionCheck_email,
        img: line?.actionCheck_img,
      },
      finalSign: {
        id: line?.finalSign_id,
        group: line?.finalSign_group,
        name: line?.finalSign_name,
        rank: line?.finalSign_rank,
        countryCode: line?.finalSign_countryCode,
        cellphone: line?.finalSign_cellphone,
        email: line?.finalSign_email,
        img: line?.finalSign_img,
      },
      circulators: [
        ...line?.saveCirculator?.map(
          (circ) => circ?.circ_cellphone + circ?.circ_email
        ),
      ],
    });
    handleModal();
  }, [inputs]);

  React.useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text BOLD>
          {language === "KOR" && "결재라인 선택하기"}
          {language === "ENG" && "Select approval line"}
        </Text>
        <FiX className="closeIcon" onClick={() => handleModal()} />
        <LineWrapper>
          {signLineData?.seeSaveSignLine?.signLine?.length === 0 && (
            <div>
              {language === "KOR" && "저장된 결재라인이 없습니다."}{" "}
              {language === "ENG" && "No approval lines saved."}
            </div>
          )}
          {signLineData?.seeSaveSignLine?.signLine?.map((line, index) => (
            <LineBox
              key={index}
              onClick={() => setInputs({ ...inputs, selectLine: line.ssl_id })}
            >
              <RowBox>
                <RadioButton active={inputs.selectLine === line.ssl_id} />
                <Text margin="20px 0" BOLD>
                  {getSignLineName(line?.name?.trim())}
                </Text>
              </RowBox>

              <Text margin="0 0 20px 20px" color={colors.grayFontColor}>
                {language === "KOR" && "발행자"}
                {language === "ENG" && "Creator"}
              </Text>
              <ProfileRowBox>
                <ProfileImage src={line.create_img} />
                <Text width={60}>
                  {groupChanger(line?.create_group, language)}
                </Text>
                <Text BOLD width={250}>
                  {line?.create_name.length > 5
                    ? line?.create_name.substring(0, 5) + "..."
                    : line?.create_name}{" "}
                  {line?.create_rank.length > 10
                    ? line?.create_rank.substring(0, 10) + "..."
                    : line?.create_rank}
                </Text>
              </ProfileRowBox>

              <Text margin="20px 0 20px 20px" color={colors.grayFontColor}>
                {language === "KOR" && "발행확인자"}
                {language === "ENG" && "Resident Engineer (PM)"}
              </Text>
              <ProfileRowBox>
                <ProfileImage src={line.check_img} />
                <Text width={60}>
                  {groupChanger(line?.check_group, language)}
                </Text>
                <Text BOLD width={250}>
                  {line?.check_name.length > 5
                    ? line?.check_name.substring(0, 5) + "..."
                    : line?.check_name}{" "}
                  {line?.check_rank.length > 10
                    ? line?.check_rank.substring(0, 10) + "..."
                    : line?.check_rank}
                </Text>
              </ProfileRowBox>

              <Text margin="20px 0 20px 20px" color={colors.grayFontColor}>
                {language === "KOR" && "조치자"}
                {language === "ENG" && "Safety Officer"}
              </Text>
              <ProfileRowBox>
                <ProfileImage src={line.action_img} />
                <Text width={60}>
                  {groupChanger(line?.action_group, language)}
                </Text>
                <Text BOLD width={250}>
                  {line?.action_name.length > 5
                    ? line?.action_name.substring(0, 5) + "..."
                    : line?.action_name}{" "}
                  {line?.action_rank.length > 10
                    ? line?.action_rank.substring(0, 10) + "..."
                    : line?.action_rank}
                </Text>
              </ProfileRowBox>

              <Text margin="20px 0 20px 20px" color={colors.grayFontColor}>
                {language === "KOR" && "책임자"}
                {language === "ENG" && "Safety Manager"}
              </Text>
              <ProfileRowBox>
                <ProfileImage src={line.manager_img} />
                <Text width={60}>
                  {groupChanger(line?.manager_group, language)}
                </Text>
                <Text BOLD width={250}>
                  {line?.manager_name.length > 5
                    ? line?.manager_name.substring(0, 5) + "..."
                    : line?.manager_name}{" "}
                  {line?.manager_rank.length > 10
                    ? line?.manager_rank.substring(0, 10) + "..."
                    : line?.manager_rank}
                </Text>
              </ProfileRowBox>

              <Text margin="20px 0 20px  20px" color={colors.grayFontColor}>
                {language === "KOR" && "조치확인자"}
                {language === "ENG" && "HSE Officer (PM)"}
              </Text>
              <ProfileRowBox>
                <ProfileImage src={line.actionCheck_img} />
                <Text width={60}>
                  {groupChanger(line?.actionCheck_group, language)}
                </Text>
                <Text BOLD width={250}>
                  {line?.actionCheck_name.length > 5
                    ? line?.actionCheck_name.substring(0, 5) + "..."
                    : line?.actionCheck_name}{" "}
                  {line?.actionCheck_rank.length > 10
                    ? line?.actionCheck_rank.substring(0, 10) + "..."
                    : line?.actionCheck_rank}
                </Text>
              </ProfileRowBox>

              <Text margin="20px 0 20px 20px" color={colors.grayFontColor}>
                {language === "KOR" && "최종승인자"}
                {language === "ENG" && "Resident Engineer"}
              </Text>
              <ProfileRowBox>
                <ProfileImage src={line.finalSign_img} />
                <Text width={60}>
                  {groupChanger(line?.finalSign_group, language)}
                </Text>
                <Text BOLD width={250}>
                  {line?.finalSign_name.length > 5
                    ? line?.finalSign_name.substring(0, 5) + "..."
                    : line?.finalSign_name}{" "}
                  {line?.finalSign_rank.length > 10
                    ? line?.finalSign_rank.substring(0, 10) + "..."
                    : line?.finalSign_rank}
                </Text>
              </ProfileRowBox>

              <Text margin="20px 0 20px 20px" color={colors.grayFontColor}>
                {language === "KOR" && "회람자"}
                {language === "ENG" && "Distribution"}
              </Text>
              {line?.circulators?.length === 0 && (
                <Text key={index} margin="0 0 10px 20px">
                  -
                </Text>
              )}
              {line?.circulators?.map((circulator, index) => (
                <Text key={index} margin="0 0 10px 20px" width={350}>
                  {circulator?.circ_email + circulator?.circ_cellphone}{" "}
                </Text>
              ))}
            </LineBox>
          ))}
        </LineWrapper>
        <ButtonBox>
          <StyledButton
            width={language === "ENG" ? 100 : 74}
            height={46}
            label={
              (language === "KOR" && "삭제") || (language === "ENG" && "Delete")
            }
            margin="0"
            fontSize={16}
            bgColor="#dc3545"
            onClick={() => handleRemove()}
          />
          <StyledButton
            width={language === "ENG" ? 100 : 74}
            height={46}
            label={
              (language === "KOR" && "완료") ||
              (language === "ENG" && "Complete")
            }
            margin="0 0 0 12px"
            fontSize={16}
            onClick={() => handleSave()}
          />
        </ButtonBox>
      </ModalContainer>
    </Container>
  );
};

export default LineSelectModal;
