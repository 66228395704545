import React, { useCallback, useContext, useState, memo } from "react";
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";
import StyledButton from "../../../../share/StyledButton";
import StyledInput from "../../../../share/StyledInput";
import { FiX } from "react-icons/fi";
import { ScarDetailContext } from "routes/Main/ProjectDetail/ScarDetail/ScarDetailContainer";
import { SCAR_APPROVAL } from "graphql/Main/ProjectDetail/ScarDetail/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { SEE_MY_SIGN_PROJECT } from "graphql/Main/Main/query";
import { SEE_PROJECT_DETAIL } from "graphql/Main/ProjectDetail/ProjectDetail/query";
import { SEE_SCAR_LIST } from "graphql/Main/ProjectDetail/ProjectDetail/query";
import { AppContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 461px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.whiteColor};

  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: auto 0 0 auto;
`;

const CheckModal = () => {
  const { language } = useContext(AppContext);
  const { setCheckModal, scarId, scarRefetch } = useContext(ScarDetailContext);
  const [checkApprovalContent, setCheckApprovalContent] = useState("");
  const { refetch: signLineRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { refetch: prjDetailRefetch } = useQuery(SEE_PROJECT_DETAIL, {
    variables: { prj_id: Number(localStorage.getItem("prjId")) },
  });
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });
  const [scarApproval] = useMutation(SCAR_APPROVAL);

  const handleSave = useCallback(async () => {
    if (!checkApprovalContent.trim()) {
      toast.error(
        (language === "KOR" && "코멘트를 입력해주세요.") ||
          (language === "ENG" && "Please enter Comment"),
      );
      return;
    }

    try {
      const {
        data: {
          scarApproval: { result, error },
        },
      } = await scarApproval({
        variables: {
          scar_id: Number(scarId),
          approval: true,
          checkApprovalContent,
        },
      });
      if (result) {
        toast.success(
          (language === "KOR" && "승인 되었습니다.") ||
            (language === "ENG" && "Approval complete"),
        );
        await scarRefetch();
        await signLineRefetch();
        await prjDetailRefetch();
        await scarListRefetch();
      }
      if (!error) toast.error(error);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setCheckModal(false);
      setCheckApprovalContent("");
    }
  }, [
    checkApprovalContent,
    language,
    prjDetailRefetch,
    scarApproval,
    scarId,
    scarListRefetch,
    scarRefetch,
    setCheckModal,
    signLineRefetch,
  ]);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text>
          {(language === "KOR" && "승인") || (language === "ENG" && "Approval")}
        </Text>
        <FiX className="closeIcon" onClick={() => setCheckModal(false)} />
        <StyledInput
          name="content"
          label={`${
            (language === "KOR" && "코멘트") ||
            (language === "ENG" && "Comment")
          } ( ${checkApprovalContent.length} / 80 )`}
          maxLength={79}
          margin="30px 0 14px 0"
          value={checkApprovalContent}
          onChange={(e) => setCheckApprovalContent(e.target.value)}
        />
        <ButtonWrapper>
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            BORDER
            borderColor={colors.grayFontColor}
            labelColor={colors.grayFontColor}
            label={
              (language === "KOR" && "취소") || (language === "ENG" && "Cancel")
            }
            onClick={() => setCheckModal(false)}
          />
          <StyledButton
            width={language === "ENG" ? 100 : 74}
            height={46}
            fontSize={16}
            label={
              (language === "KOR" && "완료") ||
              (language === "ENG" && "Complete")
            }
            margin="0 0 0 20px"
            onClick={() => handleSave()}
          />
        </ButtonWrapper>
      </ModalContainer>
    </Container>
  );
};

export default memo(CheckModal);
