import React from "react";
import styled from "styled-components";
import createIcon from "assets/icon/createIcon.svg";
import {colors} from "styles/colors";
import {css} from "styled-components";

const Container = styled.button`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({bgColor}) => bgColor};
  margin: ${({margin}) => margin};
  ${({BORDER}) =>
          BORDER &&
          css`
            background-color: ${colors.whiteColor};
            border: 1px solid ${({borderColor}) => borderColor};
          `}
`;

const Text = styled.div`
  font-weight: bold;
  color: ${({labelColor}) => labelColor};
  font-size: ${({fontSize}) => fontSize}px;
`;

const Icon = styled.img`
  margin-right: 12px;
`;

const StyledButton = ({
                          width,
                          height,
                          label,
                          CREATEICON,
                          fontSize = 24,
                          labelColor = `${colors.whiteColor}`,
                          BORDER,
                          borderColor = `${colors.grayFontColor}`,
                          bgColor = `${colors.mainColor}`,
                          margin,
                          onClick,
                      }) => {
    return (
        <Container
            width={width}
            height={height}
            bgColor={bgColor}
            borderColor={borderColor}
            BORDER={BORDER}
            margin={margin}
            onClick={onClick}
        >
            {CREATEICON && <Icon src={createIcon}/>}
            <Text fontSize={fontSize} labelColor={labelColor}>
                {label}
            </Text>
        </Container>
    );
};

export default StyledButton;
