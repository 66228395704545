import React, {useContext} from "react";
import styled, {css} from "styled-components";
import {colors} from "../../../../../styles/colors";
import {AppContext} from "../../../../../App";

const Wrapper = styled.div`
  width: 100%;
  margin: 26px 0 34px;
`;
const Title = styled.div`
  padding-bottom: 9px;
  font-weight: bold;
`;
const ChartBox = styled.div`
  height: 150px;
  display: flex;
  align-self: flex-end;
  border-bottom: 1px solid ${colors.grayFontColor};
`;
const StickGraphBox = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
`;
const StickGraph = styled.div`
  width: 8px;
  flex: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.mainColor};
  transition: .4s;

  ${({ height }) => height && css`
    height: ${({ height }) => height}%;
  `};
`;
const ValueBox = styled.div`
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ValueText = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`;

const StickChart = ({ data = [] }) => {
    const { language } = useContext(AppContext);

    return (
        <Wrapper>
            <Title>
                {language === 'KOR' && '체크리스트 월 준수율 통계 (최근)'}
                {language === 'ENG' && 'Checklist Monthly Compliance Statistics (Recent)'}
            </Title>
            <ChartBox>
                {data.map((data) => (
                    <StickGraphBox key={data?.month}>
                        <StickGraph height={data?.complyRate} />
                        <ValueBox>
                            <ValueText>
                                {data?.month}
                            </ValueText>
                            <ValueText>
                                {data?.complyRate}%
                            </ValueText>
                        </ValueBox>
                    </StickGraphBox>
                ))}
            </ChartBox>
        </Wrapper>
    )
}

export default React.memo(StickChart);