import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { AppContext } from "App";
import locationIcon from "assets/icon/locationIcon.svg";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "utils/common";
import { scarSignStatusChanger } from "lib/ProjectDetailUtils";
import headerLogo from "assets/icon/Logo.png";

const Container = styled.div`
  width: 100%;
  height: 118px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  &:hover {
    background-color: ${colors.lightGrayHover};
  }
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-size: 18px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  padding-right: 20px;
`;

const ProjectImage = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 10px;
  margin-right: 30px;
  /* background-image: ${({ src }) => `url(${src})`};
  background-position: 50%;
  background-size: cover; */
  object-fit: cover;
  border: ${({ src }) => src === "" && `1px solid ${colors.lightgrayBorder}`};
`;

const LocationIcon = styled.img`
  margin-right: 6px;
`;

const ProjectCard = ({ project }) => {
  const { language } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    prj_address,
    prj_img,
    prj_startDate,
    prj_endDate,
    prj_status,
    prj_id,
    prj_title,
  } = project;

  return (
    <Container
      onClick={() => {
        // console.info('???', prj_id );
        navigate("/projectDetail");
        localStorage.setItem("prjId", prj_id);
      }}
    >
      {/*{console.log(">SDF>SDF>SD>", prj_status)}*/}
      <ProjectImage src={prj_img || headerLogo} />
      <Text BOLD width={220}>
        {prj_title}
      </Text>
      <Text color={colors.grayFontColor} width={258}>
        <LocationIcon src={locationIcon} />
        {prj_address}
      </Text>
      <Text width={238}>
        {dateFormat(new Date(Number(prj_startDate)), "yyyy.MM.dd")} -{" "}
        {dateFormat(new Date(Number(prj_endDate)), "yyyy.MM.dd")}
      </Text>
      <Text
        width={87}
        BOLD
        color={
          prj_status === "end" || prj_status === "toEnd"
            ? colors.grayFontColor
            : colors.mainColor
        }
      >
        {scarSignStatusChanger(prj_status, language)}
      </Text>
    </Container>
  );
};

export default ProjectCard;
