import React, { useEffect, useState, useRef, forwardRef } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import noticeIconWhite from "assets/icon/noticeIconWhite.svg";
import clockIcon from "assets/icon/clockIcon.svg";
import { SEE_MY_NOTIFICATION } from "graphql/App/query";
import { useQuery } from "@apollo/client";
import { dateFormat } from "utils/common";

const Container = styled.div`
  width: 353px;
  min-height: 250px;
  max-height: 500px;
  position: absolute;
  top: 70px;
  left: calc(-1 * (353px / 2));
  padding: 20px;
  background-color: ${colors.whiteColor};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow-x: auto;
  z-index: 1;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.scrollColor};
  }
`;

const NotiBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  border-bottom: 1px solid ${colors.grayFontColor};
  &:last-child {
    border: none;
  }
`;

const Icon = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-left: 30px;
  background-color: ${colors.mainColor};
  background-image: url(${noticeIconWhite});
  background-repeat: no-repeat;
  background-position: 50%;
`;

const Text = styled.div`
  font-size: 18px;
  word-break: keep-all;
  line-height: 1.3;
`;

const GrayText = styled.div`
  color: ${colors.grayFontColor};
  display: flex;
  margin-top: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClockIcon = styled.div`
  background-image: url(${clockIcon});
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const NoticeModal = forwardRef(({ setSubScriptOn, subScriptOn }, ref) => {
  const {
    data: notiData,
    loading: notiLoading,
    refetch: notiRefetch,
  } = useQuery(SEE_MY_NOTIFICATION);

  useEffect(() => setSubScriptOn(false), []);

  return (
    <Container ref={ref}>
      {notiData?.seeMyNotification?.notificationList?.map((noti) => (
        <NotiBox key={noti.notifi_id}>
          <TextWrapper>
            <Text>{noti.notifi_content}</Text>
            <GrayText>
              <ClockIcon />
              {dateFormat(
                new Date(Number(noti.notifi_createdAt)),
                "yyyy.MM.dd - hh:mm"
              )}
            </GrayText>
          </TextWrapper>
          <Icon />
        </NotiBox>
      ))}
    </Container>
  );
});

export default NoticeModal;
