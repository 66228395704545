import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { FiChevronRight } from "react-icons/fi";
import Image from "components/feature/Report/Image";
import { riskGradeChanger } from "lib/ProjectDetailUtils";
import { dateFormat } from "utils/common";
import { scarSignStatusChanger } from "lib/ProjectDetailUtils";
import MemberModal from "components/feature/Report/MemberModal";

const Container = styled.div`
  width: 100%;
  max-width: 957px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 50px 16px;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ GRAY }) => GRAY && `${colors.grayFontColor}`};
  & > span {
    ::after {
      content: ", ";
    }
  }
  & > span:last-child {
    ::after {
      content: "";
    }
  }
`;

const SpaceBetweenRowBox = styled.div`
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ BOLDERBOTTOM }) =>
    BOLDERBOTTOM && `1px solid ${colors.lightgrayBorder}`};
  border-top: ${({ BOLDERTOP }) =>
    BOLDERTOP && `1px solid ${colors.lightgrayBorder}`};
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ bgColor }) => {
    switch (bgColor) {
      case "reject":
        return `${colors.redFontColor}`;
      case "impossible":
        return `${colors.redFontColor}`;
      case "complete":
        return `${colors.grayFontColor}`;
      default:
        return `${colors.mainColor}`;
    }
  }};
`;

const NormalRowBox = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ POINTER }) => POINTER && "pointer"};
  & > .icon {
    font-size: 24px;
    margin-left: 10px;
    color: ${colors.darkGrayFontColor};
  }
`;

const ProfileImage = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: ${({ src }) => (src === "" ? `40%` : `cover`)};
  border: ${({ src }) => src === "" && `1px solid ${colors.grayFontColor}`};
  background-color: ${colors.whiteColor};
  z-index: ${({ zIndex }) => zIndex};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.darkGrayFontColor};
`;

const ProfileLayer = styled.div`
  display: flex;
  margin-right: 10px;
  & > ${ProfileImage} {
    margin-right: -8px;
  }
`;

const TextColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  & > * + * {
    margin: 10px 0;
  }
`;

const HistoryCard = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 10px;
  align-content: center;
  height: 70px;
  border-bottom: 1px solid ${colors.lightgrayBorder};
`;

const ReportPresenter = ({ data, memberModal, setMemberModal, language }) => {
  return (
    <Container>
      <TitleText>{data?.scar?.scar_title}</TitleText>
      <SpaceBetweenRowBox>
        <Text BOLD>{data?.scar?.scar_number}</Text>
        <NormalRowBox>
          <Dot bgColor={data?.scar?.scar_signStatus} />
          <Text>
            {data?.scar?.scar_signNumber === 6 ? "완료" : "진행중"} :{" "}
            {scarSignStatusChanger(data?.scar?.scar_signStatus, language)}
          </Text>
        </NormalRowBox>
      </SpaceBetweenRowBox>
      <SpaceBetweenRowBox BOLDERBOTTOM BOLDERTOP>
        <Text>결재라인</Text>
        <NormalRowBox POINTER onClick={() => setMemberModal(true)}>
          <ProfileLayer>
            <ProfileImage
              src={data?.scar?.signLine?.actionCheck_img}
              zIndex={3}
            />
            <ProfileImage src={data?.scar?.signLine?.manager_img} zIndex={2} />
            <ProfileImage src={data?.scar?.signLine?.action_img} zIndex={1} />
          </ProfileLayer>
          <ProfileImage>+3</ProfileImage>
          <FiChevronRight className="icon" />
        </NormalRowBox>
      </SpaceBetweenRowBox>
      <SpaceBetweenRowBox>
        <Text BOLD> SCAR 발행 정보</Text>
        <NormalRowBox>
          <ProfileImage src={data?.creatorInfo?.user_profileImg} />
          <Text>
            {data?.creatorInfo?.user_name} {data?.creatorInfo?.user_rank}
          </Text>
        </NormalRowBox>
      </SpaceBetweenRowBox>
      <TextColumnBox>
        <Text GRAY>위반사항</Text>
        <Text>{data?.scar?.scar_title}</Text>
      </TextColumnBox>
      <TextColumnBox>
        <Text GRAY>위반위치</Text>
        <Text>{data?.scar?.scar_spot}</Text>
      </TextColumnBox>
      <TextColumnBox>
        <Text GRAY>위험등급</Text>
        <Text>
          {riskGradeChanger(data?.scar?.scar_riskGrade)} :{" "}
          {data?.scar?.scar_riskGrade === "A"
            ? "발행일 금일 자정까지 조치"
            : data?.scar?.scar_riskGrade === "B"
            ? "발행일 익일 자정까지 조치"
            : "발행일 4일 후 자정까지 조치"}
          요망
        </Text>
        <Text>
          조치기한 :{" "}
          {dateFormat(
            new Date(Number(data?.scar?.scar_endDate)),
            "yyyy.MM.dd - hh:mm"
          )}
        </Text>
      </TextColumnBox>
      <TextColumnBox>
        <Text GRAY>라벨</Text>
        <Text>
          {data?.scar?.scar_label?.map((label, index) => (
            <span key={index}>{label?.sLbl_name}</span>
          ))}
        </Text>
      </TextColumnBox>
      <TextColumnBox>
        <Text GRAY>리스크코드</Text>
        <Text>{data?.scar?.scar_riskcode}</Text>
      </TextColumnBox>

      <Text GRAY>사진</Text>
      {data?.scar?.img?.length === 0 ? (
        <Text>-</Text>
      ) : (
        <Image image={data?.scar?.img?.map((img) => img?.si_img)} />
      )}
      <SpaceBetweenRowBox BOLDERTOP>
        <Text BOLD>조치사항정보</Text>
        <NormalRowBox>
          <ProfileImage src={data?.actionUserInfo?.user_profileImg} />
          <Text>
            {data?.actionUserInfo?.user_name} {data?.actionUserInfo?.user_rank}
          </Text>
        </NormalRowBox>
      </SpaceBetweenRowBox>
      <TextColumnBox>
        <Text GRAY>조치사항</Text>
        <Text>
          {data?.scar?.scarAction?.sa_text === ""
            ? "-"
            : data?.scar?.scarAction?.sa_text}
        </Text>
      </TextColumnBox>
      <TextColumnBox>
        <Text GRAY>완료시간 (소요시간)</Text>
        <Text>
          {data?.scar?.scar_completeDate === ""
            ? "-"
            : dateFormat(
                new Date(data?.scar?.scar_completeDate),
                "yyyy.MM.dd - hh:mm"
              )}
        </Text>
      </TextColumnBox>
      <Text GRAY>사진</Text>
      <Image
        image={data?.scar?.scarAction?.scarActionImg?.map(
          (img) => img?.sai_img
        )}
      />
      <SpaceBetweenRowBox BOLDERTOP>
        <Text BOLD>SCAR 히스토리</Text>
      </SpaceBetweenRowBox>
      {data?.scar?.history?.map((history, index) => (
        <HistoryCard key={index}>
          <ProfileImage src={history?.sh_userImg} />
          <Text>{history?.sh_text}</Text>
          <div></div>
          <Text GRAY>
            {dateFormat(
              new Date(Number(history?.sh_createdAt)),
              "yyyy.MM.dd - hh:mm"
            )}
          </Text>
        </HistoryCard>
      ))}
      {memberModal && (
        <MemberModal handleModal={setMemberModal} data={data?.scar?.signLine} />
      )}
    </Container>
  );
};

export default ReportPresenter;
