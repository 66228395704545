import React, {useCallback, useContext, useState, memo} from "react";
import styled from "styled-components";
import {colors} from "../../../../../styles/colors";
import StyledButton from "../../../../share/StyledButton";
import StyledFileInput from "../../../../share/StyledFileInput";
import StyledInput from "../../../../share/StyledInput";
import {FiX} from "react-icons/fi";
import {ScarDetailContext} from "routes/Main/ProjectDetail/ScarDetail/ScarDetailContainer";
import {TAKE_ACTION_SCAR} from "graphql/Main/ProjectDetail/ScarDetail/mutation";
import {useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {SEE_MY_SIGN_PROJECT} from "graphql/Main/Main/query";
import {SEE_PROJECT_DETAIL} from "graphql/Main/ProjectDetail/ProjectDetail/query";
import {SEE_SCAR_LIST} from "graphql/Main/ProjectDetail/ProjectDetail/query";
import {AppContext} from "App";
import {getExtension} from "../../../../../utils/common";
import {ALLOW_FILE_EXTENSION} from "../../../../../utils/constants";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 461px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.whiteColor};

  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: auto 0 0 auto;
`;

const ActionModal = () => {
    const {language} = useContext(AppContext);
    const {setActionModal, scarId, scarRefetch} = useContext(ScarDetailContext);
    const [content, setContent] = useState("");
    const [image, setImage] = useState([]);
    const [thumbnail, setThumbnail] = useState([]);
    const {refetch: signLineRefetch} = useQuery(SEE_MY_SIGN_PROJECT);
    const {refetch: prjDetailRefetch} = useQuery(SEE_PROJECT_DETAIL, {
        variables: {prj_id: Number(localStorage.getItem("prjId"))},
    });
    const {refetch: scarListRefetch} = useQuery(SEE_SCAR_LIST, {
        variables: {
            prjId:
                localStorage.getItem("prjId") === null
                    ? 0
                    : Number(localStorage.getItem("prjId")),
            searchTerm: "",
            statusFilter: "total",
        },
    });

    const [takeActionScar] = useMutation(TAKE_ACTION_SCAR);

    const handleImage = useCallback((e) => {
        let files = e.target.files;

        if ((image.length + files.length) > 5) {
            toast.error((language === "KOR" && "최대 5장의 사진을 업로드할 수 있습니다.") || (language === "ENG" && "Up to five photos can be uploaded."));
            return;
        }

        for (let i = 0; i < files.length; i++) {
            if (files[i].size / 1024 / 1024 > 10) {
                alert((language === "Kor" && "이미지 파일은 10MB 이하로 업로드해주세요.") || (language === "Eng" && "Please upload the image file under 10MB"));
                return;
            }

            let ext = getExtension(files[i].name);

            if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === '') {
                alert((language === "Kor" && "이미지 파일만 업로드 가능합니다.") || (language === "Eng" && "Only image files can be uploaded"));
                return;
            }
        }

        setImage([...image, ...files]);

        let thumbnailImgList = [...thumbnail];
        for (let i = 0; i < files.length; i++) {
            thumbnailImgList.push(URL.createObjectURL(files[i]));
        }
        setThumbnail(thumbnailImgList);
    }, [thumbnail, image]);

    const handleSave = useCallback(async () => {
        if (content.trim() === "") {
            toast.error(
                (language === "KOR" && "조치사항을 입력해주세요.") ||
                (language === "ENG" && "Please enter Resolution Description")
            );
            return;
        }

        if (image.length === 0) {
            toast.error(
                (language === "KOR" && "이미지를 업로드 해주새요.") ||
                (language === "ENG" && "Please upload image")
            );
            return;
        }

        try {
            let variables;
            if (image.length === 0) {
                variables = {
                    scar_id: Number(scarId),
                    sa_text: content,
                    sa_imgSelect: false,
                };
            } else {
                variables = {
                    scar_id: Number(scarId),
                    sa_text: content,
                    sa_imgSelect: true,
                    scarActionImg: image,
                };
            }
            const {data} = await takeActionScar({variables: variables});
            if (data?.takeActionScar?.result) {
                await scarRefetch();
                await signLineRefetch();
                await prjDetailRefetch();
                await scarListRefetch();
                toast.success(
                    (language === "KOR" && "조치를 완료했습니다.") ||
                    (language === "ENG" && "Report sent successfully")
                );
                setActionModal(false);
            }
            if (data?.takeActionScar?.error !== "") {
                toast.error(data?.takeActionScar?.error);
            }
        } catch (e) {
            toast.error(e.message);
        }
    }, [content, image]);

    const handleImageDelBtn = useCallback((index) => {
        thumbnail.splice(index, 1);
        setThumbnail(thumbnail);
        setImage(image.filter(img => image[index] !== img));
    }, [image, thumbnail]);

    // React.useEffect(() => console.log(image, thumbnail), [thumbnail, image]);

    return (
        <Container>
            <BgContainer/>
            <ModalContainer>
                <Text>조치하기</Text>
                <FiX className="closeIcon" onClick={() => setActionModal(false)}/>
                <StyledInput
                    name="content"
                    label={`${
                        (language === "KOR" && "조치사항") ||
                        (language === "ENG" && "Resolution Description")
                    } ( ${content.length} / 80 )`}
                    maxLength={79}
                    margin="30px 0 14px 0"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
                <StyledFileInput
                    multiple
                    image={thumbnail}
                    onChange={handleImage}
                    delClick={handleImageDelBtn}
                />
                <ButtonWrapper>
                    <StyledButton
                        width={74}
                        height={46}
                        fontSize={16}
                        BORDER
                        borderColor={colors.grayFontColor}
                        labelColor={colors.grayFontColor}
                        label={
                            (language === "KOR" && "취소") || (language === "ENG" && "Cancel")
                        }
                        onClick={() => setActionModal(false)}
                    />
                    <StyledButton
                        width={language === "ENG" ? 100 : 74}
                        height={46}
                        fontSize={16}
                        label={
                            (language === "KOR" && "완료") ||
                            (language === "ENG" && "Complete")
                        }
                        margin="0 0 0 20px"
                        onClick={() => handleSave()}
                    />
                </ButtonWrapper>
            </ModalContainer>
        </Container>
    );
};

export default memo(ActionModal);
