import React from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import logo from "assets/icon/Logo.png";
import StyledSelect from "components/share/StyledSelect";
import { nationList } from "components/feature/Login/nationNumber";
import InputForm from "components/feature/Login/InputForm";
import StyledButton from "components/share/StyledButton";
import emptyProfile from "assets/icon/emptyProfile.svg";
import imgPlusIcon from "assets/icon/imgPlusIcon.svg";
import checkIcon from "assets/icon/checkIcon.svg";
import { onlyNumber } from "utils/common";

const Container = styled.div`
  min-width: 1920px;
  width: 100%;
  height: 100vh;
  background-color: ${colors.whiteColor};
  display: grid;
  grid-template-columns: 640px minmax(1280px, 1fr);
  position: relative;
`;

const Text = styled.div`
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ BOLD }) => BOLD && "bold"};
`;

const LargeText = styled(Text)`
  font-size: 48px;
  font-weight: bold;
  color: ${colors.mainColor};
`;

const MediumText = styled(Text)`
  font-size: 24px;
`;

const SmallText = styled(Text)`
  font-size: 18px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  cursor: ${({ POINTER }) => POINTER && "pointer"};
  margin: ${({ margin }) => margin};
`;

const PageLogo = styled.img`
  position: absolute;
  top: 30px;
  left: 40px;
  width: 100px;
  border-radius: 20px;
`;

const TutorialButton = styled.button`
  width: 253px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: ${colors.whiteColor};
  border-radius: 10px;
  border: 2px solid ${colors.whiteColor};
`;

const ContentsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ BLUE }) =>
    BLUE &&
    css`
      background-color: ${colors.mainColor};
      & > ${Text} {
        text-align: center;
        color: ${colors.whiteColor};
      }
    `};
`;

const SendButton = styled.button`
  width: 87px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 12px;
  right: calc((-87px + -22px));
  font-size: 24px;
  color: ${colors.grayFontColor};
  border: 1px solid ${colors.grayFontColor};
  border-radius: 10px;
  &:hover {
    border: 1.5px solid ${colors.grayFontColor};
  }
  &:active {
    background-color: ${colors.lightGrayHover};
  }
`;

const RowBox = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  margin: ${({ margin }) => margin};
  cursor: ${({ POINTER }) => POINTER && "pointer"};
`;

const ProfileImageThumbnail = styled.label`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${colors.lightGrayHover};
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-repeat: no-repeat;
  background-size: ${({ src }) => (src === "" ? "40%" : "cover")};
  background-position: 50%;
  position: relative;
  cursor: pointer;
`;

const ImageInput = styled.input`
  display: none;
`;

const ProfilePlusBtn = styled.img`
  position: absolute;
  right: 0;
  bottom: 10px;
`;

const CheckButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url(${checkIcon});
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: ${({ color }) => color};
  margin-right: 6px;
  margin-bottom: 20px;
`;

const SignupPresenter = ({
  inputs,
  setInputs,
  handleInput,
  handleSelect,
  sendAuthCode,
  confirmAuth,
  handleSignup,
  onChangeFile,
  profileImage,
  goToLogin,
  language,
  setLanguage,
  handleSignUpMode,
}) => {
  return (
    <Container>
      <PageLogo src={logo} />
      <ContentsContainer BLUE>
        <LargeText lineHeight={1.4}>
          {language === "KOR" && " 반가워요,"}
          {language === "ENG" && "Hello,"}
          <br />
          {language === "KOR" && "리스크제로 HG입니다."}
          {language === "ENG" && "It's RiskZero HG."}
        </LargeText>
        <MediumText lineHeight={1.5} margin="50px 0 80px 0">
          {language === "KOR" && "리스크제로 HG는 안전시정조치요구서의 발행"}
          {language === "ENG" && "Risk Zero HG is an application developed"}
          <br />
          {language === "KOR" &&
            "공유 및 문서화를 하나의 플랫폼에서 수행하도록"}
          {language === "ENG" &&
            "by Hanmi Global to issue, share, and document safety"}
          <br />
          {language === "KOR" &&
            "한미글로벌이 주도하여 개발한 어플리케이션입니다."}
          {language === "ENG" && "corrective action requests on one platform."}
        </MediumText>
        {/* <TutorialButton>사용방법 보러가기</TutorialButton> */}
      </ContentsContainer>
      <ContentsContainer>
        <LargeText margin="0 0 100px 0">
          {language === "KOR" && "회원가입하기"}
          {language === "ENG" && "Signup"}
        </LargeText>
        {!inputs.sendcode ? (
          <>
            {inputs.signUpMode === "PHONE" ? (
              <RowBox>
                <StyledSelect
                  name="nation"
                  label={
                    (language === "KOR" && "국가") ||
                    (language === "ENG" && "Country code")
                  }
                  width={78}
                  value={inputs.nation}
                  onChange={handleSelect}
                  options={nationList}
                />
                <InputForm
                  width={265}
                  name="phoneNumber"
                  label={
                    (language === "KOR" && "휴대폰번호") ||
                    (language === "ENG" && "Phone number")
                  }
                  margin="0 0 0 30px"
                  value={onlyNumber(inputs.phoneNumber)}
                  onChange={handleInput}
                />
                <SendButton onClick={() => sendAuthCode()}>
                  {language === "KOR" && "전송"}
                  {language === "ENG" && "Send"}
                </SendButton>
              </RowBox>
            ) : null}

            {inputs.signUpMode === "EMAIL" ? (
              <RowBox>
                <InputForm
                  width={373}
                  name="mail"
                  label={
                    (language === "KOR" && "이메일 주소") ||
                    (language === "ENG" && "E-Mail Address")
                  }
                  value={inputs.mail}
                  onChange={handleInput}
                />
                <SendButton onClick={() => sendAuthCode()}>
                  {language === "KOR" && "전송"}
                  {language === "ENG" && "Send"}
                </SendButton>
              </RowBox>
            ) : null}

            <RowBox>
              <InputForm
                name="authcode"
                label={
                  (language === "KOR" && "6자리 인증번호") ||
                  (language === "ENG" && "6-digit code")
                }
                width={373}
                value={onlyNumber(inputs.authcode)}
                onChange={handleInput}
                maxLength={6}
                margin="30px 0 0 0 "
              />
            </RowBox>
            <StyledButton
              label={
                (language === "KOR" && "인증하기") ||
                (language === "ENG" && "Authenticate")
              }
              width={373}
              height={68}
              margin="50px 0 0 0"
              onClick={() => confirmAuth(false)}
            />
            <SmallText
              POINTER
              BOLD
              margin="20px 0 0 0"
              onClick={() =>
                handleSignUpMode(
                  inputs.signUpMode === "PHONE" ? "EMAIL" : "PHONE",
                )
              }
            >
              {inputs.signUpMode === "PHONE" ? (
                <>
                  {language === "KOR" && "이메일로 회원가입"}
                  {language === "ENG" && "Sign up by e-mail"}
                </>
              ) : null}
              {inputs.signUpMode === "EMAIL" ? (
                <>
                  {language === "KOR" && "핸드폰으로 회원가입"}
                  {language === "ENG" && "Sign up by phone"}
                </>
              ) : null}
            </SmallText>
            <SmallText
              POINTER
              BOLD
              margin="20px 0 0 0"
              onClick={() => goToLogin()}
            >
              {language === "KOR" && "로그인하기"}
              {language === "ENG" && "Login"}
            </SmallText>
            <RowBox margin="40px 0 0 0">
              <SmallText
                POINTER
                BOLD
                color={language !== "KOR" ? colors.grayFontColor : ""}
                onClick={() => {
                  localStorage.setItem("lang", "KOR");
                  setLanguage("KOR");
                }}
              >
                KOR
              </SmallText>
              <SmallText margin="0 10px">|</SmallText>
              <SmallText
                POINTER
                BOLD
                color={language !== "ENG" ? colors.grayFontColor : ""}
                onClick={() => {
                  localStorage.setItem("lang", "ENG");
                  setLanguage("ENG");
                }}
              >
                ENG
              </SmallText>
            </RowBox>
          </>
        ) : (
          <>
            <ProfileImageThumbnail src={profileImage}>
              <ImageInput
                type="file"
                accept=".jpeg, .jpg, .png"
                onChange={onChangeFile}
              />
              <ProfilePlusBtn src={imgPlusIcon} />
            </ProfileImageThumbnail>
            <RowBox>
              <InputForm
                name="name"
                label={
                  (language === "KOR" && "성함*") ||
                  (language === "ENG" && "Name*")
                }
                margin="30px 30px 0 0"
                width={200}
                value={inputs.name}
                onChange={handleInput}
              />
              <InputForm
                name="rank"
                label={
                  (language === "KOR" && "직책") ||
                  (language === "ENG" && "Rank")
                }
                width={140}
                value={inputs.rank}
                onChange={handleInput}
              />
            </RowBox>
            <InputForm
              name="mail"
              label={
                (language === "KOR" && "메일*") ||
                (language === "ENG" && "Email*")
              }
              width={373}
              margin="30px 0"
              disabled={inputs.signUpMode === "EMAIL"}
              value={inputs.mail}
              onChange={handleInput}
            />
            <InputForm
              name="company"
              label={
                (language === "KOR" && "소속회사명*") ||
                (language === "ENG" && "Company*")
              }
              margin="0 0 30px 0"
              width={373}
              value={inputs.company}
              onChange={handleInput}
            />
            <RowBox>
              <StyledSelect
                name="team"
                label={
                  (language === "KOR" && "소속") ||
                  (language === "ENG" && "Role")
                }
                width={175}
                margin="0 33px 0 0"
                value={inputs.team}
                onChange={handleSelect}
                options={
                  (language === "KOR" && [
                    "PM본사",
                    "PM현장",
                    "시공사",
                    "발주처",
                  ]) ||
                  (language === "ENG" && [
                    "PM HQ",
                    "PM",
                    "Contractor",
                    "Client",
                  ])
                }
              />
              <InputForm
                name="companyCode"
                label={
                  (language === "KOR" && "소속회사 코드 입력*") ||
                  (language === "ENG" && "Role code*")
                }
                width={165}
                value={inputs.companyCode}
                onChange={handleInput}
              />
            </RowBox>
            <RowBox POINTER>
              <CheckButton
                color={inputs.agree ? colors.greenColor : colors.scrollColor}
                onClick={() => setInputs({ ...inputs, agree: !inputs.agree })}
              />
              <SmallText
                margin="30px 0 20px 0"
                color={colors.grayFontColor}
                onClick={() => setInputs({ ...inputs, agree: !inputs.agree })}
              >
                {language === "KOR" && "약관에 동의합니다."}
                {language === "ENG" && "Accept the terms and conditions"}
              </SmallText>
              <SmallText
                margin="30px 0 20px 10px"
                BOLD
                onClick={() => window.open("https://agreementshg.riskzero.ai")}
              >
                {language === "KOR" && "약관보러가기"}
                {language === "ENG" && "Terms and conditions"}
              </SmallText>
            </RowBox>
            <StyledButton
              label={
                (language === "KOR" && "회원가입하기") ||
                (language === "ENG" && "Sign Up")
              }
              width={373}
              height={68}
              margin="30px 0 0 0 "
              onClick={handleSignup}
            />
            <SmallText
              POINTER
              BOLD
              margin="30px 0 0 0"
              onClick={() => goToLogin()}
            >
              {language === "KOR" && "로그인하기"}
              {language === "ENG" && "Login"}
            </SmallText>
            <RowBox margin="20px 0 0 0">
              <SmallText
                POINTER
                BOLD
                color={language !== "KOR" ? colors.grayFontColor : ""}
                onClick={() => {
                  localStorage.setItem("lang", "KOR");
                  setLanguage("KOR");
                }}
              >
                KOR{" "}
              </SmallText>
              <SmallText margin="0 10px">|</SmallText>
              <SmallText
                POINTER
                BOLD
                color={language !== "ENG" ? colors.grayFontColor : ""}
                onClick={() => {
                  localStorage.setItem("lang", "ENG");
                  setLanguage("ENG");
                }}
              >
                ENG
              </SmallText>
            </RowBox>
          </>
        )}
      </ContentsContainer>
    </Container>
  );
};

export default SignupPresenter;
