import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ScarDetailPresenter from "./ScarDetailPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_SCAR_DETAIL } from "graphql/Main/ProjectDetail/ScarDetail/query";
import { SCAR_APPROVAL } from "graphql/Main/ProjectDetail/ScarDetail/mutation";
import { toast } from "react-toastify";
import { SEE_MY_SIGN_PROJECT } from "graphql/Main/Main/query";
import { SEE_PROJECT_DETAIL } from "graphql/Main/ProjectDetail/ProjectDetail/query";
import { SEE_SCAR_LIST } from "graphql/Main/ProjectDetail/ProjectDetail/query";
import { AppContext } from "App";
import { useParams } from "react-router-dom";

export const ScarDetailContext = createContext();

const ScarDetailContainer = () => {
  const { language } = useContext(AppContext);
  const { scarId } = useParams();
  const projectInfo = JSON.parse(localStorage.getItem("projectInfo"));
  const [rejectReason, setRejectReason] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [scarModal, setScarModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const [scar, setScar] = useState(null);
  const [signTurn, setSignTurn] = useState(false);
  const [openAvilabel, setOpenAvilabel] = useState(true);
  const { refetch: signLineRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { data: prjDetailData, refetch: prjDetailRefetch } = useQuery(
    SEE_PROJECT_DETAIL,
    {
      variables: { prj_id: Number(localStorage.getItem("prjId")) },
    },
  );
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });

  const { data: scarData, refetch: scarRefetch } = useQuery(SEE_SCAR_DETAIL, {
    variables: {
      scar_id: Number(scarId),
    },
  });

  const [scarApproval] = useMutation(SCAR_APPROVAL);

  const handleApproval = useCallback(
    async (action) => {
      const {
        seeScarDetail: {
          scar: { scar_signNumber },
        },
      } = scarData;

      if (action && scar_signNumber === 1) {
        setCheckModal(true);
        return;
      }
      if (action && scar_signNumber === 2) {
        setActionModal(true);
        return;
      }

      if (
        window.confirm(
          (language === "KOR" && `${action ? "승인" : "반려"}하시겠습니까?`) ||
            (language === "ENG" &&
              `Are you sure you want to ${action ? "Approval" : "Reject"}`),
        )
      ) {
        try {
          const {
            data: {
              scarApproval: { result, error },
            },
          } = await scarApproval({
            variables: {
              scar_id: Number(scarId),
              approval: action,
              rejectText: rejectReason,
            },
          });
          if (result) {
            toast.success(
              (language === "KOR" &&
                `${action ? "승인" : "반려"} 되었습니다.`) ||
                (language === "ENG" &&
                  `${action ? "Approval" : "Reject"} complete`),
            );
            await scarRefetch();
            await signLineRefetch();
            await prjDetailRefetch();
            await scarListRefetch();
          }
          if (!error) toast.error(error);
        } catch (e) {
          toast.error(e.message);
        } finally {
          setRejectModal(false);
          setRejectReason("");
        }
      }
    },
    [scarId, scarData, rejectReason],
  );

  const handleModal = useCallback(() => {
    setScarModal(!scarModal);
  }, [scarModal]);

  const handleCheckModal = useCallback(() => {
    setCheckModal(!checkModal);
  }, [checkModal]);

  useEffect(() => {
    if (scarData?.seeScarDetail?.result) {
      setScar(scarData.seeScarDetail.scar);
      setSignTurn(scarData?.seeScarDetail?.mySignTurn);
    }
  }, [scarData]);

  useEffect(() => {
    setOpenAvilabel(
      prjDetailData?.seeProjectDetail?.project?.prj_openAvailable,
    );
  }, [prjDetailData]);

  return (
    <ScarDetailContext.Provider
      value={{
        scarId,
        scarModal,
        handleModal,
        handleCheckModal,
        scarData,
        scarRefetch,
        scar,
        projectInfo,
        handleApproval,
        rejectModal,
        setRejectModal,
        rejectReason,
        setRejectReason,
        actionModal,
        setActionModal,
        checkModal,
        setCheckModal,
        signTurn,
        openAvilabel,
      }}
    >
      <ScarDetailPresenter language={language} />
    </ScarDetailContext.Provider>
  );
};

export default ScarDetailContainer;
