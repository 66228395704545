import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import { dateFormat } from "utils/common";
import { AppContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 574px;
  height: 661px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  overflow-y: auto;
  /* overflow-x: hidden; */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.scrollColor};
  }
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
`;

const NoticeTitle = styled.div`
  font-size: 24px;
  margin: 40px 0 20px 0;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NoticeText = styled.div`
  font-size: 18px;
  line-height: 1.5;
  white-space: normal;
  word-break: break-all;
  padding: 30px 0;
`;

const Image = styled.img`
  margin-top: 20px;
`;

const NoticeModal = ({ handleModal, notice }) => {
  const { language } = useContext(AppContext);
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text BOLD>
          {language === "KOR" && "공지"}
          {language === "ENG" && "Notice"}
        </Text>
        <FiX className="closeIcon" onClick={() => handleModal()} />

        <NoticeTitle>{notice?.noti_title}</NoticeTitle>
        <Text color={colors.darkGrayFontColor} margin="0 0 20px 0">
          {dateFormat(new Date(Number(notice?.noti_createdAt)), "yyyy.MM.dd")}
        </Text>
        <NoticeText
          dangerouslySetInnerHTML={{
            __html: notice?.noti_text.replaceAll("\n", "<br />"),
          }}
        ></NoticeText>
        <Image src={notice?.noti_img} />
      </ModalContainer>
    </Container>
  );
};

export default NoticeModal;
