export const statusChager = (value, type) => {
  switch (type) {
    case "KOR":
      switch (value) {
        case "전체":
          return "total";
        case "진행중":
          return "inProgress";
        case "조치필요":
          return "actionRequier";
        case "확인필요":
          return "confirmRequier";
        case "불가/반려":
          return "reject";
        case "최종완료":
          return "final";
      }
    case "ENG":
      switch (value) {
        case "inProgress":
          return "In progress";
        case "end":
          return "Closed";
        case "toEnd":
          return "Waiting for Close";
        case "notAction":
          return "Not action";
        case "delay":
          return "Delay";
        case "reject":
          return "Reject";
        case "complete":
          return "Complete";
        default:
          return value;
      }
  }
};

export const scarSignStatusChanger = (value, type) => {
  switch (type) {
    case "KOR":
      switch (value) {
        case "total":
          return "전체";
        case "notAction":
          return "미조치";
        case "inProgress":
          return "진행중";
        case "toEnd":
          return "종료예정";
        case "end":
          return "종료";
        case "impossible":
          return "조치불가";
        case "complete":
          return "최종완료";
        case "reject":
          return "반려";
        case "delay":
          return "지연";
      }
    case "ENG":
      switch (value) {
        case "inProgress":
          return "In progress";
        case "end":
          return "Closed";
        case "toEnd":
          return "Waiting for Close";
        case "notAction":
          return "Not action";
        case "delay":
          return "Delay";
        case "reject":
          return "Reject";
        case "complete":
          return "Complete";
        default:
          return value;
      }
  }
};

export const riskGradeChanger = (value, type) => {
  switch (type) {
    case "KOR":
      switch (value) {
        case "A":
          return "상";
        case "B":
          return "중";
        case "C":
          return "하";
        case "상":
          return "A";
        case "중":
          return "B";
        case "하":
          return "C";
      }
      break;
    case "ENG":
      switch (value) {
        case "High":
          return "A";
        case "Medium":
          return "B";
        case "Low":
          return "C";
        case "A":
          return "High";
        case "B":
          return "Medium";
        case "C":
          return "Low";
      }
      break;
  }
};

export const engMonthChang = month => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';
  }
}
