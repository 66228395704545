import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import StyledButton from "components/share/StyledButton";
import { AppContext, ProfileContext } from "App";
import { useMutation } from "@apollo/client";
import { WITHDRAWAL } from "../../graphql/Profile/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthProvider";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: ${colors.whiteColor};
  position: fixed;
  border-radius: 10px;
  overflow: hidden;
  & > .closeIcon {
    margin: 0 0 10px auto;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: auto 0 0 auto;
`;

const WithdrawalModal = () => {
  const { userLogout } = useContext(AuthContext);
  const { language } = useContext(AppContext);
  const { handleCloseWithdrawal } = useContext(ProfileContext);
  const [withdrawal] = useMutation(WITHDRAWAL);

  const handleWithdrawal = useCallback(
    async (e) => {
      try {
        const {
          data: {
            withdrawal: { result },
          },
        } = await withdrawal();

        if (result) {
          toast.info(
            (language === "KOR" && "회원탈퇴 성공") ||
              (language === "ENG" && "Success withdrawal"),
          );
          userLogout();
        } else {
          toast.error(
            (language === "KOR" && "회원탈퇴 실패") ||
              (language === "ENG" && "Fail withdrawal"),
          );
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    },
    [withdrawal],
  );

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <FiX className="closeIcon" onClick={handleCloseWithdrawal} />
        <div>
          {(language === "KOR" && "절말 탈퇴하시겠습니까?") ||
            (language === "ENG" && "Do you really want to withdrawal?")}
        </div>
        <ButtonWrapper>
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            BORDER
            borderColor={colors.grayFontColor}
            labelColor={colors.grayFontColor}
            label={
              (language === "KOR" && "취소") || (language === "ENG" && "Cancel")
            }
            onClick={handleCloseWithdrawal}
          />
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            label={
              (language === "KOR" && "완료") || (language === "ENG" && "Submit")
            }
            margin="0 0 0 20px"
            onClick={handleWithdrawal}
          />
        </ButtonWrapper>
      </ModalContainer>
    </Container>
  );
};

export default WithdrawalModal;
