import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import { ScarDetailContext } from "routes/Main/ProjectDetail/ScarDetail/ScarDetailContainer";
import StyledInput from "components/share/StyledInput";
import StyledButton from "components/share/StyledButton";
import { AppContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  position: fixed;
  border-radius: 10px;
  background-color: ${colors.whiteColor};
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  & > .closeIcon {
    margin: 0 0 10px auto;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: auto 0 0 auto;
`;

const RejectModal = () => {
  const { setRejectModal, rejectReason, setRejectReason, handleApproval } =
    useContext(ScarDetailContext);
  const { language } = useContext(AppContext);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <FiX className="closeIcon" onClick={() => setRejectModal(false)} />
        <StyledInput
          label={
            (language === "KOR" && "반려 이유") ||
            (language === "ENG" && "Reason for Rejection")
          }
          placeholder={
            (language === "KOR" && "반려 이유 입력") ||
            (language === "ENG" && "Enter reasons for a rejection")
          }
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
        <ButtonWrapper>
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            BORDER
            borderColor={colors.grayFontColor}
            labelColor={colors.grayFontColor}
            label={
              (language === "KOR" && "취소") || (language === "ENG" && "Cancel")
            }
            onClick={() => setRejectModal(false)}
          />
          <StyledButton
            width={74}
            height={46}
            fontSize={16}
            label={
              (language === "KOR" && "완료") || (language === "ENG" && "Submit")
            }
            margin="0 0 0 20px"
            onClick={() => handleApproval(false)}
          />
        </ButtonWrapper>
      </ModalContainer>
    </Container>
  );
};

export default RejectModal;
