import React, { useState, useEffect, useRef, useContext } from "react";
import styled, { css } from "styled-components";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { dateFormat } from "../../../../utils/common";
import { colors } from "../../../../styles/colors";
import { MainContext } from "../../../../routes/Main/Main/MainContainer";
import { AppContext } from "App";

const RowBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ValueViewer = styled.div`
  width: 208px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.mainColor};
  border: 1px solid ${colors.lightgrayBorder};
  border-radius: 8px;
  cursor: pointer;
`;

const CalendarContainer = styled.div`
  width: 208px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  padding: 10px;
  border: 1px solid ${colors.lightgrayBorder};
  border-radius: 10px;
  background-color: ${colors.whiteColor};
  z-index: 10;
`;

const Header = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0 10px 0;
`;

const HeaderArrow = styled.div`
  cursor: pointer;
  :hover {
    color: ${colors.darkGrayFontColor};
  }
`;

const HeaderText = styled.div``;

const DateGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const DateBox = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  ${({ today }) =>
    today &&
    css`
      border: 1px solid ${colors.styledInputBorder};
    `}
  ${({ current }) =>
    !current
      ? css`
          color: gray;
        `
      : css`
          :hover {
            border: 1px solid ${colors.styledInputBorder};
          }
        `}
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${colors.skyblueColor};
      color: white;
    `}

  ${({ startEnd }) =>
    startEnd &&
    css`
      color: white;
      background-color: ${colors.mainColor};
    `}
`;

const WeekGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 5px;
`;

const WeekBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 2px;
`;

const Date_Range_Picker = () => {
  const { language } = useContext(AppContext);
  const { inputs, setInputs } = useContext(MainContext);
  const { startDate, endDate } = inputs;

  const ref = useRef(null);
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const [modal, setModal] = useState(false);
  const [yearMonth, setYearMonth] = useState([
    today.getFullYear(),
    today.getMonth() + 1,
  ]);

  const getFullMonth = (yearMonth) => {
    const curMonthStart = new Date(yearMonth[0], yearMonth[1] - 1); //Date 객체로 생성
    const preMonthEnd = new Date(yearMonth[0], yearMonth[1] - 1, 0).getDate(); //이전달 끝나는 날
    const curStartDate = curMonthStart.getDay(); //이번달 시작 요일 -> 일요일 0 ~ 토요일 6
    const curEndDate = new Date(yearMonth[0], yearMonth[1], 0).getDate();
    let list = [[]];

    for (let i = curStartDate - 1; i >= 0; i--) {
      const preDate = new Date(yearMonth[0], yearMonth[1] - 2, preMonthEnd - i);
      list[0].push({
        year: preDate.getFullYear(),
        month: preDate.getMonth() + 1,
        date: preDate.getDate(),
        dateObj: preDate,
        state: "pre",
      });
    }

    for (let i = 1, j = 0; i <= curEndDate; i++) {
      if (list[j].length === 7) {
        j++;
        list.push([]);
      }
      list[j].push({
        year: yearMonth[0],
        month: yearMonth[1],
        date: i,

        dateObj: new Date(yearMonth[0], yearMonth[1] - 1, i),
        state: "cur",
      });
    }
    let i = 0;
    while (list[list.length - 1].length < 7) {
      i++;
      const nextDate = new Date(yearMonth[0], yearMonth[1], i);
      list[list.length - 1].push({
        year: nextDate.getFullYear(),
        month: nextDate.getMonth() + 1,
        date: nextDate.getDate(),
        dateObj: nextDate,
        state: "next",
      });
    }
    return list;
  };

  const calendarHandel = (arrow) => {
    let year = yearMonth[0];
    let month = yearMonth[1];
    switch (arrow) {
      case "pre":
        if (month === 1) {
          year--;
          month = 12;
        } else month--;
        break;
      case "next":
        if (month === 12) {
          year++;
          month = 1;
        } else month++;
        break;
    }
    setYearMonth([year, month]);
  };

  const selectDateHandle = (day) => {
    if (startDate === null) {
      setInputs({ ...inputs, startDate: day.dateObj });
      return;
    }
    if (endDate === null) {
      if (day.dateObj.getTime() < startDate.getTime()) {
        setInputs({
          ...inputs,
          startDate: day.dateObj,
          endDate: startDate,
        });
      } else {
        setInputs({ ...inputs, endDate: day.dateObj });
      }
      return;
    }
    setInputs({ ...inputs, startDate: day.dateObj, endDate: null });

    if (endDate === null) setModal(false);
  };

  useEffect(() => {
    const click = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setModal(false);
      }
    };
    window.addEventListener("click", click);

    return () => window.removeEventListener("click", click);
  }, []);

  return (
    <>
      <RowBox ref={ref}>
        <ValueViewer onClick={() => setModal(!modal)}>
          {startDate === null ? "-" : dateFormat(startDate, "yyyy.MM.dd")} -{" "}
          {endDate === null ? "-" : dateFormat(endDate, "yyyy.MM.dd")}
        </ValueViewer>
        {modal && (
          <>
            <CalendarContainer>
              <Header>
                <HeaderArrow onClick={() => calendarHandel("pre")}>
                  <FaCaretLeft />
                </HeaderArrow>
                <HeaderText>
                  {yearMonth[0]}.
                  {yearMonth[1] < 10 ? `0${yearMonth[1]}` : yearMonth[1]}
                </HeaderText>
                <HeaderArrow onClick={() => calendarHandel("next")}>
                  <FaCaretRight />
                </HeaderArrow>
              </Header>
              <WeekGrid>
                {language === "KOR" &&
                  ["일", "월", "화", "수", "목", "금", "토"].map(
                    (el, index) => <WeekBox key={index}>{el}</WeekBox>
                  )}
                {language === "ENG" &&
                  ["Sun", "Mon", "Tue", "Wen", "Tue", "Fri", "Sat"].map(
                    (el, index) => <WeekBox key={index}>{el}</WeekBox>
                  )}
              </WeekGrid>
              <DateGrid>
                {getFullMonth(yearMonth).map((week, index) =>
                  week.map((day, idx) => (
                    <DateBox
                      key={idx}
                      current={day.state === "cur"}
                      today={today.getTime() === day.dateObj.getTime()}
                      selected={
                        startDate !== null &&
                        endDate !== null &&
                        startDate.getTime() <= day.dateObj.getTime() &&
                        day.dateObj.getTime() <= endDate.getTime()
                      }
                      startEnd={
                        (startDate !== null &&
                          startDate.getTime() === day.dateObj.getTime()) ||
                        (endDate !== null &&
                          endDate.getTime() === day.dateObj.getTime())
                      }
                      onClick={() =>
                        day.state === "cur" && selectDateHandle(day)
                      }
                    >
                      {day.date}
                    </DateBox>
                  ))
                )}
              </DateGrid>
            </CalendarContainer>
          </>
        )}
      </RowBox>
    </>
  );
};

export default Date_Range_Picker;
