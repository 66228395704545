import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { FiChevronDown } from "react-icons/fi";
import { groupChanger } from "utils/common";
import { AppContext } from "App";

const Container = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
  flex-shrink: 0;
  /* border: 1px solid red; */
`;

const LabelText = styled.div`
  /* color: ${colors.grayFontColor}; */
  font-size: ${({ labelFontSize }) => labelFontSize}px;
`;

const ValueDiv = styled.div`
  width: 100%;
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: ${({ NOBORDER }) =>
    !NOBORDER && `1px solid ${colors.styledInputBorder}`};
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};

  color: ${({ grayFontColor }) => grayFontColor && `${colors.grayFontColor}`};
  & > .downIcon {
    position: absolute;
    right: 0;
    font-size: 24px;
    color: ${colors.blackColor};
  }
`;

const OptionWrapper = styled.div`
  width: 100%;
  max-height: 180px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  overflow-y: auto;
  background-color: ${colors.defaultBackgroundColor};
  z-index: 1;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.scrollColor};
    border-radius: 5px;
  }
`;

const Option = styled.div`
  height: 59px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-left: 5px;
  font-size: 18px;
  color: ${colors.blackColor};
  border-bottom: 1px solid ${colors.styledInputBorder};
  &:hover {
    background-color: ${colors.lightGrayHover};
  }
`;

const ProfileImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-size: ${({ src }) => (src === "" ? `40%` : `cover`)};
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: ${colors.lightgrayBackgroundColor};
  margin-right: 6px;
  overflow: hidden;
`;

const Text = styled.div`
  width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SelectMember = ({
  name,
  label,
  margin = "20px 0",
  width,
  value,
  options = [],
  onChange,
  NOBORDER,
  BOLD,
  height = 59,
  labelFontSize = 18,
  fontSize = 18,
  placeholder = "",
  NOARROW,
}) => {
  const { language } = useContext(AppContext);
  const [optionOpen, setOptionOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const selectbox = useRef();

  useEffect(() => {
    setSelectedImg(value?.img);
  }, [value]);

  useEffect(() => {
    const click = (e) => {
      if (!selectbox?.current?.contains(e.target)) setOptionOpen(false);
    };
    window.addEventListener("click", click);
    return () => window.removeEventListener("click", click);
  }, []);

  return (
    <Container margin={margin} width={width}>
      <LabelText labelFontSize={labelFontSize}>{label}</LabelText>
      <ValueDiv
        height={height}
        NOBORDER={NOBORDER}
        ref={selectbox}
        fontSize={fontSize}
        BOLD={BOLD}
        onClick={(e) => {
          e.preventDefault();
          setOptionOpen(!optionOpen);
        }}
        grayFontColor={!value}
      >
        {!value ? (
          placeholder
        ) : (
          <>
            <ProfileImage src={selectedImg} />
            <Text>
              {groupChanger(value?.group, language)} {value?.name} {value?.rank}
            </Text>
          </>
        )}
        {!NOARROW && <FiChevronDown className="downIcon" />}
        {optionOpen && (
          <OptionWrapper>
            {options.map((op, index) => (
              <Option
                key={index}
                onClick={() => {
                  onChange(name, {
                    id: op.user_id,
                    group: op.user_group,
                    name: op.user_name,
                    rank: op.user_rank,
                    countryCode: op.user_countryCode,
                    cellphone: op.user_cellphone,
                    email: op.user_email,
                    img: op.user_profileImg,
                  });
                }}
              >
                <ProfileImage src={op.user_profileImg} />
                <Text>
                  {groupChanger(op.user_group, language)} {op.user_name}{" "}
                  {op.user_rank}
                </Text>
              </Option>
            ))}
          </OptionWrapper>
        )}
      </ValueDiv>
    </Container>
  );
};
export default SelectMember;
