import React, {useContext, useState, useCallback, useEffect} from "react";
import styled, {css} from "styled-components";
import {colors} from "styles/colors";
import StyledSelect from "components/share/StyledSelect";
import emptyProfile from "assets/icon/emptyProfile.svg";
import {toast} from "react-toastify";
import memberDelIcon from "assets/icon/memberDelIcon.svg";
import {ProjectDetailContext} from "routes/Main/ProjectDetail/ProjectDetail/ProjectDetailContainer";
import {FiX} from "react-icons/fi";
import StyledInput from "../../../../share/StyledInput";
import StyledButton from "../../../../share/StyledButton";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_SCAR_MEMBER} from "graphql/Main/ProjectDetail/ProjectDetail/query";
import {groupChanger, onlyNumber} from "utils/common";
import {
    EDIT_PROJECT_MEMBER,
    DELETE_PROJECT_MEMBER,
} from "graphql/Main/ProjectDetail/ProjectDetail/mutation";
import {nationList} from "components/feature/Login/nationNumber";
import {AppContext} from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 770px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.whiteColor};

  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-weight: bold;
  margin-bottom: ${({TITLE}) => TITLE && 40}px;
  color: ${({color}) => color};
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({BOTTOM}) => BOTTOM && "auto"};
  margin-bottom: ${({marginBottom}) => marginBottom && "20px"};
`;

const MemberBox = styled.div`
  height: 80px;
  display: grid;
  grid-template-columns: 48px 1fr 80px;
  row-gap: 8px;
  column-gap: 20px;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.styledInputBorder};
`;

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  grid-row: 1 / 3;
  background-image: ${({src}) =>
          src === "" ? `url(${emptyProfile})` : `url(${src})`};

  background-repeat: no-repeat;
  background-position: 50%;
  background-size: ${({src}) => (src === "" ? "40%" : "cover")};
  background-color: ${colors.lightgrayBackgroundColor};
`;

const NameText = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({color}) => color};
`;

const GrayFont = styled.div`
  color: ${colors.grayFontColor};
  margin-left: ${({RIGHT}) => RIGHT && "auto"};
  white-space: nowrap;
  ${({width}) =>
          width &&
          css`
            width: ${width}px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          `}
`;

const MemberDelIcon = styled.img`
  margin-left: auto;
  cursor: pointer;

  &:hover {
    filter: grayscale(1);
  }
`;

const MemberListScrollBox = styled.div`
  width: ${({scroll}) => (scroll ? ` calc(100% + 26px)` : "100%")};
  height: 450px;
  display: flex;
  flex-direction: column;
  padding-right: ${({scroll}) => scroll && 15}px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.scrollColor};
  }
`;

const Button = styled.button`
  height: 26px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid ${colors.grayFontColor};
  ${({select}) =>
          select &&
          css`
            background-color: ${colors.mainColor};
            color: ${colors.whiteColor};
            border: 1px solid ${colors.mainColor};
          `}
`;

const ButtonWrapper = styled.div`
  width: 220px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const MemberEditModal = () => {
    const {language} = useContext(AppContext);
    const {handleMemberEditModal, project, prjDetailRefetch} =
        useContext(ProjectDetailContext);

    const [memberList, setMemberList] = useState([]);
    const [select, setSelect] = useState(true);
    const [phoneOrMail, setPhoneOrMail] = useState("");
    const [country, setCountry] = useState("+82");
    const [group, setGroup] = useState(
        (language === "KOR" && "PM현장") || (language === "ENG" && "PM")
    );

    const [editProjectMember] = useMutation(EDIT_PROJECT_MEMBER);
    const [deleteProjectMember] = useMutation(DELETE_PROJECT_MEMBER);

    const {data: memberData, loading: memberLoading, refetch: memberRefetch} = useQuery(SEE_SCAR_MEMBER, {
        variables: {
            prj_id: Number(localStorage.getItem("prjId")),
        },
    });

    const handleDelMember = useCallback(async (unsignId, userId) => {
        try {
            const {data} = await deleteProjectMember({
                variables: {
                    prj_id: project?.prj_id,
                    user_id: userId,
                    unsignedUser_id: unsignId,
                },
            });
            if (data?.deleteProjectMember?.result) {
                await memberRefetch();
                await prjDetailRefetch();
                toast.success(
                    (language === "KOR" && "구성원을 삭제하였습니다.") ||
                    (language === "ENG" && "Member deleted successfully.")
                );
            }
            if (data?.deleteProjectMember?.error !== "") {
                toast.error(data?.deleteProjectMember?.error);
            }
        } catch (e) {
            toast.error(e.message);
        }
    }, [memberList]);

    const handleAddMember = useCallback(async () => {
        if (group === "선택" || group === "Select") {
            toast.error(
                (language === "KOR" && "소속을 선택해주세요.") ||
                (language === "ENG" && "Please select role")
            );
            return;
        }

        if (
            memberList.map((member) => member.user_email).includes(phoneOrMail) ||
            memberList
                .map((member) => member.user_countryCode + member.user_cellphone)
                .includes(country + phoneOrMail)
        ) {
            toast.error(
                (language === "KOR" && "이미 추가된 사용자입니다.") ||
                (language === "ENG" && "User already added.")
            );
            return;
        }
        if (!select && !phoneOrMail.includes("@")) {
            toast.error(
                (language === "KOR" && "이메일 형식이 맞지 않습니다.") ||
                (language === "ENG" && "The email format is not correct.")
            );
            return;
        }

        try {
            const {data} = await editProjectMember({
                variables: {
                    prj_id: project?.prj_id,
                    group: groupChanger(group, language),
                    emailPhone: select,
                    countryCode: country,
                    term: phoneOrMail,
                },
            });
            if (data?.editProjectMember?.result) {
                toast.success(
                    (language === "KOR" && "구성원이 추가되었습니다.") ||
                    (language === "ENG" && "Member added.")
                );
                await prjDetailRefetch();
                await memberRefetch();
            }
            if (data?.editProjectMember?.error !== "") {
                toast.error(data?.editProjectMember?.error);
            }
        } catch (e) {
            toast.error(e.message);
        } finally {
            setGroup(
                (language === "KOR" && "선택") || (language === "ENG" && "Select")
            );
            setPhoneOrMail("");
        }
    }, [select, phoneOrMail, country, group]);

    useEffect(() => {
        console.log(memberData);
        if (memberData?.seeScarMember?.result) {
            setMemberList(memberData.seeScarMember.prj_member);
        }
    }, [memberData]);

    useEffect(() => {
        document.body.style = `overflow: hidden`;
        return () => (document.body.style = `overflow: auto`);
    }, []);

    return (
        <Container>
            <BgContainer/>
            <ModalContainer>
                <Text TITLE>
                    {language === "KOR" && "프로젝트 구성원 수정하기"}
                    {language === "ENG" && "Edit Members"}
                </Text>
                <FiX className="closeIcon" onClick={() => handleMemberEditModal()}/>
                <RowBox marginBottom>
                    <StyledSelect
                        label={
                            (language === "KOR" && "소속") || (language === "ENG" && "Role")
                        }
                        labelFontSize={14}
                        width={150}
                        height={59}
                        fontSize={18}
                        options={
                            (language === "KOR" && ["PM현장", "시공사", "발주처"]) ||
                            (language === "ENG" && ["PM", "Contractor", "Client"])
                        }
                        value={group}
                        placeholder={
                            (language === "KOR" && "소속 선택") ||
                            (language === "ENG" && "Select Role")
                        }
                        onChange={(_, value) => {
                            setGroup(value);
                        }}
                    />
                    <ButtonWrapper>
                        <Button select={!select} onClick={() => setSelect(false)}>
                            {language === "KOR" && "메일"}
                            {language === "ENG" && "Email"}
                        </Button>
                        <Button select={select} onClick={() => setSelect(true)}>
                            {language === "KOR" && "휴대폰번호"}
                            {language === "ENG" && "Phone number"}
                        </Button>
                    </ButtonWrapper>
                </RowBox>
                <RowBox>
                    {select && (
                        <StyledSelect
                            label={
                                (language === "KOR" && "국가") ||
                                (language === "ENG" && "Country")
                            }
                            labelFontSize={14}
                            width={150}
                            height={54}
                            fontSize={18}
                            options={nationList}
                            value={country}
                            onChange={(_, value) => {
                                setCountry(value);
                            }}
                        />
                    )}
                    <StyledInput
                        name="phoneOrMail"
                        PLUSBTN
                        width={select ? 220 : 395}
                        value={select ? onlyNumber(phoneOrMail) : phoneOrMail}
                        onChange={(e) => setPhoneOrMail(e.target.value)}
                        label={
                            (language === "KOR" && "연락처 또는 메일로 추가하기*") ||
                            (language === "ENG" && "Enter the phone number or mail*")
                        }
                        handlePlusBtn={handleAddMember}
                    />
                </RowBox>
                <MemberListScrollBox scroll={memberList.length > 5}>
                    {memberList.map((user, index) => (
                        <MemberBox key={index}>
                            <ProfileImage src={user.user_profileImg}/>
                            <NameText>
                                {user.user_name} {language === "KOR" && "님"}
                            </NameText>
                            <GrayFont RIGHT>
                                {user.user_id === 0 ? (
                                    <>
                                        {language === "KOR" && "미가입자"}
                                        {language === "ENG" && "unsign user"}
                                    </>
                                ) : (
                                    <>
                                        {language === "KOR" && "가입자"}
                                        {language === "ENG" && "user"}
                                    </>
                                )}
                            </GrayFont>
                            <GrayFont>
                                {user.user_id === 0
                                    ? user.user_email === ""
                                        ? user.user_countryCode + " " + user.user_cellphone
                                        : user.user_email.length > 10
                                            ? user.user_email.substring(0, 10) + "..."
                                            : user.user_email
                                    : user.user_groupCompany.length > 10
                                        ? user.user_groupCompany.substring(0, 10) + "..."
                                        : user.user_groupCompany}{" "}
                                (
                                {groupChanger(user.user_group, language).length > 10
                                    ? groupChanger(user.user_group, language).substring(0, 10) +
                                    "..."
                                    : groupChanger(user.user_group, language)}
                                )
                            </GrayFont>
                            {!user.creator && (
                                <MemberDelIcon
                                    src={memberDelIcon}
                                    onClick={() =>
                                        handleDelMember(user.unsignedUser_id, user.user_id)
                                    }
                                />
                            )}
                        </MemberBox>
                    ))}
                </MemberListScrollBox>

                <StyledButton
                    width={74}
                    height={46}
                    label={
                        (language === "KOR" && "완료") || (language === "ENG" && "Complete")
                    }
                    margin="0 0 0 auto "
                    fontSize={16}
                    onClick={() => handleMemberEditModal()}
                />
                {/* </RowBox> */}
            </ModalContainer>
        </Container>
    );
};

export default MemberEditModal;
