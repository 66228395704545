import React, { useContext } from "react";
import styled from "styled-components";
import GuideHeader from "components/layout/GuideHeader";
import { colors } from "styles/colors";
import threedotIcon from "assets/icon/threedotIcon.svg";
import StyledSwiper from "components/share/StyledSwiper";
import ProfileCard from "components/feature/Main/ProjectDetail/ScarDetail/ProfileCard";
import ScarModal from "components/feature/Main/ProjectDetail/ScarDetail/ScarModal";
import StyledButton from "components/share/StyledButton";
import { ScarDetailContext } from "./ScarDetailContainer";
import { dateFormat, engLabelChanger } from "utils/common";
import {
  riskGradeChanger,
  scarSignStatusChanger,
} from "lib/ProjectDetailUtils";
import RejectModal from "components/feature/Main/ProjectDetail/ScarDetail/RejectModal";
import ActionModal from "components/feature/Main/ProjectDetail/ScarDetail/ActionModal";
import { css } from "styled-components";
import CheckModal from "components/feature/Main/ProjectDetail/ScarDetail/CheckModal";
import headerLogo from "assets/icon/Logo.png";

const Container = styled.div`
  width: 1920px;
  /* min-height: calc(1080px - 82px); */
  min-height: calc(100vh - 154px - 81px - 32px - 100px);
  display: grid;
  grid-template-columns: 380px 957px 400px;
  align-content: flex-start;
  column-gap: 50px;
  padding: 0 40px;
  margin: 0 auto 100px auto;
`;

const ButtonWrapper = styled.div`
  margin-top: 140px;
`;

const ProjectDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 50px 0 100px 0;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 2px;
`;

const ProjectInfoBox = styled.div`
  height: 159px;
  grid-column: 1/ 3;
  display: grid;
  grid-template-columns: 107px 70px 1fr;
  column-gap: 30px;
  row-gap: 20px;
  padding: 26px 30px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const SubTitleText = styled.div`
  margin: 24px 0 16px 0;
`;

const DotIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const ProjectImage = styled.img`
  grid-row: 1 / 4;
  width: 107px;
  height: 107px;
  border-radius: 10px;
  object-fit: cover;
  border: ${({ src }) => src === "" && `1px solid ${colors.lightgrayBorder}`};
`;

const Text = styled.div`
  white-space: ${({ NORMAL }) => (NORMAL ? "normal" : "nowrap")};
  color: ${({ GRAY }) => GRAY && `${colors.darkGrayFontColor}`};
  line-height: ${({ NORMAL }) => NORMAL && 1.2};
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 0;
    `}
`;

const TextSwiperWrapper = styled.div`
  display: flex;
  padding: 26px 30px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const InfoBox = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : 290)}px;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-left: 26px;
`;

const TextColumnWrapper = styled.div`
  width: ${({ width }) => width}px;
  height: 48px;
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  & > ${Text} {
    margin-bottom: 12px;
  }
`;

const ProfileWrapper = styled.div`
  height: 864px;
  overflow-y: auto;
  margin: 26px 0 24px 0;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.scrollColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  & > ${Text} {
    padding: 5px 16px;
    margin: 0 0 16px 16px;
    border-radius: 8px;
    background-color: ${colors.whiteColor};
    border: 1px solid ${colors.lightgrayBorder};
  }
`;

const ScarDetailPresenter = ({ language }) => {
  const {
    scarModal,
    handleModal,
    scarData,
    projectInfo,
    scar,
    rejectModal,
    setRejectModal,
    handleApproval,
    actionModal,
    checkModal,
    signTurn,
  } = useContext(ScarDetailContext);

  return (
    <>
      <GuideHeader
        labelList={
          (language === "KOR" && [
            "프로젝트 목록",
            "프로젝트 상세",
            "SCAR 상세",
          ]) ||
          (language === "ENG" && [
            "Project List",
            "Project Detail",
            "SCAR Detail",
          ])
        }
      />
      <Container>
        <ButtonWrapper>
          {signTurn &&
            scar?.scar_signNumber !== 6 &&
            scar?.scar_signNumber !== 7 && (
              <>
                <StyledButton
                  width={380}
                  height={48}
                  fontSize={18}
                  label={
                    (language === "KOR" &&
                      (scar?.scar_signNumber === 2
                        ? "조치사항 입력"
                        : "승인")) ||
                    (language === "ENG" &&
                      (scar?.scar_signNumber === 2
                        ? "Enter action"
                        : "Approve"))
                  }
                  margin="0 0 20px 0"
                  onClick={() => handleApproval(true)}
                />
                <StyledButton
                  width={380}
                  height={48}
                  fontSize={18}
                  BORDER
                  borderColor={colors.redFontColor}
                  labelColor={colors.redFontColor}
                  label={
                    (language === "KOR" && "반려") ||
                    (language === "ENG" && "Decline")
                  }
                  onClick={() => setRejectModal(true)}
                />
              </>
            )}
        </ButtonWrapper>
        <ProjectDetailContainer>
          <TitleText>
            {language === "KOR" && "SCAR 상세정보"}
            {language === "ENG" && "SCAR Detail"}
          </TitleText>
          <DotIcon src={threedotIcon} onClick={handleModal} />
          <SubTitleText>
            {language === "KOR" && "프로젝트 정보"}
            {language === "ENG" && "Project Information"}
          </SubTitleText>
          <ProjectInfoBox>
            <ProjectImage src={projectInfo?.prj_img || headerLogo} />
            <Text GRAY>
              {language === "KOR" && "프로젝트명"}
              {language === "ENG" && "Project Title"}
            </Text>
            <Text width={650}>{projectInfo?.prj_title} </Text>
            <Text GRAY>
              {language === "KOR" && "현장주소"}
              {language === "ENG" && "Location"}
            </Text>
            <Text width={650}>{projectInfo?.prj_address}</Text>
            <Text GRAY>
              {language === "KOR" && "사업기간"}
              {language === "ENG" && "Period"}
            </Text>
            <Text>
              {dateFormat(
                new Date(Number(projectInfo?.prj_startDate)),
                "yyyy.MM.dd",
              )}{" "}
              ~{" "}
              {dateFormat(
                new Date(Number(projectInfo?.prj_endDate)),
                "yyyy.MM.dd",
              )}
            </Text>
          </ProjectInfoBox>
          <SubTitleText>
            {language === "KOR" && "SCAR 발행 정보"}
            {language === "ENG" && "Violation Observed"}
          </SubTitleText>
          <TextSwiperWrapper>
            <StyledSwiper imageList={scar?.img?.map((img) => img.si_img)} />
            <InfoBox>
              <TextColumnWrapper width={146}>
                <Text GRAY>
                  {language === "KOR" && "발행일"}
                  {language === "ENG" && "Date of issue"}{" "}
                </Text>
                <Text>
                  {dateFormat(
                    new Date(Number(scar?.scar_createdAt)),
                    "yyyy.MM.dd",
                  )}
                </Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={152}>
                <Text GRAY>
                  {language === "KOR" && "조치기한"}
                  {language === "ENG" && "Due date"}
                </Text>
                <Text>
                  {dateFormat(
                    new Date(Number(scar?.scar_endDate)),
                    "yyyy.MM.dd",
                  )}
                </Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={152}>
                <Text GRAY>
                  {language === "KOR" && "상태"}
                  {language === "ENG" && "State"}
                </Text>
                <Text>
                  {scarSignStatusChanger(scar?.scar_signStatus, language)}
                </Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={139}>
                <Text GRAY>
                  {language === "KOR" && "발행자"}
                  {language === "ENG" && "Creator"}
                </Text>
                <Text width={140}>
                  {scarData?.seeScarDetail?.creatorInfo?.user_name}{" "}
                  {scarData?.seeScarDetail?.creatorInfo?.user_rank}
                </Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={146}>
                <Text GRAY>
                  {language === "KOR" && "위험등급"}
                  {language === "ENG" && "Risk Grade"}
                </Text>
                <Text>{riskGradeChanger(scar?.scar_riskGrade, language)}</Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={152}>
                <Text GRAY>
                  {" "}
                  {language === "KOR" && "리스크코드"}
                  {language === "ENG" && "Risk Code"}
                </Text>
                <Text>{scar?.scar_riskcode}</Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={152}>
                <Text GRAY>
                  {language === "KOR" && "발행 번호"}
                  {language === "ENG" && "SCAR Number"}
                </Text>
                <Text NORMAL>{scar?.scar_number}</Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={135} height={69}>
                <Text GRAY>
                  {language === "KOR" && "라벨"}
                  {language === "ENG" && "Label"}
                </Text>
                <Text NORMAL>
                  {scar?.scar_label.map((label, index) => (
                    <div key={index} style={{ marginRight: "10px" }}>
                      {language === "KOR" && label.sLbl_name}
                      {language === "ENG" && engLabelChanger(label.sLbl_name)}
                    </div>
                  ))}
                </Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={589}>
                <Text GRAY>
                  {language === "KOR" && "위반위치"}
                  {language === "ENG" && "Location"}
                </Text>
                <Text NORMAL>{scar?.scar_spot}</Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={589}>
                <Text GRAY>
                  {language === "KOR" && "위반사항"}
                  {language === "ENG" && "Violation Description"}
                </Text>
                <Text NORMAL>{scar?.scar_title}</Text>
              </TextColumnWrapper>
            </InfoBox>
          </TextSwiperWrapper>
          <SubTitleText>
            {language === "KOR" && "발행 확인자 코멘트"}
            {language === "ENG" && "Resident Engineer (PM) Comment"}
          </SubTitleText>
          <TextSwiperWrapper>
            <Text>{scar?.signLine?.check_approvalContent}</Text>
          </TextSwiperWrapper>
          <SubTitleText>
            SCAR {language === "KOR" && "조치사항"}
            {language === "ENG" && "Resolution Description"}
          </SubTitleText>
          <TextSwiperWrapper>
            <StyledSwiper
              imageList={scar?.scarAction?.scarActionImg?.map(
                (img) => img.sai_img,
              )}
            />
            <InfoBox height={122}>
              <TextColumnWrapper width={304}>
                <Text GRAY>
                  {language === "KOR" && "완료시간 (소요시간)"}
                  {language === "ENG" && "End Date"}
                </Text>
                <Text>
                  {scar?.scarAction?.sa_completeDate !== ""
                    ? dateFormat(
                        new Date(scar?.scarAction?.sa_completeDate),
                        "yyyy.MM.dd - hh:mm",
                      ) + ` (${scar?.scarAction?.sa_takenTime})`
                    : "-"}
                </Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={287}>
                <Text GRAY>
                  {language === "KOR" && "조치자"}
                  {language === "ENG" && "Safety Officer"}
                </Text>
                <Text width={290}>
                  {scarData?.seeScarDetail?.actionUserInfo?.user_name}{" "}
                  {scarData?.seeScarDetail?.actionUserInfo?.user_rank}
                </Text>
              </TextColumnWrapper>
              <TextColumnWrapper width={591}>
                <Text GRAY>
                  {" "}
                  {language === "KOR" && "조치사항"}
                  {language === "ENG" && "Resolution Description"}
                </Text>
                <Text NORMAL>
                  {scar?.scarAction?.sa_text === ""
                    ? "-"
                    : scar?.scarAction?.sa_text}
                </Text>
              </TextColumnWrapper>
            </InfoBox>
          </TextSwiperWrapper>
        </ProjectDetailContainer>
        <ProjectDetailContainer>
          <TitleText>
            {language === "KOR" && "결재 라인"}
            {language === "ENG" && "Approval Process"}
          </TitleText>
          <ProfileWrapper>
            <ProfileCard
              status={
                scar?.signLine?.create_rejectStatus
                  ? "reject"
                  : scar?.signLine?.create_status
                  ? "auth"
                  : "unauth"
              }
              title={
                (language === "KOR" && "발행자") ||
                (language === "ENG" && "Creator")
              }
              profileImg={scar?.signLine?.create_img}
              group={scar?.signLine?.create_group}
              name={scar?.signLine?.create_name}
              rank={scar?.signLine?.create_rank}
              phoneNumber={
                scar?.signLine?.create_countryCode +
                " " +
                scar?.signLine?.create_cellphone
              }
              mail={scar?.signLine?.create_email}
            />
            <ProfileCard
              status={
                scar?.signLine?.check_rejectStatus
                  ? "reject"
                  : scar?.signLine?.check_status
                  ? "auth"
                  : "unauth"
              }
              title={
                (language === "KOR" && "발행확인자") ||
                (language === "ENG" && "Resident Engineer (PM)")
              }
              profileImg={scar?.signLine?.check_img}
              group={scar?.signLine?.check_group}
              name={scar?.signLine?.check_name}
              rank={scar?.signLine?.check_rank}
              phoneNumber={
                scar?.signLine?.check_countryCode +
                " " +
                scar?.signLine?.check_cellphone
              }
              mail={scar?.signLine?.check_email}
            />
            <ProfileCard
              status={
                scar?.signLine?.action_rejectStatus
                  ? "reject"
                  : scar?.signLine?.action_status
                  ? "auth"
                  : "unauth"
              }
              title={
                (language === "KOR" && "조치자") ||
                (language === "ENG" && "Safety Officer")
              }
              profileImg={scar?.signLine?.action_img}
              group={scar?.signLine?.action_group}
              name={scar?.signLine?.action_name}
              rank={scar?.signLine?.action_rank}
              phoneNumber={
                scar?.signLine?.action_countryCode +
                " " +
                scar?.signLine?.action_cellphone
              }
              mail={scar?.signLine?.action_email}
            />
            <ProfileCard
              status={
                scar?.signLine?.manager_rejectStatus
                  ? "reject"
                  : scar?.signLine?.manager_status
                  ? "auth"
                  : "unauth"
              }
              title={
                (language === "KOR" && "책임자") ||
                (language === "ENG" && "Safety Manager")
              }
              profileImg={scar?.signLine?.manager_img}
              group={scar?.signLine?.manager_group}
              name={scar?.signLine?.manager_name}
              rank={scar?.signLine?.manager_rank}
              phoneNumber={
                scar?.signLine?.manager_countryCode +
                " " +
                scar?.signLine?.manager_cellphone
              }
              mail={scar?.signLine?.manager_email}
            />
            <ProfileCard
              status={
                scar?.signLine?.actionCheck_rejectStatus
                  ? "reject"
                  : scar?.signLine?.actionCheck_status
                  ? "auth"
                  : "unauth"
              }
              title={
                (language === "KOR" && "조치확인자") ||
                (language === "ENG" && "HSE Officer (PM)")
              }
              profileImg={scar?.signLine?.actionCheck_img}
              group={scar?.signLine?.actionCheck_group}
              name={scar?.signLine?.actionCheck_name}
              rank={scar?.signLine?.actionCheck_rank}
              phoneNumber={
                scar?.signLine?.actionCheck_countryCode +
                " " +
                scar?.signLine?.actionCheck_cellphone
              }
              mail={scar?.signLine?.actionCheck_email}
            />
            <ProfileCard
              status={
                scar?.signLine?.finalSign_rejectStatus
                  ? "reject"
                  : scar?.signLine?.finalSign_status
                  ? "auth"
                  : "unauth"
              }
              title={
                (language === "KOR" && "최종승인자") ||
                (language === "ENG" && "Resident Engineer")
              }
              profileImg={scar?.signLine?.finalSign_img}
              group={scar?.signLine?.finalSign_group}
              name={scar?.signLine?.finalSign_name}
              rank={scar?.signLine?.finalSign_rank}
              phoneNumber={
                scar?.signLine?.finalSign_countryCode +
                " " +
                scar?.signLine?.finalSign_cellphone
              }
              mail={scar?.signLine?.finalSign_email}
            />
          </ProfileWrapper>
          <Text>
            {language === "KOR" && "회람자"}
            {language === "ENG" && "Distribution"} ({language === "KOR" && "총"}
            {language === "ENG" && "Total"}{" "}
            {!scar?.signLine?.circulators
              ? 0
              : scar?.signLine?.circulators?.length}
            {language === "KOR" && "명"})
          </Text>
          <RowBox>
            {scar?.signLine?.circulators?.map((str, index) => (
              <Text key={index}>
                {str.circ_email === "" ? str.circ_cellphone : str.circ_email}
              </Text>
            ))}
          </RowBox>
        </ProjectDetailContainer>
      </Container>
      {actionModal && <ActionModal />}
      {checkModal && <CheckModal />}
      {scarModal && (
        <ScarModal handleModal={handleModal} scarId={scar?.scar_id} />
      )}
      {rejectModal && <RejectModal />}
    </>
  );
};

export default ScarDetailPresenter;
