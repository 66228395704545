import parsePhoneNumber from "libphonenumber-js";

// 휴대폰 번호 hyphen 제거
export const removeHyphen = (phoneNumber) => {
  return phoneNumber.replace(/-/g, "");
};

export const getExtension = (str) => {
  return str.slice(str.lastIndexOf(".") + 1).toLowerCase();
};

export const groupChanger = (value, type) => {
  switch (type) {
    case "KOR":
      switch (value) {
        case "PM본사":
          return "head";
        case "PM현장":
          return "local";
        case "시공사":
          return "builder";
        case "발주처":
          return "owner";
        case "head":
          return "PM본사";
        case "local":
          return "PM현장";
        case "builder":
          return "시공사";
        case "owner":
          return "발주처";
      }
    case "ENG":
      switch (value) {
        case "head":
          return "PM HQ";
        case "local":
          return "PM";
        case "builder":
          return "Contractor";
        case "owner":
          return "Client";
        case "PM HQ":
          return "head";
        case "PM":
          return "local";
        case "Contractor":
          return "builder";
        case "Client":
          return "owner";
      }
  }
};

export const onlyNumber = (text) => {
  return text.replace(/[^0-9]/g, "");
};

export const dateFormat = (dateString, type) => {
  const weekDay = ["일", "월", "화", "수", "목", "금", "토"];
  let year;
  let month;
  let day;
  let week;
  let date = new Date(dateString);

  switch (type) {
    case "yyyy.MM":
      year = date.getFullYear();
      month = date.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      return `${year}. ${month}`;
    case "yy.MM.dd":
      year = date.getFullYear().toString().slice(-2);
      month = date.getMonth() + 1;
      day = date.getDate();
      week = weekDay[date.getDay()];

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      return year + ". " + month + ". " + day;
    case "yy.MM.dd (W)":
      year = date.getFullYear().toString().slice(-2);
      month = date.getMonth() + 1;
      day = date.getDate();
      week = weekDay[date.getDay()];

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      return year + "." + month + "." + day + ` (${week})`;
    case "yyyy.MM.dd (W)":
      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
      day = date.getDate();
      week = weekDay[date.getDay()];

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      return year + "." + month + "." + day + ` (${week})`;
    case "yyyy.MM.dd":
      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
      day = date.getDate();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      return year + ". " + month + ". " + day;
    case "yyyy-MM-dd":
      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
      day = date.getDate();
      week = weekDay[date.getDay()];

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      return year + "-" + month + "-" + day;
    case "yyyy.MM.dd - hh:mm":
      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
      day = date.getDate();

      let hour = date.getHours();
      let minute = date.getMinutes();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour >= 10 ? hour : "0" + hour;
      minute = minute >= 10 ? minute : "0" + minute;

      return year + ". " + month + ". " + day + " - " + hour + ":" + minute;
    default:
      return String(date);
  }
};

export const engLabelChanger = (value) => {
  switch (value) {
    case "추락":
      return "Fall hazard";
    case "전도":
      return "Slips and falls";
    case "협착":
      return "Pinch Point";
    case "화재":
      return "Fire";
    case "화재/폭발":
      return "Fire/Explosion";
    case "낙하물":
      return "Falling objects";
    case "무너짐":
      return "Collapse";
    case "베임/찔림":
      return "Cut/Sting";
    case "산소결핍":
      return "Risk of suffocation";
    case "유해물질":
      return "Hazardous materials";
    case "개인보호구":
      return "PPE";
    case "정리정돈":
      return "Clean up";
    case "기타":
      return "Others";
    case "Fall hazard":
      return "추락";
    case "Slips and falls":
      return "전도";
    case "Pinch Point":
      return "협착";
    case "Fire/Explosion":
      return "화재/폭발";
    case "Falling objects":
      return "낙하물";
    case "Collapse":
      return "무너짐";
    case "Cut/Sting":
      return "베임/찔림";
    case "Risk of suffocation":
      return "산소결핍";
    case "Hazardous materials":
      return "유해물질";
    case "PPE":
      return "개인보호구";
    case "Fire":
      return "화재";
    case "Clean up":
      return "정리정돈";
    case "Others":
      return "기타";
    default:
      return value;
  }
};

export const getCountryNumberToCode = (countryNumber) => {
  switch (countryNumber) {
    // 사아디아라비아
    case "+966":
      return "SA";
    // 중국
    case "+86":
      return "CN";
    // 베트남
    case "+84":
      return "VN";
    // 대한민국
    case "+82":
      return "KR";
    // 폴란드
    case "+48":
      return "PL";
    // 영국
    case "+44":
      return "GB";
    // 미국, 캐나다
    case "+1":
    default:
      return ["US", "CA"];
  }
};

export const validatePhoneNumber = (phone, countryCode) => {
  try {
    if (!(countryCode && phone)) return false;

    if (countryCode instanceof Array) {
      return (countryCode || []).some((item) =>
        parsePhoneNumber(phone, item).isValid(),
      );
    }

    if (typeof countryCode === "string") {
      return parsePhoneNumber(phone, countryCode).isValid();
    }
  } catch (e) {
    return false;
  }

  return false;
};

export const validateEmail = (email = "") => {
  const regExp = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
  );

  return regExp.test(email);
};
