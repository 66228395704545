import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import locationIcon from "assets/icon/locationIcon.svg";
import { useQuery } from "@apollo/client";
import { SEE_MY_SIGN_PROJECT } from "graphql/Main/Main/query";
import { dateFormat } from "utils/common";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: ${({ scrollWidth }) => (scrollWidth ? 410 : 400)}px;
  margin-top: 50px;
`;

const PrjListWrapper = styled.div`
  height: 650px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.scrollColor};
  }
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  margin: 26px 0 16px 0;
`;

const RowTableText = styled.div`
  width: ${({ width }) => width}px;
  color: ${colors.grayFontColor};
  :first-child {
    margin-left: 25px;
  }
`;

const ApprovalCard = styled.div`
  width: 100%;
  height: 115px;
  display: flex;
  align-items: center;
  padding: 26px 16px;
  margin-bottom: 20px;
  background-color: ${colors.whiteColor};
  border-radius: 8px;
  cursor: pointer;
`;

const ProjectImage = styled.img`
  width: 86px;
  height: 86px;
  border-radius: 50%;
  margin-right: 16px;
  /* background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%; */
  object-fit: cover;
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
`;
const ProjectInfo = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LocationIcon = styled.img`
  width: 11px;
  height: 14px;
  margin: 0 6px -2px 0;
`;

const SmallText = styled.div`
  font-size: 14px;
  color: ${({ GRAY }) => GRAY && `${colors.grayFontColor}`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 1px;
`;

const ScarNumber = styled.div`
  color: ${colors.mainColor};
  font-weight: bold;
  margin: 0 0 auto 0;
  white-space: nowrap;
`;

const ApprovalList = ({ language }) => {
  const navigate = useNavigate();
  const [signList, setSignList] = useState([]);
  const {
    data: projectData,
    loading: projectLoading,
    refetch: projectRefetch,
  } = useQuery(SEE_MY_SIGN_PROJECT);

  useEffect(() => {
    if (projectData?.seeMySignProject?.result) {
      setSignList(projectData.seeMySignProject.projects);
    }
  }, [projectData]);

  return (
    <Container scrollWidth={signList.length >= 5}>
      <TitleText>
        {language === "KOR" && "내 결재 차례 목록"}
        {language === "ENG" && "Action Required"}
      </TitleText>
      <RowBox>
        <RowTableText width={100}>
          {language === "KOR" && "사진"}
          {language === "ENG" && "Image"}
        </RowTableText>
        <RowTableText width={187}>
          {language === "KOR" && "프로젝트정보"}
          {language === "ENG" && "Project Info"}
        </RowTableText>
        <RowTableText width={87}>
          {language === "KOR" && "결재 SCAR 수"}
          {language === "ENG" && "Sign Scar"}
        </RowTableText>
      </RowBox>
      <PrjListWrapper>
        {signList.map((project, index) => (
          <ApprovalCard
            key={index}
            onClick={() => {
              navigate("/projectDetail");
              localStorage.setItem("prjId", project.prj_id);
            }}
          >
            <ProjectImage src={project.prj_img} />
            <ProjectInfo>
              <CardTitle>{project.prj_title}</CardTitle>
              <SmallText GRAY>
                <LocationIcon src={locationIcon} />
                {project.prj_address}
              </SmallText>
              <SmallText>
                {dateFormat(
                  new Date(Number(project.prj_startDate)),
                  "yyyy.MM.dd"
                )}
                -{" "}
                {dateFormat(
                  new Date(Number(project.prj_endDate)),
                  "yyyy.MM.dd"
                )}
              </SmallText>
            </ProjectInfo>
            <ScarNumber>SCAR + {project.scarCount}</ScarNumber>
          </ApprovalCard>
        ))}
      </PrjListWrapper>
    </Container>
  );
};

export default ApprovalList;
